html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 17px;
    line-height: 1.4;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: 'DecimaPlusW01';
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #d45d38;
    outline: 0px solid slategrey;
}

cite,
em,
i {
    font-style: italic;
}

input,
textarea {
    -webkit-border-radius: 0px;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 25px;
    border: 1px solid #d0cfcb;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius: 0px;
    height: 54px;
    font-size: 17px;
    color: #555555;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 0px;
    margin: 15px 0px 10px 0px;
    padding: 14px 28px 14px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #fff;
    border: 0px;
    font-size: 18px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #000;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #cb4d36;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #000
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #000;
}

h1 {
    font-size: 50px; font-family: 'DecimaPlusW01';
}

h2 {
    font-size: 40px; font-family: 'DecimaPlusW01';
}

h3 {
    font-size: 28px; font-family: 'DecimaPlusW01';
}

h4 {
    font-size: 24px; font-family: 'DecimaPlusW01';
}

h5 {
    font-size: 18px; font-family: 'DecimaPlusW01';
}

h6 {
    font-size: 16px; font-family: 'DecimaPlusW01';
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-weight: 700;
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
    width: auto;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
    outline: none;
    color: #fff;
}

.btn {
    color: #fff;
    border-radius: 0;
    padding: 12px 22px;
    height: 51px;
    border-color: #f9f9f9;
    font-size: 18px;
    font-weight: normal;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    text-transform: uppercase;
    margin-bottom: 4px;
    position: relative;
}

.btn:hover {
    color: #fff;
}

.btn_minwid {
    min-width: 170px;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-sm {
    height: 42px;
    padding: 8px 15px;
    font-size: 16px;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}
.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 20px;
    margin: 0 0 20px 18px;
    color: #000;
}

.form-group {
    margin: 0 0 10px;
}

.form_sec .row {
    margin-left: -4px;
    margin-right: -4px;
}

.form_sec [class*="col-"] {
    padding: 0 4px;
}

label {
    font-weight: 500;
    font-size: 15px;
    color: #615f5f;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 54px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 20px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #5d8d87;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #6f6f6f;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.profile-info-div {
    margin: 35px 0;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'Calibri' !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 1rem;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-family: 'Calibri-Bold';
    font-weight: normal
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #d45d38 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #fb9f0e !important;
}

.react-datepicker__header {
    background-color: #e5dbc9 !important;
}

.react-datepicker__navigation {
    margin: 0
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 25px;
}

.custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_checkbox input[type=checkbox]:checked + span:before {
    content: "";
    text-rendering: auto;
    text-align: center;
    border-color: transparent;
    line-height: 23px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -455px -122px;
}

.custom_checkbox .disableactive + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    color: #ff8400;
    text-align: center;
    text-align: center;
    border-color: #ff8400;
}

.custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 28px;
    height: 28px;
    border: 1px solid #ecdfcc;
    left: 0;
    top: 1px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 40px;
    pointer-events: none;
    color: #3d3d3d;
    display: block;
}

.custom_radio {
    position: relative;
    display: inline-block;
}

.custom_radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.custom_radio input[type=radio]:checked + span:before {
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -399px -125px;
    z-index: 1;
    border: 1px solid transparent;
}

.custom_radio input[type=radio]:checked + span:after {
    content: "";
    background: #ff8400;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.custom_radio span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid #d0cfcb;
    left: 0;
    top: 1px;
    pointer-events: none;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.custom_radio span {
    position: relative;
    padding: 0 0 0 35px;
    pointer-events: none;
    color: #202963;
    display: block;
}

.ckbox_custmze {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ckbox_custmze input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: -12px;
    left: 0;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid #ecdfcc;
}

.ckbox_custmze:hover input ~ .checkmark {
    background-color: #fff;
}

.ckbox_custmze input:checked ~ .checkmark {
    background-color: #72624f;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.ckbox_custmze input:checked ~ .checkmark:after {
    display: block;
}

.ckbox_custmze .checkmark:after {
    left: 10px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.error {
    color: #f00;
    font-weight: normal;
}

.success {
   
    color: #3c763d;
     font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.tab_mobtrigger {
    display: none;
}

.main_tabsec {
    margin: 0 0 40px;
}

.main_tabsec > .nav-tabs {
    border: 0;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-radius: 50px;
    margin: 0 0 20px;
}

.main_tabsec > .nav-tabs>li {
    margin: 0;
    display: table-cell;
    float: none;
    text-align: center;
    width: 50%;
    vertical-align: middle;
    border-left: 2px solid transparent;
}

.main_tabsec > .nav-tabs>li:first-child {
    border-left: 0;
}

.main_tabsec > .nav-tabs>li>a {
    background: #b69a6b;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 15px;
}

.main_tabsec > .nav>li>a:focus {
    background: none;
}

.main_tabsec > .nav-tabs>li.active>a,
.main_tabsec .nav-tabs>li.active>a:focus,
.main_tabsec .nav-tabs>li>a:hover {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec > .nav-tabs>li>a:after {
    content: "";
    border: 18px solid #0b0405;
    border-color: #0b0405 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -36px;
    margin-left: -18px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.main_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.main_tabin {
    border: 1px solid #d9d9d8;
    background: #fff;
    border-radius: 4px;
}

.filter_tabsec {
    margin: 0 0 40px;
}

.filter_tabsec .nav-tabs {
    margin: 0 0 30px;
}

.filter_tabsec .nav-tabs>li {
    width: 17%;
    margin: 0;
    display: inline-block;
    float: none;
    text-align: center;
    vertical-align: middle;
}

.filter_tabsec .nav-tabs>li:first-child {
    border-left: 0;
}

.filter_tabsec .nav-tabs>li>a {
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #272626;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 0 0 5px;
    display: block;
}

.filter_tabsec .nav>li>a:focus {
    background: none;
}

.filter_tabsec .nav-tabs>li:hover>a,
.filter_tabsec .nav-tabs>li.active>a,
.filter_tabsec .nav-tabs>li.active>a:focus,
.filter_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: none;
    color: #060d15;
}

.filter_tabsec .nav-tabs>li>a:after {
    content: "";
    background: #ecdfcc;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.filter_tabsec .nav-tabs>li:hover>a:after,
.filter_tabsec .nav-tabs>li.active>a:after {
    opacity: 1;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec {
    width: 480px;
    position: absolute;
    right: -50px;
    top: 100%;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
}

.hsearch_sec.open {
    opacity: 1;
    visibility: visible;
    top: 54px;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
    position: relative
}

.hmenu_list > li:hover > a,
.hmenu_list > li.active > a {
    color: #fff;
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    margin: 0 0 0 5px;
    list-style: none;
    float: left;
}

.hordertype_sec a {
    display: block;
    padding: 10px 20px;
    color: #d45d38;
    background: #ffffff;
    text-transform: uppercase;
    position: relative;
    top: -10px;
    letter-spacing: 0.5px;
    margin: 0 0 0 13px;
}

.hordertype_sec a:hover {
    color: #000
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #e5dbc9;
    z-index: 99;
    width: 220px;
    position: absolute;
    left: 0px;
    top: 33px;
    display: none;
}

.hmenu_list.desktop_hmenu_list > li:hover > ul,
.hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a {
    font-size: 16px;
    color: #1b0000;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #ab2225;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #d45d38;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #000;
}

.htico_search {
    position: relative;
    top: -4px
}

.hsearch_sec .search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #f7f2ea;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none
}

.search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found {
    color: #333;
    display: block;
    padding: 7px 15px
}

.search_result li a:hover {
    background: #5d8d87;
    color: #fff;
}

.hsearch_sec.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input {
    padding-right: 40px;
    margin-bottom: 0
}

.hsearch_sec.open .input-sec:after,
.hsearch_sec.open .input-sec:before {
    bottom: 98%;
    right: 60px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hsearch_sec.open .input-sec:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}

.hsearch_sec.open .input-sec:before {
    border-color: rgba(208, 207, 203, 0);
    border-bottom-color: #d0cfcb;
    border-width: 9px;
    margin-left: -9px;
}

.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}

.htico_cart {
    padding-right: 0px;
    position: relative;
    top: -3px
}

.htico_cart > a {
    position: relative;
    display: block;
}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

.hcart_dropdown {
    background: #fff;
    right: 0;
    width: 680px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 11;
    text-align: left;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.3);
}

.hcart_dropdown.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hcart_tt {
    background: #060203;
    padding: 15px 10px 15px 20px;
    color: #fff;
}

.hcart_tt h3 {
    margin: 0;
    font-size: 18px;
    color: #fff
}

.hcart_tt h3 a:hover {
    color: #fff;
}

.hclear_cart {
    color: #fff;
}

.hcart_scrollarea {
    max-height: 400px;
    overflow-y: auto;
}

.cart_body {
    padding: 20px 20px;
}

.cart_row {
    padding: 0px;
    position: relative;
}

.cart_img {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    overflow: hidden;
    background: #fff;
    padding: 3px
}

.cart_img img {
    width: 100%;
    max-height: 80px;
    -o-object-fit: cover;
    object-fit: cover
}

.cart_info {
    width: calc(100% - 125px);
    width: -webkit-calc(100% - 125px);
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.cart_info h4 {
    font-size: 18px;
    margin: 0 0 6px;
    color: #3d3d3d;
    font-family: 'Calibri-Bold';
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 13px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p b {
    color: #3d3d3d;
    font-style: normal;
    font-family: 'Calibri-Bold';
}

.cart_extrainfo p {
    margin: 0;
    color: #777777;
    font-style: italic;
    font-size: 16px;
}

.cart_price {
    position: relative;
    margin: 0
}

.cart_price p {
    margin: 0;
    color: #3d3d3d;
    font-size: 19px;
    font-family: 'Calibri-Bold';
}

.cart_remove {
    background: #b0b0b0 url(../images/cross1.png) no-repeat center;
    position: absolute;
    top: 19px;
    right: 10px;
    width: 21px;
    height: 21px;
}

.cart_remove:hover {
    background-color: #000;
    background-image: url(../images/cross1.png);
}

.qty_bx {
    width: 126px;
    height: 36px;
    position: relative;
    padding: 0 40px;
    display: inline-block;
}

.qty_bx span {
    position: absolute;
    width: 36px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    border-radius: 5px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border: 2px solid #809b66
}

.qty_bx span.qty_minus {
    left: 0px;
}

.qty_bx span.qty_plus {
    right: 0px;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #060203;
    border-radius: 5px;
    font-family: 'Calibri-Bold';
}

.qty_bx span:after {
    position: absolute;
    width: 20px;
    height: 20px;
    display: block;
    top: 50%;
    margin-top: -10px;
    content: "";
}

.qty_bx span.qty_minus:after {
    left: 8px;
    background: url(../images/minus.svg) no-repeat;
    background-size: contain;
}

.qty_bx span.qty_plus:after {
    right: 8px;
    background: url(../images/plus.svg) no-repeat;
    background-size: contain;
}

.cart_footer .cart_row {
    padding-top: 0;
    border: 0;
}

.cart_footer span {
    font-size: 16px;
    font-weight: 700;
}

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 12px;
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 16px;
    overflow: hidden;
    border-radius: 10px;
    background: #5d8d87;
    position: relative
}

.progress_bar {
    content: "";
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background: #c4d5d3;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #FFC41E;
    font-size: 14px;
    font-weight: 300;
    margin: 7px 0 0;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.cartaction_price sup {
    font-size: 50%;
    left: -4px;
    top: -7px;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.cart_action {
    padding: 20px
}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.hbslider_sec h2 {
    font-size: 24px;
    color: #363636;
}

.hbslider_sec .slick-dots {
    position: absolute;
    bottom: 15px;
    margin: 0;
    padding: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.hbslider_sec .slick-dots li button {
    background: none;
    border: 1px solid #fff;
    ;
    padding: 0;
    border-radius: 50%;
    font-size: 0;
    width: 12px;
    height: 12px;
}

.hbslider_sec .slick-dots li.slick-active button {
    background: #ecdfcc;
}

.hbslider_sec .slick-arrow {
    z-index: 1;
}

.hbslider_sec .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -188px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-next:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-next:hover::after {
    background-position: -78px -60px;
}

.hbslider_sec .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hbslider_sec .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -136px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hbslider_sec .slick-prev:hover {
    background: #f5f5f5;
}

.hbslider_sec .slick-prev:hover::after {
    background-position: -26px -61px;
}

.white-popup .hbslider_sec .hbslide {
    text-align: center;
}

.bakery_inner {
    padding-bottom: 15px;
}

.hme-product-inner {
    padding: 50px 50px;
    background: #fff;
    margin: 25px 0 0 0;
}

.hme-product-inner .filter_tabsec .nav-tabs {
    margin-bottom: 64px;
    border-bottom: 0;
}

.hme-product-inner .title_sec {
    margin-bottom: 25px;
}

.hme-product-inner .ttsplitter {
    margin-bottom: 25px;
}

.hme-prt-row .slick-slider {
    padding: 0 90px;
}

.hme-prt-column {
    position: relative;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-product-inner .slick-slide {
    padding: 0 10px;
    outline: 0;
}

.hme-product-inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -78px -60px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-next:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-next:hover::after {
    background-position: -188px -60px;
}

.hme-product-inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.hme-product-inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -26px -61px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.hme-product-inner .slick-prev:hover {
    background: #f5f5f5;
}

.hme-product-inner .slick-prev:hover::after {
    background-position: -136px -61px;
}

.hme-prt-column {
    position: relative;
}

.hme-prt-innerslide {
    position: relative;
}

.hme-prt-inner {
    position: relative;
}

.hme-prt-slidecontent {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: rgba(255, 255, 255, 0.86);
    opacity: 0;
    padding: 15px;
}

.hme-prt-hei {
    text-align: center;
}

.hme-prt-hei img {
    max-height: 100%;
    width: auto;
    height: auto;
}

.hme-prt-column:hover .hme-prt-slidecontent {
    opacity: 1;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.hme-prt-slidecontent-inner {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: relative;
    text-align: center;
}

.hme-prt-slidecontent-inner h3 {
    font-size: 19px;
    margin-bottom: 3px;
    color: #556c11;
    height: 27px;
    overflow: hidden;
}

.hme-prt-slidecontent-inner span {
    font-size: 19px;
    color: #556c11;
}

.hme-prt-slidecontent-inner p {
    color: #556c11;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 23px;
    height: 40px;
    overflow: hidden;
    padding: 0 10px;
}

.outlet {
    position: relative;
}

.outlet_inner {
    padding: 63px 35px 76px 35px;
    background: #fff;
    margin: 25px 0 39px 0;
}

.bread_space {
    margin: 40px 0 0;
}

.breadcrumb {
    margin-bottom: 0;
    padding: 30px 0 0 0;
}

.breadcrumb_inner ul {
    list-style: none;
}

.breadcrumb_left {
    float: left;
    width: 50%;
}

.breadcrumb_left ul {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.breadcrumb_right {
    float: right;
    width: 50%;
    text-align: right;
    position: relative;
}

.breadcrumb_right .chosen_left {
    position: absolute;
    top: 50%;
    left: 0;
    text-align: right;
    right: 170px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.breadcrumb_right .re_select {
    max-width: 170px;
    float: right;
    width: 100%;
}

.breadcrumb_right .re_select {
    max-width: 170px;
}

.breadcrumb_right .re_select .Select-clear-zone {
    display: none;
}

.breadcrumb_inner ul p {
    margin-bottom: 0;
    font-weight: 700;
}

.breadcrumb_inner ul p a {
    display: inline-block;
    color: #5b5b5b;
}

.breadcrumb_inner ul p a:hover {
    color: #000;
}

.breadcrumb_inner ul p i {
    padding: 0 10px;
    display: inline-block
}

.breadcrumb_right .form-group {
    margin-bottom: 0;
}

.breadcrumb_right .chosen_left span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    color: #5b5b5b;
    vertical-align: middle;
    font-weight: 700;
    padding-right: 15px;
}

.breadcrumb_right .chosen-container {
    display: inline-block;
    text-align: left;
}

.breadcrumb_right .chosen-container-single .chosen-single {
    width: 170px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #ecdfcc;
}

.breadcrumb_right .chosen-container-single .chosen-single span {
    text-transform: capitalize;
}

.breadcrumb_right .chosen-container-single .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -205px -24px;
    width: 14px;
    height: 8px;
}

.breadcrumb_right .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 14px;
    height: 8px;
    background-position: -236px -24px;
}

.breadcrumb_right .chosen-container-single .chosen-single div {
    top: 15px;
    right: -17px;
}

.breadcrumb_right .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.bakery {
    position: relative;
}

.bakery_inner h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.bakery_inner p {
    color: #5b5b5b;
}

.bakery_slider {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.bakery_slider .slick-slider {
    padding: 0 48px;
}

.bakery_slider.below_three {
    display: table;
    width: 100%;
}

.bakery_slider.below_three > .bakery_slide {
    display: table-cell;
}

.bakery_slider.below_three > .bakery_slide:first-child {
    border-left: 1px solid #ecdfcc;
}

.bakery_slide p {
    margin-bottom: 0;
}

.bakery_slide p a:hover {
    background: #809b66;
    color: #fff;
}

.bakery_row .slick-next {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #fff;
    right: 0px;
    top: 0;
    width: 48px;
    height: 44px;
    border-left: 1px solid #ecdfcc;
}

.bakery_slider .slick-list {
    padding: 0 1px;
}

.bakery_row .slick-prev {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #fff;
    left: 0;
    top: 0;
    width: 48px;
    height: 44px;
    cursor: pointer;
    margin: 0
}

.innerproduct {
    margin: 27px 0 20px 0;
}

.innerproduct:last-of-type {
    margin-bottom: 14px;
}

.innerproduct h3 {
    font-size: 19px;
    margin-bottom: 25px;
    outline: none;
    text-transform: uppercase;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.innerproduct-col {
    width: 19.105%;
    margin-right: 1.081%;
    margin-bottom: 32px;
}

.innerproduct-col:nth-of-type(5n+5) {
    margin-right: 0;
}

.addcart_row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
    max-width: 320px;
    margin: 0 auto;
}

.addcart_row.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.addcart_row .qty_bx {
    width: 56%;
    vertical-align: middle;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    width: 42%;
    padding-left: 8px;
    padding-right: 8px;
}

.special_product {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    display: inline-block;
    width: 26px;
    height: 23px;
}

.special_product::after {
    position: absolute;
    content: "";
    top: 0;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -458px -19px;
    z-index: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
}

.innerproduct-item {
    position: relative;
}

.innerproduct-item-image {
    background: #fff;
    text-align: center;
}

.innerproduct-item-image img {
    height: auto;
    width: auto;
}

.innerproduct-item-hei {
    height: 255px;
    line-height: 255px;
    text-align: center;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.86);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-button {
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 2;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s all ease-in-out;
    -webkit-transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover .innerproduct-button,
.innerproduct-col.open .innerproduct-button {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

.innerproduct-item-text {
    text-align: center;
}

.innerproduct-item-text h4 {
    font-size: 16px;
    color: #363636;
    margin-bottom: 0;
}

.innerproduct-item-text h4 a {
    color: #363636;
}

.innerproduct-item-text p {
    font-weight: 300;
}

.innerproduct-item-text p a {
    color: #5b5b5b;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    color: #363636;
    margin-bottom: 0;
}

.innerproduct-item-text h5 a {
    color: #363636;
}

.price_none {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    color: #999;
    padding-right: 10px;
}

.price_offer .price_none {
    width: 50%;
    text-align: left;
}

.price_offer .price_nor {
    width: 50%;
    text-align: right;
    display: inline-block;
    margin-left: -4px;
}

.inn-product-popup {
    -webkit-box-shadow: 0px 2px 10px 0px rgba(2, 2, 2, 0.15);
    box-shadow: 0px 2px 10px 0px rgba(2, 2, 2, 0.15);
    border: 1px solid #d4d4d4;
    margin: 20px auto 0 auto;
    background: #fff;
}

.inn-product-popup .container {
    padding: 0;
}

.prodet_bansec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.prodet_banleft {
    width: 47%;
}

.prodet_baninfo {
    width: 53%;
    padding: 35px 30px 20px 30px;
    position: relative
}

.prodet_baninfo .product-tags-list {
    position: relative;
    bottom: inherit;
    float: none;
    margin: 0 0 15px 0
}

.prodet_baninfo .product-tags-list ul {
    padding: 0;
    margin: 0
}

.inn-product-popup-bg {
    position: relative;
}

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner {
    position: relative;
    padding-top: 0px;
    line-height: 1;
}

.inner_product_slide {
    outline: 0;
}

.inn-product-popup-inner .slick-next,
.inn-product-popup-inner .slick-prev {
    display: none !important
}

.inn-product-popup-inner .custom-dots {
    padding: 0;
    position: absolute;
    bottom: 12px;
    left: 0;
    right: 0;
    margin: 0;
    text-align: center;
}

.inn-product-popup-inner .custom-dots li {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}

.inn-product-popup-inner .custom-dots li button {
    padding: 0;
    margin: 0
}

.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #d45d38;
}

.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

.inner_product_slide {
    position: relative;
}

.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}

.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}

.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
    float: left;
}

.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}

.popup_social_icon li a:hover {
    color: #ccc;
}

.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_social_left {
    padding-right: 10px;
}

.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}

.inn_product_row {
    margin: 0 auto 0 auto;
}

.inn_product_hea p {
    color: #5b5b5b;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.inn_product_hea_left h3 {
    font-size: 26px;
    margin: 0 0 10px 0;
    color: #000
}

.inn_product_hea_icon {
    padding-right: 45px;
    display: inline-block;
}

.inn_product_hea_icon img {
    margin: 0 2px;
}

.inn_product_hea_star .checked {
    color: #72624f;
}

.inn_product_hea_star .star {
    color: #d7cbb9;
}

.inn_product_review_text a {
    text-decoration: underline;
    color: #5b5b5b;
    font-weight: 700;
}

.inn_product_review_text {
    padding-left: 10px;
}

.inn_product_review {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.prd_chosen_row {
    padding: 35px 40px 30px 40px;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.product_chosen_col {
    width: 47.5%;
    margin-right: 4.8426150121065374%;
}

.product_chosen_col.product_chosen_col_right {
    margin-right: 0;
}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}

.product_chosen_col_inner {
    margin-bottom: 38px;
    margin-left: -15px;
    margin-right: -15px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.product_chosen_item_left {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 15px;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.product_chosen_item_left_second {
    float: left;
    width: 47%;
    margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}

.select_full {
    width: 100%;
}

.product_chosen_hea h6 {
    font-size: 18px;
    color: #1c1c1c;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 10px;
    font-family: 'Calibri-Bold';
}

.product_chosen_hea {
    position: relative;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.pmodifier_ddlist ul {
    padding: 0;
    margin: 7px 0;
    list-style: none;
}

.pmodifier_ddlist li {
    background: #ecdfcc;
    padding: 3px 35px 3px 17px;
    border: 1px solid #dcd8d8;
    border-radius: 20px;
    position: relative;
    font-weight: 700;
    color: #181818;
    margin: 0 0 4px;
}


.pmodifier_close:hover {
    background-image: url(../images/cross1.png);
    background-color: #1c1c1c;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_left {
    max-width: 300px;
}

.chosen_adn_right {
    line-height: 0;
}

.chosen_adn_left span {
    font-size: 17px;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.radio.chosen_adn_right label {
    padding-left: 0;
}
.product_chosen_col_right .custom_checkbox {
    width: 100%;
}
.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
    right: 0;
    left: auto;
    top: -5px;
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 14px;
    color: #181818;
    text-align: right;
    float: right;
    margin-top: 6px;
    font-weight: 700;
}

.text-box textarea {
    width: 100%;
    height: 120px;
    padding: 15px 15px;
    font-size: 16px;
    color: #999898;
    border: 1px solid #e6e5e5;
}

textarea {
    resize: none
}

.chosen_upload {
    float: right;
    text-align: right;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}

.prd_chosen_sub_row {
    text-align: center;
    margin: 15px 0 0 0;
    background: #f9f9f9;
    padding: 30px
}

.prd_chosen_sub_row > p {
    font-size: 30px;
    color: #000;
}

.prd_chosen_sub_row p > sup {
    font-size: 20px;
}

.prd_chosen_sub_col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 576px;
    margin: 0 auto;
}

.prd_chosen_sub_col .alert {
    margin: 0;
    width: 100%;
}

.prd_chosen_sub_item_left {
    width: 32.11805555555556%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.prd_chosen_sub_item_right {
    width: 67.64705882352942%;
}

.prd_chosen_sub_item_right button {
    border: 0;
    padding: 15px 15px;
    max-width: 390px;
    width: 100%;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    outline: 0;
}

.prd_chosen_sub_item_right button:hover {
    background: #000;
}

.prd_chosen_sub_item_right.prd_chosen_item_full {
    width: 100%;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
    max-width: 100%;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
    background: #000;
}

.prd_chosen_sub_item_left .qty_bx {
    width: 126px !important;
}

.prd_chosen_sub_item_left .qty_bx .qty_minus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx .qty_plus {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd_chosen_sub_item_left .qty_bx input {
    color: #fff !important;
}

.detail-pg-slider .slick-slider {
    padding: 0 0px;
}

.detail-pg-inner {
    padding: 60px 0px 10px;
}

.detail-pg-slider {
    height: 100%
}

.detail-pg-slider .slick-dots {
    padding: 40px 0 0 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.detail-pg-slider .slick-dots button {
    padding: 0;
    margin: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
    line-height: 1;
}

.detail-pg-slider .slick-dots li.slick-active button {
    background: #cb4d36;
}

.detail-pg-slider .slick-dots li {
    display: inline-block;
    margin: 10px 5px 0;
}

.detail-pg-slider-comman .innerproduct-col {
    width: auto;
    margin-bottom: 0;
    height: 100%;
}

.detail-pg-slider-comman .slick-slide {
    padding: 0 5px;
    outline: 0;
}

.detail-pg-slider-comman .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 20px;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    display: none !important;
}

.detail-pg-slider-comman .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.detail-pg-slider-comman .slick-next:hover {
    background: #f5f5f5;
}

.detail-pg-slider-comman .slick-next:hover::after {
    background-position: -154px -123px;
}

.detail-pg-slider-comman .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 17px;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    display: none !important;
}

.detail-pg-slider-comman .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.detail-pg-slider-comman .slick-prev:hover {
    background: #f5f5f5;
}

.detail-pg-slider-comman .slick-prev:hover::after {
    background-position: -213px -123px;
}

.detail-pg-slider-comman .slick-prev:hover {
    background-position: -213px -123px;
}

.detail-pg-inner .filter_tabsec .nav-tabs {
    margin-bottom: 35px;
}

.detail-pg-inner .filter_tabsec {
    margin-bottom: 0;
}

.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 89px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}

.skip:hover {
    color: #fff;
    background: #333;
}

.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}

.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}

.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.prd-sub .slick-slide {
    padding: 0 5px;
}

.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-prev:hover {
    background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.check_pg {
    position: relative;
}

.check_pg_inner {
    background: #fff;
    padding: 75px 0px 30px 0px;
    margin-bottom: 14px;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 30px;
    height: 30px;
    background: url(../images/arrow.svg) no-repeat;
    position: absolute;
    background-position: center;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
    background-size: contain;
    transform: rotate(180deg);
}
.check_pg_inner .slick-next:hover {
    background: #fdd24a;
}
.check_pg_inner .slick-next:hover::after {
    background: url(../images/arrow1.svg) no-repeat;
    background-size: contain;
}

.check_pg_inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}
.check_pg_inner .slick-prev::after {
    content: "";
    width: 30px;
    height: 30px;
    background: url(../images/arrow.svg) no-repeat;
    position: absolute;
    background-position: center;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
    background-size: contain;
}
.check_pg_inner .slick-prev:hover {
    background: #fdd24a;
}

.check_pg_inner .slick-prev:hover::after {
    background: url(../images/arrow1.svg) no-repeat;
    background-size: contain;
}
.check_out_fourcommon {
    width: 100%;
    margin: 0 auto;
}
.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}

.cdd-details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
    border: 0px solid #dddcdc
}

.cdd-details .ttsplitter {
    margin-bottom: 0;
}

.checkout-total {
    width: 100%;
    margin: 20px auto 0px auto
}

.checkout-total::after {
    display: block;
    content: "";
    clear: both
}

.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {
    padding: 14px 20px;
    position: relative;
    background: #87311d;
    border-bottom: 0px;
    text-align: center;
    font-size: 24px;
    width: 100%;
    margin-bottom: 0
}

.checkout-total .cart-header {
    padding: 0;
    background: inherit;
    border: 0;
    margin: 0
}

.checkout-total .cdd-details-rhs .cart-header h4 {
    margin: 0;
    line-height: normal
}

.checkout-total .cart-header:after {
    display: none
}

.checkout-total .cdd-details-lhs {
    width: 50%;
    position: relative;
    padding: 0px 20px 30px 30px;
    background: #fff;
}

.checkout-total .cdd-details-rhs {
    width: 50%;
    padding: 0px 30px 30px 20px;
    background: #fff;
    position: relative;
}

.checkout-right-body-section {
    padding: 30px 0 0 0;
}

.checkout-total .controls-single input[type="text"] {
    color: #6f6f6f;
    font-size: 17px;
}

.chk_hea {
    font-size: 19px;
    padding: 10px 20px;
    background: #ebe9e9;
    color: #3d3d3d;
    font-family: 'Calibri-Bold';
    display: block;
    margin: 0 0 10px 0;
}

.chk_hea > span {
    font-family: 'Calibri';
    color: #393939;
    font-size: 17px;
    float: right;
}

.checkout-total .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.checkout-total .checkout-control-group-top a {
    font-size: 16px;
    color: #393939;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 20px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-total .checkout-control-group-top a:hover {
    color: #d45d38
}

.checkout-total .checkout-control-group-top {
    padding: 30px 0 0px 0;
}

.checkout-control-group-topband {
    border-bottom: 1px solid #939292;
    padding: 0 0 35px 0
}

.checkout-control-group-topband span {
    float: right;
    font-weight: normal;
    font-size: 15px
}

.checkout-total .checkout-control-group-middle {
    margin: 0px 0 0 0;
    padding: 25px 0 0px 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #333;
}

.checkout-total .checkout-control-group-bottom {
    padding: 0
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker {
    width: 48%
}

.checkout-total .checkout-control-group-middle .form-group .input_area {
    width: 49%
}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 20px 30px;
    color: #615f5f;
    height: 160px;
    font-size: 15px;
    width: 100%;
    outline: 0;
    border: 1px solid #e2e2e2;
}

.checkout-total textarea::-webkit-input-placeholder {
    color: #595656;
}

.checkout-total textarea:-moz-placeholder {
    color: #595656;
}

.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .cart_body {
    padding: 0
}

.checkout-total .form-group.controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.checkout-total .form-group.controls-three > div {
    width: 32%;
}

.checkout-total .form-group.controls-three > div input {
    margin-bottom: 0
}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}
.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
    color: #1c1b1b;
    line-height: 22px;
    font-size: 17px
}

.delivery_total_left span {
    display: block;
    color: #000;
    line-height: 20px;
    font-size: 14px
}

.delivery_total_left.delivery_total_right {
    float: right;
    text-align: right
}

.delivery_total.delivery_total_number {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.delivery_total.delivery_total_number .delivery_total_left h2 {
    font-size: 19px;
    color: #3d3d3d;
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: 'Calibri-Bold';
}

.delivery_total.delivery_total_number .delivery_total_left h4 {
    color: #060203;
    font-size: 26px;
    margin-bottom: 0;
    font-family: 'Calibri-Bold';
}

.pickup-order-div:before {
    display: none
}

.pickup-order-div .delivery_total_left {
    width: 100%;
    text-align: center
}

.pickup-order-div .delivery_total_left img {
    width: 25px;
}

.cart-product-desc-inner {
    padding-left: 20px
}

.cart-product-desc-inner span {
    display: block;
    color: #3d3d3d;
    line-height: 19px;
    font-size: 14px
}
.chekout_cart_bar .cart_footer {
    padding: 30px 40px 10px 10px;
}
.chekout_cart_bar .cart_left {
    padding-left: 0;
}
.chk_hea .custom_checkbox {
    vertical-align: top
}
.chk-payment-btn-row {
    margin: 10px 0 0 0
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 21px;
    padding: 16px 10px
}

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
    width: 49%
}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem::after {
    display: block;
    clear: both;
    content: "";
}

.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.redeem-col {
    width: 49%;
    background: #c6f193;
    padding: 40px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.redeem-col-full{width: 50%}
.redeem-col-full .redeem-item{     max-width: 660px;
    margin: 0 auto;}
.redeem-col.redeem-col-right {
    background: #efed74;
}

.redeem-item-hea {
    position: relative;
}

.redeem-item-hea h4 {
    font-size: 25px !important;
    text-transform: uppercase;
    color: #363636;
    margin-bottom: 0;
    letter-spacing: -.5px;
}

.redeem-item-hea span {
    font-size: 16px;
    color: #2e2e2d;
}

.redeem-item-hea .points {
    position: absolute;
    right: 0;
    background: #1c1c1c;
    padding: 8px 0;
    max-width: 280px;
    color: #ecdfcc;
    display: block;
    width: 100%;
    text-align: center;
    top: 8px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    font-size: 18px;
    font-family: 'Calibri-Bold';
}

.redeem-item-hea .points b {
    color: #fff;
    font-family: 'Calibri-Bold';
}

.redeem-item-hea .points:hover {
    background: #54362c;
    color: #fff;
}

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0
}
.redeem_apply .btn:hover {
    background: #d45d38;
    color: #fff;
}
.redeem_apply .form-control {
    height: 46px;
}
.redeem-item-hea-inner {
    width: 42%;
}
.chk-payment {
    background: #fefefe;
    padding: 0px 10px 35px 10px;
    text-align: center;
}

.chk-payment .ttsplitter span {
    background: #fefefe;
    z-index: 1;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.chk-payment-col-radio li {
    max-width: 257px;
    margin-right: 10px;
    padding: 15px 20px 15px 20px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.chk-payment .chk-payment-col-radio li label {
    font-size: 16px;
    font-weight: 700;
    padding-left: 42px !important;
    text-transform: uppercase;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-top: -20px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 22px;
    height: 22px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 22px;
    height: 22px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -399px -125px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:after {
    width: 28px;
    height: 28px;
    background-position: -455px -122px;
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
.product_chosen_col_inner [type="radio"]:not(:checked) + label:before {
    width: 28px;
    height: 28px;
}

.chk-payment-col-radio.promotion-ul-topcls li {
    width: auto;
    margin-right: 0
}

.product_chosen_col_inner [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    top: 0px;
}

.product_chosen_col_inner [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    top: 0px;
}

.chosen_radio_inner {
    width: 100%;
}

.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}

.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}

.expand_sub h1 span {
    width: 80%;
}

.panel-heading.expand_hea {
    cursor: pointer;
}

.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

.expand_sub_body {
    width: 100%;
}

.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}
.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.chk-payment-btn-row .btn {
    width: 100%;
    border: 0;
}
.chk-payment-btn-row .row {
    margin-right: 0;
    margin-left: 0;
}
.chk-payment-btn-row [class*="col-"] {
    padding: 0;
}
.tnk-you {
    position: relative;
    padding: 110px 0;
}
.tnk-you::after {
    display: block;
    clear: both;
    content: "";
}

.tnk-header {
    max-width: 683px;
    margin: 0 auto;
    text-align: center;
}

.tnk-header .tick {
    padding: 0 15px;
}

.tick h2 {
    font-size: 44px;
    margin: 18px 0 0 0;
}

.tick span {
    font-size: 18px;
    color: #0b0405;
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #fff;
    text-transform: uppercase;
    background: #000;
    margin-bottom: 0;
    font-size: 24px;
}

.tnk-order {
    background: #ea2876;
    padding: 20px 15px;
}

.tnk-order h3 {
    margin-bottom: 5px;
    font-size: 34px;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Calibri-Bold';
}

.tnk-order p {
    font-size: 17px;
    font-weight: 300;
    color: #000;
    margin-bottom: 0;
}

.tnk-delivery .order-detail-inner {
    padding-top: 0px;
}

.tnk-delivery {
    padding: 35px 30px;
    background: #fff;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
    display: none;
}

.tnk-delivery .order-detail-inner-icon {
    margin: 0 auto;
}

.tnk-delivery .delivery_total {
    margin: 6px 0 8px 0;
}

.tnk-delivery .cart_row {
    padding: 18px 10px 5px 7px;
}

.tnk-delivery .cart_left .help-block {
    text-align: left;
}

.tnk-delivery .cart_info h4 {
    text-align: left;
}

.tnk-delivery .cart_extrainfo {
    text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 20px 20px 5px 20px;
    margin: 0;
}

.tnk-delivery .tnk_cart_footer .cart_row {
    text-align: left;
    padding: 0px 0px 5px 0px;
}

.tnk-chk-order .btn {
    width: 100%;
    border: 0;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.orderitem_body_div {
    background: #f6f6f6;
    padding: 10px;
}

.orderitem_body_div .cart_row .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0 7px;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 16px;
}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}

.pouup_in {
    padding: 60px 50px 40px;
}

.alert_popup,
.warining_popup,
.warning_popup,
.pay-conf-popup {
    max-width: 450px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close,
button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #181818;
    color: #fff;
    font-size: 18px;
    padding: 12px 14px;
    position: relative;
    text-align: center;
}

.alert_body {
    text-align: center;
    padding: 20px;
}
.alt_btns a {
    margin: 0 5px;
}
.alt_btns a.button-left {
    background: #ea2876;
}
.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit
}

.pop_title small {
    font-size: 19px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.innervmid_in {
    width: 100%
}

.delevery_popup .popup_ttsec {
    background: #5d8d87;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}

.two-button-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
}

.two-button-row > div {
    width: 49%
}

.two-button-row > div.active .button {
    background: #809b66;
}

.two-button-row .delivery_submit {
    background: #d45d38
}

.go_second a {
    background: #5d8d87
}

.two-button-row .delivery_submit:hover,
.go_second a:hover {
    background: #869f6e
}

.error_postal_popup .popup_right .button {
    margin: 0
}

img.pop-scooter-img {
    max-width: 150px;
}

.receipt_popup,
.receipt_cateringpopup {
    max-width: 683px;
}

.receipt_popup .pouup_in,
.receipt_cateringpopup .pouup_in {
    padding: 0;
}

.receipt_popup .tnk-you,
.receipt_cateringpopup .tnk-you {
    padding: 0;
}

.receipt_popup .tnk-detail,
.receipt_cateringpopup .tnk-detail {
    margin-top: 0
}

.receipt_popup .Viewreceipt,
.receipt_cateringpopup .Viewreceipt {
    margin: 30px 0
}

.error_postal_popup .popup_ttsec {
    background: #5d8d87
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    text-align: center;
    padding: 64px 0;
    border: 2px solid #d45d38;
}

.order-body h2 {
    color: #040507;
    font-size: 36px;
    margin-bottom: 0;
    text-transform: uppercase;
}

.order-body p {
    color: #333;
    font-size: 20px;
    font-family: 'raphtalia_demoregular';
    margin: 0 0 20px 0;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    padding: 0 20px;
}

.order_delivery_item li {
    width: 25%;
}

.order_delivery_item li a {
    display: block;
    position: relative;
}

.order_delivery_item h3 {
    color: #fff;
    font-size: 18px;
    margin: 10px 0 0 0;
}

.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}

.header-ordernow-single-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.order_delivery_item li .header-ordernow-single-img img {
    height: 70px
}

.mfp-close-btn-in .order_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close,
.mfp-close-btn-in .self_popup .mfp-close,
.mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close,
.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close,
.changepw_popup .mfp-close,
.redeem_popsec .mfp-close,
.pay-conf-popup .mfp-close,
.warning_popup .mfp-close {
    background: #809b66;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;
}

.mfp-close-btn-in .order_popup .mfp-close::after,
.mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after,
.mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after,
.mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after,
.changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after,
.pay-conf-popup .mfp-close:after,
.warning_popup .mfp-close:after, .venue_popup .close-icon-but:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover,
.mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover,
.mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover,
.redeem_popsec .mfp-close:hover,
.pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover,
.venue_popup .close-icon-but:hover{
    background: transparent;
}

.mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(255, 255, 255, 0.2);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: transparent;
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #5d8d87;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #d45d38;
    color: #fff
}

.awesome_del {
    margin-top: 13px;
    text-align: center;
}

.awesome_del h5 {
    font-size: 19px;
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Calibri-Bold';
}

.awesome_del h2 {
    color: #fff;
    font-size: 17px;
    font-family: 'Calibri';
}

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight: 700; padding-bottom: 10px; color: #383838; font-size: 16px}
.delevery_popup_datetime .btn_sec .button{ display: block; margin: 0; width: 100%; }

.white-popup.self_popup {
    max-width: 560px;
}

.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    background: #d45d38;
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 21px 15px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}


.self_pop_col_right h4 {
    font-size: 16px;
    font-weight: 700;
    color: #595656;
    text-align: left;
    margin-left: 30px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000000;
    font-size: 18px;
    text-align: left;
    margin: 0px 0 10px 5px;
    text-transform: initial;
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height: 190px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}
.self_outlet_inner li a {
    background: #f8f8f8;
    padding: 10px 15px;
    display: block;
    font-size: 15px;
    color: #595656;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
}
.self_outlet_inner li a:hover,
.self_outlet_inner li.active a {
        background: #cb4d36;
        color: #fff;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block
}

.self_pop_locbx {
    margin: 0 0 20px 0
}

.white-popup.login-popup,
.white-popup.signup-popup,
.white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup,
.white-popup.guest-popup {
    background: inherit;
    max-width: 1086px;
}
.pop-whole-lhs-inner {text-align: center; max-width: 318px;}
.pop-whole {position: relative}

.login-popup .mfp-close, 
.signup-popup .mfp-close,
.signup-popup .mfp-close,
.signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close,
.guest-popup .mfp-close,
.pdetail_popup .mfp-close {
    width: 35px; height: 35px; line-height: 35px; right: 49px;
    top: 0; font-size: 0; color: #fff;  margin: 0
}

.login-popup .mfp-close:hover, 
.pdetail_popup .mfp-close:hover,
.signup-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover,
.signup-corparatetwo-popup .mfp-close:hover,
.guest-popup .mfp-close:hover {
    background:#000;
}

.login-popup .mfp-close:after, 
.pdetail_popup .mfp-close:after,
.signup-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after,
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after {
    content: "";  background-image: url(../images/crosslg1.png); background-position: center;
    background-repeat: no-repeat; width: 15px; height: 15px; position: absolute; left: 0; right: 0; 
    margin: auto;  top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); z-index: 1;
}

.login-popup .resend_link {
    padding: 10px 0px;
    text-align: right;
}

.login-popup .resend_link a {
    color: #0dad85;
    font-weight: 600;
}

.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #000;
    font-size: 16px;
    margin: 0 0 3px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #869F6E;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}

.inside-popup-rhs {
    float: right;
    max-width: 514px;
    background: #fff;
    padding: 45px 55px 0px 55px;
    position: relative;
    z-index: 9;
    margin: 0 49px 0 0;
    width: 100%;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all
}

.signup-popup .inside-popup-rhs,
.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs {
    padding: 45px 55px 30px 55px;
}

.signup-corparatetwo-popup .inside-popup-rhs,
.guest-popup .inside-popup-rhs {
    padding: 60px 55px 40px 55px;
}

.pop-whole-lhs {
    position: absolute;
    background: #5d8d87;
    padding: 124px 50px 124px 90px;
    left: 0;
    right: 0;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.forgot-password-popup .pop-whole-lhs {
    padding: 84px 50px 84px 60px;
}

.forgot-password-popup .pop-whole-lhs-inner {
    text-align: left;
    max-width: 328px;
}

.pop-whole-lhs h3 {
    font-size: 36px;
    margin: 0 0 4px 0;
    line-height: 1;
    color: #fff;
}

.pop-whole-lhs p {
    font-size: 16px;
    color: #fff;
    margin: 0
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}

.popup-header {
    padding: 15px 0 24px 0;
}

.popup-header h4 {
    font-size: 16px;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    text-transform: inherit
}

.popup-header h4 img {
    width: 163px;
    margin: 0 0 0 10px
}

.popup-header p {
    font-size: 13px;
    margin: 0px;
}

.popup-body input {
    margin: 0 0 8px;
    font-size: 16px;
    color: #595656;
}

.two-links {
    padding: 10px 0 12px 0;
    text-align: center;
}

.two-links a {
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    color: #272727;
    font-family: 'Calibri-Bold';
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease
}

.two-links a:hover {
    color: #000
}

.spilter {
    width: 2px;
    height: 15px;
    background: #272727;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 25px 0;
    position: relative;
    left: 0;
}

.or-seperator {
    position: relative;
    padding: 15px 0 7px 0;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 0 10px;
    background: #fff;
    display: inline-block;
    z-index: 1;
    position: relative
}

.or-seperator:after {
    height: 1px;
    width: 100%;
    content: "";
    display: block;
    background: #0e0e0e;
    position: absolute;
    top: 55%;
    left: 0
}

.login_pop_sub .btn {
    width: 100%;
}

.guest_chk_out {
    margin-left: -55px;
    margin-right: -55px;
}

.guest_chk_out .btn {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    color: #f1e0c6;
}

.guest_chk_out .btn b {
    color: #fff;
}

.guest_chk_out .btn:hover {
    color: #fff;
}

.popup-body h4 {
    text-transform: uppercase;
    margin: 0 0 11px 15px;
    font-weight: 700;
    font-size: 16px;
    color: #0e0e0e;
}

.popup-body h4.your_lgn_spacing {
    margin-top: 19px;
}

.popup-body .custom_checkbox {
    width: 100%;
    margin: 7px 0 9px 0;
}

.popup-body .sign_reg {
    margin-left: 43px;
}

.popup-body .sign_reg a {
    width: 100%;
    color: #595656;
}

.popup-body .sign_reg a:hover {
    background: #333;
    color: #fff;
}

.popup-body .custom_checkbox input {
    border: 1px solid #e2e2e2;
    width: 27px;
}

.popup-body .custom_checkbox span:before {
    top: 11px;
    width: 27px;
    height: 27px;
    border: 2px solid #e7d8c1;
    border-radius: 2px;
}

.popup-body .custom_checkbox input[type=checkbox]:checked + span:before {
    background-position: -30px -186px;
}

.popup-body .sign_reg p {
    margin-bottom: 9px;
    color: #0e0e0e;
    line-height: 18px;
    font-weight: 300;
}

.popup-body .custom_checkbox.custom_checkbox_content span:before {
    top: 0;
}

.popup-body .sign_reg a.sign_reg_uline {
    text-decoration: underline;
    color: #ea2876;
}

.popup-body .sign_reg a.sign_reg_uline:hover {
    color: #d45d38;
    background: inherit;
}

.row.pop-two-form {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.back_click {
    position: absolute;
    left: 19px;
    top: 13px;
}

.back_click a {
    color: #c3c3c3;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-left: 24px;
}

.back_click a:hover {
    color: #333;
}

.back_click a::after {
    content: "";
    position: absolute;
    left: 0;
    width: 11px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -344px -67px;
    top: 2px;
}

.back_click a:hover::after {
    background-position: -375px -67px;
}

.changepw_popup {
    max-width: 768px;
}

.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_popsec {
    max-width: 600px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}


.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.mapcanvas_ttsec h3 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 700;
}

.mapcanvas_bx {
    padding: 12px 18px;
    color: #000;
}

.mapcanvas_bx h5 {
    color: #888;
    margin: 0;
    font-size: 13px;
}

.mainacc_menusec {
    margin: 0 0 40px;
}

.mainacc_menulist {
    padding: 0 10%;
    list-style: none;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.mainacc_menulist > li {
    width: 25%;
    border-left: 3px solid #fff0;
}

.mainacc_menulist > li >a {
    background: #ffffff;
    color: #000;
    font-size: 18px;
    text-transform: uppercase;
    padding: 17.5px 15px;
    display: block;
    position: relative;
    letter-spacing: 0.5px;
    text-align: center
}

.mainacc_menulist > li.active >a,
.mainacc_menulist > li >a:hover {
    background: #d45d38;
    color: #fff;
}

.mbacc_mslidersec {
    display: none;
}

.mainacc_moblink {
    display: none;
}

.myacc_hea {
    position: relative;
    margin: 0 0 14px;
}

.myacc_hea::after,
.myacc_pg::after {
    display: block;
    content: "";
    clear: both;
}

.myacc_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.myacc_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.myacc_hea_inner {
    margin-top: 8px;
}

.box_in {
    background: #fff;
}

.account_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.accmenu_sec {
    background: #5d8d87;
    width: 320px;
    display: inline-block;
    position: relative;
    color: #fff;
}

.accprofile {
    padding: 26px 20px 0px;
}
.member-ship-main {
    border: 2px solid #000;
    border-radius: 10px;
    background: #fff;
    color: #000;
    padding: 20px 15px;
    text-align: left;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.3);
    box-shadow: 0px 3px 10px 0px rgba(1, 1, 1, 0.3);
    margin: 30px 0 20px 0;
}
.member-ship-main h3 {
    font-family: 'subway_novellaregular';
    text-align: center;
    font-size: 25px;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto 0;
    overflow: hidden;
}
.member-ship-silver h4, .member-ship-gold h4{position: relative;
    z-index: 1;
    color: #686766;
    font-family: 'Calibri-Bold';
    text-align: center;
    text-transform: uppercase;
    font-size: 17px;margin: 0 0 15px 0;}
.member-ship-gold h4{ color: #d18029; }
.member-ship-silver h4:after, .member-ship-gold h4:after {
    height: 2px;
    width: 100%;
    content: "";
    background: #686766;
    position: absolute;
    left: 0;
    right: 0;
    z-index: -1;
    top: 50%;
    margin-top: -1px;
}
.member-ship-gold h4:after{ background: #d18029; }
.member-ship-silver h4 span, .member-ship-gold h4 span {
    display: inline-block;
    background: #fff;
    padding: 0 10px;
}
.member-ship-silver p, .member-ship-gold p{  margin-bottom: 5px; }
.member-ship-gold {  margin: 30px 0 0 0;}
.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 16px;
    color: #fff;
    font-weight: normal;
    margin: 0;
}

.accpoints_sec {
    padding: 15px 7px 0px
}

.accpoint_tt span {
    background: #fff;
    padding: 0 4px;
    position: relative;
    display: inline-block;
}

.accearn_points {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 15px 10px;
}

.accearn_pointtop {
    position: relative;
    padding-left: 52px;
}

.accearn_pointtop i {
    position: absolute;
    left: 0;
    top: 7px;
}

.accearn_pointtop h4 {
    font-size: 15px;
    margin: 0;
    color: #fff
}

.accearn_pointtop span {
    font-size: 45px;
    display: inline-block;
    line-height: 0.8;
    color: #ffc800;
}

.accearn_pointtop sub {
    font-size: 60%;
    bottom: 0;
}

.accearn_points p {
    font-size: 13px;
    margin: 0;
}

.accpoint_ec {
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.accsec_right {
    width: calc(100% - 320px);
    width: -webkit-calc(100% - 320px);
    display: inline-block;
    padding: 40px 40px;
}

.accsec_right h1 {
    font-size: 36px;
    color: #040507;
    margin-bottom: 9px;
}

.accsec_right p {
    color: #000000;
    font-size: 17px;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0px 14px;
}

.other-add-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.2%;
    margin-right: 1.7%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}

.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.other-add-row span:hover {
    opacity: 0.5;
}

.setting_menu_list {
    position: relative;
    margin: 20px 0 0 0;
}

.setting_menu_list > ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.setting_menu_list > ul li {
    position: relative;
}

.setting_menu_list > ul > li > a {
    padding: 12.5px 10px 12.5px 20px;
    background: rgba(255, 255, 255, 0.11);
    color: #fff;
    display: block;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, 0.11);
    margin-bottom: 0;
}

.setting_menu_list > ul li a:hover {
    background: #809b66;
    color: #fff;
}

.setting_menu_list > ul li:last-child a {
    border-bottom: 0;
}

.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}

.setting_menu_list_icon {
    background: url(../images/spirite_whole.png) no-repeat;
    display: inline-block;
    margin-right: 16px;
    position: relative;
}

.setting_menu_list_icon.password_icon {
    width: 15px;
    height: 18px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.password_icon {
    background-position: -326px -256px;
}

.setting_menu_list_icon.setting_icon {
    width: 14px;
    height: 14px;
    background-position: -365px -259px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.setting_icon {
    background-position: -392px -259px;
}

.setting_menu_list_icon.logout_icon {
    width: 20px;
    height: 20px;
    background-position: -462px -255px;
    top: 4px;
}

.setting_menu_list li a:hover .setting_menu_list_icon.logout_icon {
    background-position: -462px -255px;
}

.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}

.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu li a {
    color: #fff;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 0;
    right: -12px;
    padding-top: 0;
}

.main_tabsec_inner {
    background: #fff;
    padding: 30px 30px;
    border-radius: 0px;
}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal;
}

.count_num_t {
    background: #a7a7a7;
    width: 70px;
    height: 100%;
    text-align: center;
    font-style: normal;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    color: #0b0405;
    font-size: 20px;
    border-top-left-radius: 3.2em 5em;
    border-bottom-left-radius: 3.2em 5em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}


.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 5px solid #fff;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    background: #0b0405;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'Calibri-Bold';
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-family: 'Calibri-Bold';
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-family: 'Calibri-Bold';
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}

.order-cat {}

.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.earned_points {
    max-width: 300px;
    padding-left: 0;
    list-style: none;
    float: right;
    padding-right: 24px;
}

.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.redeem_popsec {
    max-width: 900px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 50px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
    width: 25%;
    height: 270px;
}

.redeem_popsec .promo-earned-content {
    width: 75%;
    padding: 50px 10px 40px;
}

.redeem_popsec .promo-earned-content h4 {
    font-size: 28px;
    max-height: inherit;
}

.redeem_popsec .promo-earned-content h2 {
    font-size: 42px;
    color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
    margin: 7px 0 0;
}

.redeem_popsec .abc_code {
    padding: 20px 0 0px;
}

.redeem_popsec .promo-earned-content h2 span sup {
    font-size: 26px;
}

.redeem_pop_image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.redeem_popsec .pouup_in {
    padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}

.redeem_popsec .btn {
    background: #ecdfcc;
    color: #644a40;
    display: block;
    width: 95%;
    border-radius: 8px;
    margin: 0 auto 17px;
}

.redeem_popsec .btn:hover {
    background: #644a40;
    color: #ecdfcc;
}

.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius: 7px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 7px;
    right: 7px;
}

.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area {
    position: relative;
}

.input_area {
    position: relative;
}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
    width: 100%;
    background: #2d4486;
    padding: 14px 15px;
    border-radius: 7px;
    font-size: 17px;
    font-family: 'Calibri-Bold';
}

.pop-whole .kep-login-facebook.metro:hover {
    background: #333;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    background: #060203;
    border-radius: 5px;
    padding: 10px 2px;
    line-height: 12px;
}

.compo_acc_innerdiv {
    padding: 10px 20px 0px 20px;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #fdd24a;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.order_nav {
    position: relative;
}

.order_nav > .nav-tabs:before {
    content: '';
    background: #dddcdc;
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.order_nav > .nav-tabs {
    position: relative;
    border: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.order_nav > .nav-tabs>li {
    margin: 0;
    text-align: center;
    border-left: 2px solid transparent;
}

.order_nav > .nav-tabs>li.active>a,
.order_nav > .nav-tabs>li.active>a:focus,
.order_nav > .nav-tabs>li.active>a:hover {
    border: 0;
    background: #d45d38;
    color: #fff;
}

.order_nav > .nav-tabs>li>a {
    background: #fff;
    margin: 0px 1px;
    border-radius: 0;
    border: 0;
    color: #181818;
    font-size: 18px;
    text-transform: uppercase;
    padding: 15px 22px;
    display: block
}

.nos {
    background: #473f3c;
    border-radius: 100%;
    padding: 2px 2px;
    margin-right: 7px;
    color: #fff;
    border: 2px solid #0b0405;
    width: 32px;
    height: 32px;
    display: inline-block;
    font-size: 14px;
    vertical-align: middle;
    line-height: 24px;
}

.order_nav .tb-padding {
    padding: 60px 0;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.choose_location {
    position: relative;
    border: 2px solid #cfbfaa;
    border-radius: 5px;
    padding: 50px 40px 30px;
    margin-bottom: 50px;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38)
}

.chosen-alert-cnt {
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.chosen-alert-cnt span {
    background: #fb0a37;
    padding: 6px 15px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 3px 4px rgba(1, 1, 1, 0.17);
}

.choose_location h4 {
    color: #3d3d3d;
    font-size: 19px;
    font-family: 'Calibri-Bold';
    margin: 0 0 5px 0
}

.choose_location .custom_radio span {
    color: #3d3d3d;
    font-size: 18px;
    line-height: 35px;
    padding: 0 0 0 42px;
    font-family: 'Calibri-Bold';
}

.choose_location .custom_radio span:before {
    width: 32px;
    height: 32px;
}

.choose_location [type="radio"]:checked,
[type="radio"]:not(:checked) {
    left: auto;
    opacity: 0;
}

.choose_location .custom_radio input[type=radio]:checked + span:before {
    background: #72624f;
    background-position: -399px -125px;
    z-index: 1;
    border: 1px solid #120b0c;
}

.divide {
    width: 90%;
    background: #c7c3c3;
    height: 2px;
    margin: 10px 0px 10px 40px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #cfbfaa;
    background: #d2be9b;
    background: rgba(210, 190, 155, 0.38);
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3,
h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
    font-family: 'subway_novellaregular';
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #626161;
    width: 140px;
    margin: 0
}

.ordering-more .button.yes {
    background: #d45d38;
    width: 140px;
    margin: 0
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #000
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 20px;
    font-family: 'Calibri-Bold';
    margin: 0 0 10px 0;
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 20px;
    ;
    font-family: 'Calibri-Bold';
    margin: 0 0 10px 0
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.catering_days_ul:after {
    content: "";
    display: table;
    clear: both;
}

.catering_days_ul {
    list-style: none;
    margin: 0 -2px;
    padding-left: 0;
    padding-bottom: 0;
}

.catering_days_ul li {
    padding: 0 2px;
    width: 20%;
    float: left;
    margin-bottom: 5px;
    height: 70px;
}

.catering_days_ul li dl {
    padding: 12px 5px;
    background: #fff;
    border: 2px solid #d9d9d9;
    border-radius: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
    height: 70px;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.catering_days_ul li dl.active {
    background: #809b66;
}

.catering_days_ul li dl.active dt {
    color: #fff;
}

.catering_days_ul li dl.active dd {
    color: #fff;
}

.catering_days_ul li dl:hover {
    background: #809b66;
}

.catering_days_ul li dl:hover dt {
    color: #fff;
}

.catering_days_ul li dl:hover dd {
    color: #fff;
}

.catering_days_ul li dl.cl-next-days:hover span {
    color: #000;
}

.catering_days_ul li dl.cl-next-days {
    background: #a1a1a1;
}

.catering_days_ul li dl.cl-next-days span {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    text-transform: uppercase;
    display: block
}

.catering_days_ul li dl dt {
    font-size: 14px;
    text-transform: uppercase;
    color: #9d9d9d;
    line-height: 14px;
    margin: 0 0 5px 0
}

.catering_days_ul li dl dd {
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #3c3a3a;
    line-height: 1;
}

.catering_days_ul .tag {
    color: #fff;
    background: #060606;
    padding: 5px 5px;
    position: absolute;
    left: -10px;
    top: 20px;
    transform: rotate(-18deg);
    -webkit-transform: rotate(-18deg);
    width: 120%;
    line-height: normal;
    text-align: center;
    height: 27px;
    font-size: 12px;
    text-transform: uppercase
}

.catering_days_ul li dl.time_inner dd {
    font-size: 18px;
}

.res-datetime-picker p {
    margin: 15px 0px 40px;
    font-style: italic;
    font-size: 12px;
    font-weight: bold;
}

.res-datetime-picker .tag span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    text-transform: uppercase;
}

.time_slot {
    margin-bottom: 10px;
    position: relative;
    padding: 30px 120px 30px;
    border: 2px solid #ecdfcc;
    background: #ffffff;
    text-align: center;
    border-radius: 6px;
}

.time_slot .order-btn {
    padding: 20px 0px 30px;
}

.time_slot .order-btn .no {
    background: #626161;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot .order-btn .yes {
    background: #72624f;
    height: 55px;
    font-size: 22px;
    padding: 10px 80px;
    border-radius: 4px;
}

.time_slot h4 {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    margin: 0px;
}

.time_slot .add_time .label {
    color: #3d3d3d;
    font-size: 18px;
    margin: 0px;
    padding: 0px 0px;
    width: 240px;
    float: left;
    text-align: right;
    line-height: 38px;
    height: 38px;
}

.add_time {
    margin: 10px 30px 45px 0px;
}

.time_slot .time {
    padding: 0px 0px 20px 0px;
}

.time_slot .time:after {
    content: "";
    display: table;
    clear: both;
}

.time_slot .time label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 17px;
    padding: 0px 0px 0px;
    text-transform: uppercase;
    line-height: 40px;
    margin: 0px;
}

.time_slot .time .form-control,
.order-select-brktime ul .time-cls .breaktime_listcls {
    height: 38px;
    padding: 6px 0px;
    border: 0
}

.order-select-brktime ul .custom-select-inner {
    padding: 0 10px 0 40px;
    background: #fff;
    width: 180px;
    height: 38px;
}

.order-select-brktime ul .time-cls .form-group:after {
    height: 38px;
    top: 0;
    right: 0
}

.order-break {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.order-break .label {
    font-size: 19px;
    font-family: 'Calibri-Bold';
    margin: 0 10px 0 0
}

.catering_hall_list {
    padding: 30px 0 10px
}

.catering_hall_list p {
    font-size: 17px;
    margin: 0px;
}

.catering_hall_list .form-group {
    margin: 25px 0 25px;
}

.function-rooms ul {
    margin: 0;
    padding: 20px 0 0 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.function-rooms ul li {
    width: 32%;
    margin: 0 2% 20px 0;
    text-align: center
}

.function-rooms ul li:nth-child(3n) {
    margin-right: 0
}

.view-rooms h4 {
    color: #000;
    font-size: 15px
}

.function-rooms ul li a:hover h4,
.function-rooms ul li.active h4 {
    color: #fff
}

.view-rooms span {
    color: #4e4e4e;
    display: block;
    clear: both;
    position: relative
}

.function-rooms ul li a:hover span,
.function-rooms ul li.active span {
    color: #fff
}

.function-rooms ul li a {
    height: 100%;
    padding: 5px 5px 15px;
    background: #e0e0e0;
    display: block
}

.function-rooms ul li a:hover,
.function-rooms ul li.active a {
    background: #d45d38
}

a.view-rooms img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0 0 15px 0;
}

.show {
    display: block;
}

.cedele-location label {
    font-weight: bold;
    color: #3d3d3d;
    font-size: 16px;
    line-height: 35px;
}

.location-list {
    height: 270px;
    margin: 0px -15px 0px 0px;
}

.location-list ul {
    list-style: none;
    margin: 0px 15px 0px 0px;
    padding: 0px;
}

.location-list ul li {
    font-weight: bold;
    color: #595656;
    font-size: 16px;
    background: #f8f8f8;
    border-radius: 7px;
    margin-bottom: 5px;
    height: 50px;
    padding: 0px 20px;
}

.location-list ul li:hover {
    color: #ffffff;
    background: #72624f;
}

.location-list .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #d45d38;
}

.no-padding {
    padding: 0px;
}

.continue a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px
}

.ownvenue-inner-div {
    max-width: 350px;
    margin: 40px auto 0;
}

.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.event-type-maindiv.two-button-row a {
    border-radius: 4px;
    font-size: 19px;
    padding: 22px 10px;
    background: #4f868f
}

.hall-select-popup {
    background: #000;
    padding: 60px 50px 50px;
    width: auto;
    margin: 20px auto;
    border: 1px solid #869f6e;
    -webkit-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, .23);
    text-align: center;
    color: #fff;
    max-width: 580px;
    position: relative;
}

.hall-select-popup .pop-title {
    font-size: 26px;
    text-transform: uppercase;
    line-height: 1;
    margin: 5px 0 10px;
    color: #fff
}

.hall-select-popup p {
    margin: 0;
    font-family: 'Calibri-Bold';
}

.hall-select-popup .button {
    width: 100%;
    display: block;
    margin: 20px 0 0 0;
    background: #5d8d87;
}

.hall-description {
    padding: 10px 0
}

.hall-select-popup .mfp-close-btn-in .mfp-close {
    color: #fff;
    opacity: 1
}

h3.event-datetime-string {
    font-size: 20px;
    font-family: 'Calibri-Bold';
    margin: 10px 0 0 0;
}

.ccategories_tabsec {
    padding: 26px 0 330px;
}

.number-break {
    padding: 28px 28px 28px 94px;
    margin: 0px 0px 40px;
    position: relative;
    border: 1px solid #46716c;
    background: #497670;
    color: #fff
}

.number-break .back_bx {
    position: absolute;
    left: 26px;
}

.back_bx {
    background: #f5f5f5;
    width: 68px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 54px 10px 12px;
    margin: 0;
    cursor: pointer;
    font-family: 'Calibri-Bold';
}

.back_bx:after {
    position: absolute;
    top: 8px;
    left: 0;
    font-family: FontAwesome;
    font-size: 40px;
    content: "\f104";
    width: 100%;
    line-height: 1;
}

.back_bx:hover {
    background: #000;
    color: #fff
}

.number-break h2 a {
    margin: 0;
    position: absolute;
    bottom: 5px;
    content: "";
    left: 0;
    right: 0;
    color: #d45d38
}

.break-time {
    width: 31%;
    padding: 5px;
    border-right: 1px solid #cfbfaa;
    text-align: center
}

.number-break {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.break-time-removed {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 19px
}

.break-time .title4,
.making-catering .title4 {
    margin-bottom: 10px
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    font-family: 'Calibri-Bold';
    font-size: 19px;
    color: #fff;
}

.making-place.making-place-padding {
    padding-left: 5px;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #fbe0b7
}

.change-location a:hover {
    color: #fff;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-family: 'Calibri-Bold';
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}

.break-time-removed .event-time .title4,
.break-time-removed .making-catering .title4,
.break-time-removed h3.event-datetime-string {
    color: #fff;
    font-size: 20px;
}

.break-time-removed .making-catering {
    width: 50%
}

.break-time-removed .event-time {
    width: 50%
}

.row-pgk-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.row-pgk-list > li {
    width: 25%;
    border: 4px solid #fff;
    position: relative;
    overflow: hidden
}

.pkselect_tabsec > .nav-tabs {
    border-bottom: 4px solid #4f868f;
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.pkselect_tabsec > .nav-tabs>li {
    margin: 0;
}

.pkselect_tabsec > .nav-tabs>li>a {
    background: #d2d0d0;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #060203;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 13px 38px;
    display: block
}

.pkselect_tabsec > .nav-tabs>li.active>a {
    border-radius: 5px 5px 0 0;
}

.pkselect_tabsec > .nav>li>a:focus {
    background: none;
}

.pkselect_tabsec > .nav-tabs>li.active>a,
.pkselect_tabsec .nav-tabs>li.active>a:focus,
.pkselect_tabsec .nav-tabs>li.active>a:hover {
    border: 0;
    background: #4f868f;
    color: #fff;
}

.pkselect_tabin {
    position: relative;
    padding: 20px 0 0;
}

.pkselected_filtersec {
    margin: 0 0 15px;
}

.pkselected_filtersec label {
    display: inline-block;
    font-size: 18px;
    color: #010101;
    padding-right: 16px;
    vertical-align: middle;
    margin: 0;
    font-family: 'Calibri-Bold';
    text-transform: uppercase
}

.pkselected_filtersec .form-group {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
}

.pkselected_filtersec .form-control {
    height: 48px;
    margin-bottom: 0
}

.pkselected_package label {
    position: relative;
    padding: 15.5px 26px 15.5px 0px;
}

.pkselected_package label i {
    font-size: 45px;
    position: absolute;
    left: 20px;
    top: 11px;
    line-height: 0.83;
}

.pkselected_package .form-group {
    min-width: 360px;
}

.pkselected_package .chosen-container-single .chosen-single {
    height: 56px;
    line-height: 56px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 0 0 25px;
}

.pkselected_package .chosen-container-single .chosen-single span {
    margin-right: 64px;
}

.pkselected_package .chosen-container-single .chosen-single div {
    width: 64px;
}

.pkselected_break .form-group {
    min-width: 245px;
}

.pkg_gimg,
.pkg_gitem {
    position: relative;
    height: 100%;
}

.pkg_gimg img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.pkg_ginfo {
    background: rgba(0, 0, 0, 0.35);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.2;
    font-size: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.pkg_ginfo.catbox-bottom-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
}

.row-pgk-list > li:hover .pkg_ginfo,
.pkg_gitem.category-active .pkg_ginfo {
    visibility: visible;
    opacity: 1;
}

.pkg_ginfo h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 9px;
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.row-pgk-list > li:hover .pkg_ginfo h4,
.pkg_gitem.category-active .pkg_ginfo h4 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkg_ginfo p {
    margin: 0;
}

.row-pgk-list > li:hover .pkg_ginfo p,
.pkg_gitem.category-active .pkg_ginfo p {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.pkselected_catgrytext {
    text-align: center
}

.pkselected_catgrytext .title4 {
    font-size: 21px
}

.pkgselect_dd {
    text-align: left;
    display: none;
}

.pkgselect_dd .chosen-container {
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single {
    background: #ecdfcc;
    border-color: #d0cfcb;
    border-radius: 2px;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 18px;
}

.pkgselect_dd .chosen-container-single .chosen-single span {
    font-weight: 300;
    color: #41403e;
}

.pkgselect_dd .chosen-container-single .chosen-drop {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #f1ece6;
    background: #f6f4f4;
}

.pkgselect_dd .chosen-container .chosen-results li {
    padding: 15px 18px;
    border-top: 1px solid #f1ece6;
}

.pkgselect_dd .chosen-container .chosen-results li:first-child {
    border-top: 0;
}

.pkgselect_dd .chosen-container .chosen-results .highlighted {
    color: #41403e;
}

.pkg_gbtsec .button {
    font-size: 17px;
    width: 100%;
    margin: 0;
    padding: 14px 5px;
    background: #809b66;
}

.pkg_gbtsec .button:hover {
    background: #d45d38
}

.pkg_gitem.highlighted .pkg_ginfo {
    background: rgba(11, 4, 5, 0.82);
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    display: none;
}

.pkg_gitem.highlighted .pkg_ginfo .pkgselect_dd {
    display: block;
}

.pkg_gitem.highlighted .pkg_ginfo .btn {
    opacity: 0;
    pointer-events: none;
}

.catbox-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.row-pgk-list > li:hover .catbox-bottom,
.pkg_gitem.category-active .catbox-bottom {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.row-pgk-list > li.category-active {
    border-color: #d45d38;
}

.row-pgk-list > li.category-active .pkg_gbtsec .button {
    background: #d45d38
}

.pkg-detail-top-row,
.goto-chkpage-cls,
.viewmenu-btn-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.pkg_gcol {
    width: 25%;
    border: 4px solid #fff;
}

.pkg_gridsec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pkg_gbtsec.text-center {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.pkg_gcol .choose-single-btn {
    background: #d45d38;
    display: block;
    padding: 11px 10px;
    color: #fff;
    text-transform: uppercase;
}

.pkg-intuil-list .pkg_ginfo {
    opacity: 1;
    visibility: visible;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    color: #fff
}

.package-overlay-header h3 {
    color: #fff;
    font-size: 22px;
    margin: 0 0 10px 0
}

.package-overlay-header span {
    font-family: 'Calibri-Bold';
}

.package-overlay-body {
    font-family: 'Calibri-Bold';
    font-size: 22px;
}

.package-overlay-body sup {
    font-size: 15px;
    text-transform: uppercase;
    top: -6px;
    position: relative;
}

.pkg_gcol .related-tags {
    z-index: 5;
    position: static
}

.pkg-intuil-list .pkg_ginfo p {
    -webkit-transform: none;
    transform: none;
    margin: 10px 0
}

.package-overlay-header {
    margin: 0 0
}

.upkg_tabsec {
    padding: 26px 0 8px;
}

.pkselect_tabin .bx_out {
    margin: 0 0 5px;
}

.bx_in {
    background: #fff;
    padding: 50px 70px 40px;
    border: 2px solid #d2d0d0;
    border-radius: 6px;
}

.pkselect_tabin .pkg_listsec {
    margin: 0 0 8px;
}

.pkg_listsec .bx_in {
    padding: 10px 0 10px 17px;
}

.pkglist_item {
    color: #989898;
}

.pkglist_itemtable {
    display: table;
    width: 100%;
}

.pkglist_itemtable > div {
    display: table-cell;
    vertical-align: top;
}

.pkglist_img {
    width: 33%;
    padding: 13px 0;
}

.pkglist_info {
    width: 50%;
    padding: 20px 22px 0;
}

.pkglist_infott h3 {
    margin: 0 0 7px;
    color: #010101;
    font-size: 18px;
    height: 22px;
    overflow: hidden;
}

.pkglist_infott p {
    font-size: 16px;
}

.pkglist_pricebx {
    width: 17%;
    border-left: 1px solid #e4e4e4;
    padding: 0 10px;
}

.pkglist_itemtable .pkglist_pricebx {
    vertical-align: middle;
}

.pkglist_pricebx > span {
    font-size: 18px;
}

.pkglist_pricebx > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}

.pkglist_pricebx > div > span {
    font-size: 18px;
}

.pkglist_pricebx > div > b {
    font-size: 22px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0 0 4px;
}

.package_checkbx .bx_in {}

.package_formtt {
    font-size: 18px;
    color: #020202;
    margin: 0 0 20px 0px;
}

.package_formtt1 {
    margin: 0 0 15px;
    position: relative;
}

.package_formtt span {
    display: block;
    margin: 0 0 5px;
    text-transform: uppercase;
}

.package_formtt small {
    display: block;
    color: #333;
    font-size: 13px;
    font-style: italic;
}

.package_checkrow {
    margin-bottom: 40px;
}

.package_checkitem {
    border: 2px solid #f5f5f5;
    padding: 32px 38px;
}

.package_checkitem .package_formtt {
    border-bottom: 1px solid #f5f5f5;
    padding: 0 0 10px;
}

.package_formchecklist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.package_formchecklist li {
    margin: 0 0 11px;
}

.package_formchecklist li.modfr_valuecls span {
	text-transform: capitalize;
}

.package_formcheck_inline {
    padding-left: 29px;
}

.package_formcheck_inline:after {
    content: "";
    display: block;
    clear: both;
}

.package_formcheck_inline li {
    width: 50%;
    float: left;
    padding: 1px 15px 0 0;
    margin: 0 0 1px;
    height: 40px;
    overflow: hidden;
}

.package_formbx .form-group.last {
    border: 0;
}

.package_formchecklist .custom_checkbox span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_addonbx .custom_checkbox span b {
    font-weight: 700 !important;
    height: 22px;
    overflow: hidden;
}

.package_formchecklist .custom_checkbox span:before {
    border-width: 2px;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: #b1b1b1;
}

.package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #869f6e;
    border-color: #77905d;
}

.package_formchecklist .custom_checkbox span b {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    display: block;
}

.pkgerror_out {
    position: relative;
    border: 2px solid #ff1313;
}

.pkgerror_out .pkgerror_txt {
    background: #ff1313;
    padding: 5px 10px;
    position: absolute;
    bottom: -27px;
    left: 0;
    right: 0;
    font-size: 13px;
    color: #fff;
    max-width: 220px;
    text-align: center;
    margin: 0 auto;
    line-height: normal;
    z-index: 11;
}

.pkgerror_out.up .pkgerror_txt {
    top: -30px;
    bottom: auto;
}

.pkgaddon_checklist .pkgerror_out.custom_checkbox {
    border-color: #ecdfcc;
}

.pkgaddon_checklist {
    margin: 0 -3.5px;
}

.pkgaddon_checklist li {
    width: 25%;
    float: left;
    margin: 0 0 7px;
    padding: 0 3.5px;
}

.pkgaddon_checklist .custom_checkbox {
    border: 3px solid #f5f5f5;
    display: block;
    border-radius: 6px;
}

.pkgaddon_checklist li.active .custom_checkbox {
    background: #809b66;
    border-color: #ecdfcc;
}

.package_addonbx .custom_checkbox input {
    height: calc(100% + 6px);
    height: -webkit-calc(100% + 6px);
    width: calc(100% + 6px);
    width: -webkit-calc(100% + 6px);
}

.pkgaddon_checklist .custom_checkbox span {
    padding: 18.5px 15px 18.5px 72px;
}

.pkgaddon_checklist .custom_checkbox span:before {
    border-color: #809b66;
    color: #809b66;
    font-size: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    left: 21px;
    border-radius: 50%;
    text-align: center;
    line-height: 27px;
    content: "\f067";
    font-family: FontAwesome;
    font-weight: 100;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span {
    color: #fff;
}

.pkgaddon_checklist .custom_checkbox input[type=checkbox]:checked + span:before {
    content: "\f00c";
    background: #334845;
    border-color: #bdbfbf;
    font-size: 17px;
}

.pkgsetup_listrw {
    padding: 0 0 12px;
    margin-bottom: 11px;
    border-bottom: 1px solid #dfdddd;
}

.pkgsetup_listrw:last-child {
    border: 0;
    margin: 0;
    padding: 0;
}

.package_controltt .custom_radio span {
    font-size: 18px;
    font-weight: 700;
    color: #363636;
    padding: 5px 0 0 43px;
}

.package_controltt .custom_radio span:before {
    width: 30px;
    height: 30px;
    border-width: 2px;
}

.package_controltt .custom_radio input[type=radio]:checked + span:before {
    border-color: #637550;
    border-width: 2px;
    background: #869f6e;
}

.pkgsetup_list .package_formchecklist {
    padding-left: 41px;
    margin: 14px 0 0;
}

.pkgsetup_list .package_formchecklist li {
    margin: 0 0 16px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span {
    padding: 0 0 0 30px;
    font-size: 16px;
    font-weight: 300;
}

.pkgsetup_list .package_formchecklist .custom_checkbox span:before {
    width: 23px;
    height: 23px;
}

.pkgsetup_list .package_formchecklist .custom_checkbox input[type=checkbox]:checked + span:before {
    line-height: 20px;
}

.package_form_helptext {
    font-style: italic;
    font-size: 16px;
}

.package_descbx textarea {
    height: 115px;
    padding-top: 14px;
}

.upkg_tabsec .chk-payment-btn {
    margin: 10px 0 0;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product_modifiers_listinner .modifiers_main_list {
    width: 48%;
    margin: 0 0 20px 0
}

.package_checkitem {
    border-radius: 8px
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a {
    width: 49%
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:first-of-type,
#PackageEditMdl .chk-payment-btn .col-md-cls:first-of-type a,
.goto-chkpage-cls a:first-of-type,
.viewmenu-btn-row a:first-child {
    background: #5d8d87
}

.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a:hover,
#PackageEditMdl .chk-payment-btn .chk-payment-btn-row a:hover,
.goto-chkpage-cls a:first-of-type:hover,
.viewmenu-btn-row a:first-child:hover {
    background: #869f6e
}

.pkg_total_box .pkg_total_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    font-family: 'Calibri-Bold';
    font-size: 20px
}

.pkg_total_box .pkg_total_row.grand_total {
    font-size: 28px;
}

.pkg_total_box .cc-addon-subtotal-lhs {
    width: 140px;
}

.pkg_total_box .cc-addon-subtotal-rhs {
    width: 180px;
}

.pkselected_break .form-group .custom-select-inner select {
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.pkselected_break .form-group .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner {
    background: #fff;
    padding-left: 40px;
}

.checkout_time .custom-select-inner select {
    padding: 6px 0;
    border: 0;
    height: 46px;
    position: relative;
    top: 1px;
    right: 1px;
}

.goto-chkpage-cls a,
.viewmenu-btn-row a {
    width: 49%;
    margin: 0
}

.goto-chkpage-cls,
.viewmenu-btn-row {
    margin: 10px 0 0 0;
}

.addon_popup {
    max-width: 846px;
    border: 1px solid #5d8d87;
}

.addon_popup .pouup_in {
    padding: 45px 9px 30px;
}

.addon_poptop {
    padding: 0 49px;
    margin: 0 0 30px;
}

.addon_listrw {
    width: 100%;
    margin: 0 0 15px;
    padding: 0 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addon_linfo {
    width: 55%;
    color: #020202;
}

.addon_linfo h4 {
    font-size: 15.5px;
    color: #060606;
    margin: 0 0 5px;
}

.addon_linfo p {
    font-size: 15.5px;
    line-height: 1.2;
    margin: 0;
}

.addon_lright {
    width: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.cross_multi {
    font-size: 16px;
    color: #fff;
    margin: 0 9px 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 10px 3px;
    width: 42px;
    z-index: 1;
    text-align: center;
}

.cross_multi:before {
    content: "";
    background: #5d8d87;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 3px;
    -webkit-transform: skewX(-10deg);
    transform: skewX(-10deg);
    -webkit-transform: -webkit-skewX(-10deg);
    transform: -webkit-skewX(-10deg);
}

.addon_price {
    font-size: 19px;
    color: #000000;
    margin: 0 20px 0 0;
    display: inline-block;
    vertical-align: middle;
    font-family: 'Calibri-Bold';
}

.addon_lright .btn {
    border-radius: 4px;
    font-size: 17px;
    margin-bottom: 0;
    vertical-align: middle;
}

.addon_lright .qty_bx {
    width: 109px;
    height: 33px;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.addon_lright .qty_bx span {
    background: #fff;
    width: 35px;
    height: 33px;
    line-height: 31px;
    letter-spacing: -1px;
    font-size: 20px;
}

.addon_lright .qty_bx input {
    font-size: 16px;
}

.addon_lright .btn_grey {
    background: #4c4c4c;
    color: #fff;
}

.addon_lright .btn_grey:hover {
    background: #1c1c1c;
}

.addon_popbot .btn {
    min-width: 140px;
    font-size: 17px;
    margin: 0;
}

.addon_popbot .btn_grey {
    background: #5a5a5a;
    color: #fff;
}

.addon_popbot .btn_grey:hover {
    background: #1c1c1c;
}

.addon_lright .button {
    margin: 0;
    font-size: 15px;
    padding: 14px 20px 13px;
}

.addon_poptop_header {
    border-bottom: 2px solid #efefef;
    margin: 0 0 40px 0;
}

.addon_poptop_header h3 {
    margin: 0 0 15px 0;
    font-size: 24px;
}

.addon_poptop_header h3 small {
    display: block;
    color: #333;
    font-size: 12px;
    font-style: italic;
    padding-top: 10px;
}

.addon_popbot .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.addon_popbot .row-replace .button {
    margin: 0 0 0 10px
}

.addon_popbot {
    border-top: 1px solid #e4e4e4;
    padding: 30px 0 0 0;
    margin: 0 49px;
}

.addon_popbot .cal-md-cls:first-of-type .common-mfd-closefun {
    background: #5d8d87
}

.addon_popbot .row-replace .button:hover {
    background: #869f6e
}

.catering_checout .checkout-total {
    width: 100%;
}

.catering_checout .checkout-total .fc-half {
    width: 45%;
}

.catering_checout .checkout-total .controls-single input[type="text"] {
    background: #ffffff;
}

.catering_checout .checkout-control-group-bottom {
    margin: 30px 0 0 0
}

.catering_checout .checkout-control-group-middle .col-md-cls {
    padding: 0
}

.catering_checout .checkout-control-group-middle .form-group .form-control {
    margin: 0
}

.order_details .panel-group .panel+.panel {
    margin-top: 0px;
}

.order_details .panel-group {
    padding: 30px 0px 0px;
    margin-bottom: 0px;
}

.order_details .panel-default {
    border-color: transparent;
}

.order_details .panel {
    background-color: #f6f6f6;
    border-radius: 0px
}

.order_details .panel-default>.panel-heading {
    color: #fff;
    background-color: #d45d38;
    border-color: #d45d38;
    padding: 12px 15px 12px 15px;
    border-radius: 0px;
}

.order_details .panel-title>a {
    display: block;
    font-size: 18px;
    text-transform: uppercase;
}

.order_details .panel-title>a .clck {
    text-align: right;
    font-weight: 300;
    font-style: italic;
    float: right;
    font-size: 14px;
    margin-right: 30px;
    margin-top: 2px;
}

.order_details .panel-title>a:hover {
    color: #fff;
}

.order_details .panel-title {
    position: relative;
}

.order_details .panel-title:after {
    content: '';
    position: absolute;
    right: -9px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(90deg) translate(10px, 10px);
    transform: rotate(90deg) translate(10px, 10px);
}

.order_details .panel-title:before {
    content: '';
    position: absolute;
    right: 7px;
    background: #fff;
    height: 2px;
    top: 0;
    width: 18px;
    -webkit-transform: rotate(0deg) translate(6px, 10px);
    transform: rotate(0deg) translate(6px, 10px);
}

.order_details .act .panel-title:after {
    opacity: 0;
}

.order_details .panel-collapse {
    height: 670px;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-family: 'Calibri-Bold';
}

.order_details .edit {
    background: #809b66;
    border-radius: 3px;
    margin-right: 5px;
    display: inline-block;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    position: absolute;
}

.order_details .edit .fa-pencil-square-o {
    font-size: 18px;
    color: #fff
}

.package {
    padding: 0px 0px 5px 0px;
}

.package .title {
    font-family: 'Calibri-Bold';
    color: #000000;
    font-size: 20px;
    padding-left: 30px;
}

.package a {
    line-height: 1.6;
}

.package .catqty_bx .qty_minus {
    background: #fff;
}

.package .catqty_bx .qty_plus {
    background: #fff;
}

.package .package_cnt {
    padding: 0px 0px 25px;
}

.package .package-list {
    padding: 0px 0px 15px 40px;
    color: #000;
}

.package .package-list .one {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
}

.package .package-list .two {
    font-weight: 300;
    font-size: 16px;
    margin: 0px;
}

.addon {
    padding: 20px 0px 15px 40px;
}

.addon h4 {
    margin: 0px 0px 10px 0px;
    color: #000;
    font-size: 18px;
    font-family: 'Calibri-Bold';
}

.addon_list h5 {
    margin: 10px 0px 10px 0px;
    line-height: 1.6;
}

.addon_list p {
    margin: 0px 0px 5px 40px;
    font-size: 16px;
    color: #000;
}

.addon_list h5 .title {
    color: #000;
    font-size: 17px;
    padding-left: 35px;
    display: block;
    font-family: 'Calibri-Bold';
}

.amt {
    text-align: right;
    margin-top: 10px;
}

.amt .remove {
    background: #333;
    border-radius: 3px;
    margin-left: 10px;
    width: 21px;
    height: 21px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    color: #fff
}

.amt span {
    font-family: 'Calibri-Bold';
    color: #000;
}

.buffet_setup {
    padding: 20px 0px 30px;
}

.buffet_setup h5 {
    margin: 10px 0px 5px 0px;
    line-height: 1.6;
}

.buffet_setup h5 .title {
    color: #000000;
    font-size: 20px;
    padding-left: 35px;
    font-family: 'Calibri-Bold';
}

.buffet_setup p {
    margin: 0px 0px 7px 40px;
    font-size: 16px;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    font-family: 'Calibri-Bold';
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    font-family: 'Calibri-Bold';
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
    margin-left: 40px;
}


.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}

.popwel_formsec {}

.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.choose_location.pkgerror_out .pkgerror_txt {
    max-width: 290px;
}

.sub_tab_mobtrigger {
    display: none;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search {
    margin-left: 15px !important;
}

.htico_search > a {
    position: relative;
    display: block;
}

.header_bot {
    display: inline-block;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    font-family: 'Calibri';
    margin-bottom: 0px;
}

.logo {
    max-width: 25%;
    z-index: 99;
    width: 100%;
}

.slick-slide {
    position: relative;
}

.home-ordernow-main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.featured-products-section {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.featured-products-main h2 {
    font-family: 'subway_novellaregular';
    font-size: 50px;
    color: #000;
    margin-bottom: 0px;
}

.featured-products-main p {
    font-family: 'subway_novellaregular';
    font-size: 21px;
    color: #000;
    margin-bottom: 20px;
    font-style: italic;
}

.price-tag h6 {
    font-family: 'Calibri';
    font-size: 17px;
    color: #fff;
    background-color: #4f9a97;
    width: 150px;
    height: 35px;
    line-height: 35px;
    text-transform: uppercase;
    float: left;
}

.featured-products-single img {
    position: relative;
}

.price-tag {
    position: absolute;
    top: 0px;
}

.related-tags ul,
.product-tags-list ul {
    list-style: none;
    padding: 0 0 0 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.related-tags ul li,
.product-tags-list ul li {
    font-family: 'Calibri-Bold';
    font-size: 13px;
    padding: 3px 10px;
    background-color: #000;
    border: 1px solid #fff;
    text-transform: uppercase;
    border-radius: 30px;
    color: #fff;
}

.product-tags-list ul li {
    margin-right: 5px;
}

.related-tags {
    float: left;
    position: absolute;
    bottom: 15px;
}

.product-tags-list {
    float: left;
    position: absolute;
    bottom: 15px;
}

.review-tags {
    float: right;
    position: absolute;
    bottom: 4px;
    right: 0px;
}

.related-tags ul li,
.product-tags-list ul li {
    display: inline-block;
    margin-top: 5px;
    margin-right: 5px
}

.related-review-tag {
    position: relative;
}

.product-title h3,
.product-price h3 {
    font-size: 20px;
    color: #0a0b10;
    margin: 0 0 10px 0;
}

.product-title h3 {
    text-align: left;
}

.product-title {
    float: left;
    width: 70%;
}

.product-price {
    float: right;
}

.product-short-description {
    clear: both;
}

.product-short-description p {
    font-size: 15px;
    color: #949292;
    text-align: left;
    padding-top: 4px;
    margin-bottom: 0px;
    font-family: 'Calibri';
}

.featured-products-single {
    max-width: 350px;
    position: relative;
}

.product-title-discription {
    padding: 20px;
    background: #fff;
}

.product-title-price {
    position: relative;
    overflow: hidden;
}

.product-title-price::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    border: 1px dashed #dbdddd;
    width: 100%;
}

.featured-products-section {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 40px;
}

.serving-passionately-main {
    position: relative;
    overflow: hidden;
    padding: 30px;
    border-radius: 20px;
    background: rgba(210, 190, 155, 0.38);
    border: 2px solid #f0f2ec
}

.serving-passionately-left-img {
    width: 50%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.serving-passionately-left,
.serving-passionately-right {
    width: 50%;
}

.serving-passionately-left img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.serving-passionately-right {
    text-align: center;
    padding: 40px
}

.serving-passionately-right p {
    font-family: 'Calibri';
    font-size: 18px;
    color: #121212;
    line-height: 25px;
}

.serving-passionately-right a,
.product-view-more a {
    font-size: 19px;
    margin: 5px auto 0;
}

footer {
    padding: 5px 0 0 0;
    position: relative;
    margin: 40px 0 0 0;
}

.footer-bottom {
    padding-top: 30px;
    padding-bottom: 20px;
    background: #d45d38;
}

.footer-bottom .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-bottom-left {
    width: 20%;
    display: inline-block;
}

.footer-bottom-right {
    width: 15%;
    display: inline-block;
}

.footer-bottom-middle {
    width: 58%;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer-top {
    text-align: center;
    padding: 0 0 20px 0;
}

.footer-contact a,
.footer-bottom-right h3 {
    font-family: 'Calibri';
    font-size: 15px;
    text-decoration: none;
    color: #fff;
}

.footer-menu h3 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.footer-menu ul,
.footer-bottom-right ul {
    padding-left: 0px;
    list-style: none;
}

.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.footer-bottom-right ul li {
    display: inline-block;
}

.footer-bottom-right ul li a {
    color: #fff;
    font-size: 22px;
}

.footer-menu ul li a:hover {
    color: #000
}

.copyright-section p {
    font-size: 14px;
    color: #17191d;
    text-align: center;
    margin-bottom: 0px;
}

.home-banner {
    position: relative;
    z-index: 2;
}

.home-banner:after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid rgba(203, 77, 54, 0.37);
}

.home-banner .slick-slider .slick-prev {
    background: url(../images/previous-icon.png) 0 0px no-repeat !important;
    left: 10px;
}

.home-banner .slick-slider .slick-next {
    right: 10px;
    background: url(../images/next-icon.png) 0px 0px no-repeat !important;
}

.featured-products-main .slick-slider .slick-prev {
    left: -80px !important;
    background: url(../images/black-previous.png) 0 0px no-repeat !important;
}

.featured-products-main .slick-slider .slick-next {
    right: -80px !important;
    background: url(../images/black-next.png) 0px 0px no-repeat !important;
}

.home-banner .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px;
    height: 30px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin-bottom: 0
}

.featured-products-main .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    outline: 0;
    width: 38px !important;
    height: 30px !important;
    text-indent: -9999px;
    z-index: 9;
    border: none;
}

.bx-wrapper {
    border: none !important;
    margin-bottom: 0px !important;
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.bx-wrapper .bx-pager {
    display: none !important;
}

.banner-info h2 {
    font-family: 'subway_novellaregular';
    font-size: 70px;
    color: #fff;
    margin-bottom: 0px;
}

.banner-info {
    text-align: center;
    position: absolute;
    top: 38%;
    left: 0px;
    right: 0px;
}

.banner-info p {
    font-family: 'raphtalia_demoregular';
    color: #fff;
    line-height: 1;
    font-size: 30px;
}

.bxslider {
    position: relative;
}

.featured-products-main .bx-wrapper {
    margin-left: auto;
    margin-right: auto;
}

.product-view-more {
    margin-top: 40px;
}

.footer-bottom-right ul,
.footer-bottom-right h3 {
    display: inline-block;
}

.footer-bottom-right {
    text-align: right;
}

.footer-bottom-right ul li {
    padding-left: 3px;
    vertical-align: middle;
}

.featured-products-full {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
}

.slick-slide img {
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}

.slick-track .slick-slide > div {
    height: 100%;
}

.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

.featured-products-ordernow > .button {
    margin: 0 auto;
}

.featured-products-image {
    position: relative;
    overflow: hidden
}

.featured-products-ordernow {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    visibility: hidden;
    z-index: 2;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s
}

.featured-products-single:hover .featured-products-ordernow {
    visibility: visible;
    opacity: 1
}

.featured-products-single .featured-products-image:after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    content: "";
    z-index: 1;
    width: 0%;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.featured-products-single:hover .featured-products-image:after {
    width: 100%;
}

.featured-products-single:hover .product-title-discription {
    background: #17191d
}

.slick-track .slick-slide .featured-products-single:hover {
    background: #17191d
}

.featured-products-single:hover .product-title h3,
.featured-products-single:hover .product-price h3 {
    color: #fff
}

.featured-products-single:hover .product-title-price::after {
    border-color: rgba(255, 255, 255, 0.3)
}

.bakery_slide {
    background: #d6ccc0;
    text-align: center;
    border: none;
    border-left: 0;
}

.bakery_slide p a {
    padding: 11px 5px;
    display: block;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
}

.bakery_row .slick-next {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    right: 0px;
    top: 0;
    width: 48px;
    height: 44px;
    border-left: none;
    cursor: pointer;
    margin: 0
}

.bakery_row .slick-prev {
    font-size: 0;
    border: 0;
    outline: 0;
    position: absolute;
    background: #464646;
    left: 0;
    top: 0;
    width: 48px;
    height: 44px;
}

.bakery_row .slick-prev::after {
    content: "";
    background: url(../images/previous-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 2px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 11px;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row .slick-next::after {
    content: "";
    background: url(../images/next-icon.png) no-repeat;
    width: 38px;
    height: 30px;
    background-position: 4px 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-size: 75%;
}

.bakery_row {
    margin-top: 55px;
    height: 45px;
    position: relative
}

.innerproduct-col {
    width: 19.5%;
    margin-right: 0.6%;
    margin-bottom: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.addcart_row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
    max-width: 320px;
    margin: 0 auto;
}

.addcart_row .qty_bx {
    width: 56%;
    vertical-align: middle;
    float: left;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    width: 42%;
    padding-left: 8px;
    padding-right: 8px;
    float: left;
    font-size: 19px;
    color: #fff;
    height: 38px;
    line-height: 38px;
    padding: 0px;
    border: none;
    cursor: pointer;
}

.addcart_row .btn:hover,
.addcart_row .btn:focus {
    background: #000;
}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,
.innerproduct-col.open::after {
    opacity: 1;
}

.innerproduct-item-text h4 {
    font-size: 20px;
    color: #050505;
    padding: 0px;
    margin-bottom: 15px;
}

.bakery-review .fa-star {
    color: #000;
}

.bakery-review .wppc-checked {
    color: #ed8a19;
}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.menu-section_full {
    overflow: hidden;
}

.singup-fb-icon i {
    font-size: 22px;
    vertical-align: middle;
    padding-right: 7px !important;
}

.signup-popup .popup-footer .btn {
    font-family: 'Calibri-Bold';
    font-size: 14px;
    max-width: 225px;
    height: 45px;
    line-height: 45px;
    padding: 0px;
    border-radius: 0px;
}
.inside-popup-rhs .signup-popup-head .guest_btn{    
display: block;
max-width: 271px;
margin: 0 auto;
border-radius: 7px;
padding: 16px 22px;
height: 51px;
    font-size: 18px;
font-family: 'Calibri';
line-height: 1;    
}

.signup-popup .popup-body h4 {
    font-family: 'Calibri-Bold';
    font-size: 14px;
}

.signup-popup .focus-out label,
.signup-popup .popup-body input {
    font-size: 14px;
    color: #595656;
}

.signup-popup .popup-body .sign_reg p {
    font-size: 12px;
    color: #0e0e0e;
    line-height: 20px;
}

.signup-popup .popup-body .custom_checkbox span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #B1B1B1;
    margin-top: 5px;
}

.signup-popup .popup-body .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #809b66;
    border: 2px solid #809b66;
}

.signup-popup .popup-body .sign_reg {
    margin-left: 35px;
}

.signup-popup .popup-body .custom_checkbox {
    margin-top: 10px;
}

.signup-popup .popup-body .custom-pdpa-div,
.signup-popup .popup-body .custom-pdpa-div .custom_checkbox {
    margin-bottom: 0
}

.signup-popup .popup-body .custom-pdpa-div .sign_reg p {
    margin-top: 5px
}

.signup-popup .form-group .btn_minwid {
    font-size: 16px;
    text-transform: uppercase;
}

.signup-popup .pop-whole-lhs h3 {
    font-size: 45px;
    margin-bottom: 0px;
}

.signup-popup .pop-whole-lhs p {
    font-size: 13px;
}

.checkout-heading {
    background: #000;
    color: #fff;
    font-size: 22px;
    padding: 15px 10px;
    margin: 0 -30px;
    position: relative;
}

.productlist-main-div {
    min-height: 170px;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    margin: auto;
    max-width: 1260px;
}

.common-top-div {
    padding-top: 97px !important
}

.logo a {
    position: absolute;
    top: -40px;
}

.header_middle {
    background-color: #d45d38;
    position: relative;
    margin: 0 auto
}

.header_middle:before {
    content: '';
    position: absolute;
    border-top: #fff solid 2px;
    border-bottom: #fff solid 2px;
    top: 4px;
    left: 0px;
    right: 0px;
    bottom: 5px;
}

.header-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    height: 52px;
}

ul.hmenu_list li a.active {
    color: #fff;
}

ul.hmenu_list > li > a {
    font-size: 19px;
    color: #e8dcce;
    text-transform: uppercase;
    padding: 0px 20px 14px;
    display: block;
}

.hsign_sec > span {
    font-size: 19px;
    vertical-align: text-bottom;
    position: relative;
    top: -1px;
    color: #e8dcce;
    margin: 0 5px;
}

.hsign_sec a {
    font-size: 19px;
    color: #e8dcce;
    text-transform: uppercase;
}

.hsign_sec a:hover {
    color: #fff
}

.container {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0;
    width: auto;
}

.haction_sec {
    max-width: 40%;
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: relative;
    padding: 0;
    max-width: 35%;
}

.header_in {
    padding-top: 45px;
    position: relative;
}

header.small_sticky:before {
    height: 100%;
}

.hcart_round {
    background: #000;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 100%;
    position: absolute;
    right: -7px;
    top: 0px;
    text-align: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home-ordernow-single h3 {
    font-family: 'subway_novellaregular';
    font-size: 30px;
    color: #fff;
    margin-bottom: 0px;
}

.home-ordernow-single {
    text-align: center;
    position: relative;
    width: 25%;
    cursor: pointer;
}

img.blue-round {
    display: block;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

img.green-round {
    display: none;
    -webkit-transition: 0.5s linear all;
    transition: 0.5s linear all;
}

.home-ordernow-single:hover img.green-round,
.home-ordernow-single.active img.green-round,
.order_delivery_item li a:hover img.green-round,
.ordericon_link.active img.green-round {
    display: block;
    -webkit-transition-delay: 5s;
    transition-delay: 5s;
    -webkit-transform: rotate(380deg);
    transform: rotate(380deg);
}

.home-ordernow-single:hover img.blue-round,
.home-ordernow-single.active img.blue-round,
.order_delivery_item li a:hover img.blue-round,
.ordericon_link.active img.blue-round {
    display: none;
}

.home-ordernow-single-img .button {
    margin: 5px 0 0 0;
    font-size: 16px;
    padding: 12px 24px 12px;
}

.home-ordernow-single-img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.home-ordernow-single-img img {
    max-height: 83px;
}

.home-ordernow-section {
    padding-bottom: 35px!important;
    background-color: rgba(210, 190, 155, 0.38);
    position: relative;
    border-radius: 0px 0px 20px 20px;
    z-index: 5;
    padding-top: 60px;
    margin-bottom: 40px;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
}

.home-badge-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: -131px;
}

.home-ordernow-main {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 60px;
}

.serving-passionately-right h3 {
    font-family: 'subway_novellaregular';
    font-size: 52px;
    color: #000;
    line-height: 1;
    margin: 0 0 10px 0;
}

.featured-products-full .slick-dots {
    display: none !important
}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #d45d38;
    border-color: #d45d38;
}

.copyright-section {
    background: #d2be9b;
    padding: 15px 10px
}

.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #d45d38;
}

.bakery_row .slick-slide {
    background-color: #000;
    display: block !important;
}

.bakery_row .slick-slide .bakery_slide.active {
    background-color: #809b66;
}

.bakery_row .slick-slide .bakery_slide.active a {
    color: #fff
}

.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.prodetailinner-main-div {
    min-height: 105px;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #cb4d36;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    height: 48px;
    pointer-events: none;
}

.custom-select-bxcls:after {
    height: 48px;
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    font-size: 17px;
    padding-left: 10px;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.mdf_common_rows {
    position: relative;
}
.compo_pro_acc {
    background: #F9F9F9;
}
.prd_chosen_row.compo_top_div {
    background: #FFF;
}
.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}

.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}

.compopro_acc_head {
    padding: 12px 20px 12px 20px;
    cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
    background: #073a0c;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.text-uppercase {
    text-transform: uppercase
}

.hcart_dropdown .qty_bx {
    width: 120px;
    float: left;
}

.cart_footer p,
.cart_footer span {
    margin: 0;
    font-size: 19px;
    color: #fff;
    font-family: 'Calibri-Bold';
}

.cart_footer {
    padding: 20px 40px 20px 20px;
    background: #1c1c1c;
    margin-left: 20px;
    margin-right: 20px;
}

.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
    font-size: 32px;
}

.cart_row.grant-total-cls sup {
    font-size: 60%;
    position: relative;
    top: -6px;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 5px
}

.cart-header-first h4 {
    color: #1c1b1b;
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-first p {
    color: #000000;
    font-size: 16px;
    margin: 0
}

.cart-header-second h5 {
    font-family: 'Calibri-Bold';
    color: #1c1b1b;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    font-family: 'Calibri-Bold';
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 5px;
    right: -15px;
}

.cart_footer .cart_remove:hover {
    background-color: #d45d38;
}

.product-details {
    background: #f5f5f5;
    padding: 15px 40px 15px 15px
}

.cart_body {
    padding: 20px 20px;
    padding-bottom: 0px;
}

.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #809b66
}

.cartaction_bottom .btn:hover {
    background: #5d8d87
}

.product-details:hover {
    background: #e6e6e6;
}

.cart-header-first .row-replace,
.cart-header-second .row-replace,
.hcart_tt .row-replace,
.product-details .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.cart-header-first .row-replace .col-sm-cls,
.cart-header-second .row-replace .col-sm-cls {
    width: 50%
}

.cart_left {
    padding: 0 5px 0 0
}

.cart_right {
    padding: 0 0 0 5px
}

.cart_body .hcart_tt .row-replace {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.cart_body .hcart_tt {
    padding: 10px 10px 10px 15px;
}

.cart_body .hcart_tt h3 {
    font-family: 'Calibri-Bold';
    text-transform: uppercase
}

.cart_body .hclear_cart {
    font-family: 'Calibri-Bold';
}

.cart_body .hclear_cart:hover {
    color: #809b66
}

.product-details .row-replace .cart_right {
    width: 220px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.product-details .cart_info h4 {
    font-family: 'Calibri-Bold';
}

.product-details .row-replace .cart_left {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px);
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
    font-size: 18px
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #d35d38;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart_row.cart-header-second {
    margin: 15px 0 0 0;
}

.cart_row.cart-header-second h3 {
    font-family: 'Calibri-Bold';
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.cart-direction:after {
    background-image: -webkit-gradient( linear, left top, right top, color-stop(50%, #949292), color-stop(0%, transparent));
    background-image: linear-gradient( to right, #949292 50%, transparent 0%);
    background-size: 8px 4px;
    background-repeat: repeat-x;
    background-position: 0% bottom;
    -webkit-animation-name: border-dance;
    animation-name: border-dance;
    -webkit-animation-duration: 25s;
    animation-duration: 25s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    content: "";
    position: absolute;
    left: 50px;
    display: block;
    height: 2px;
    right: 30px;
    margin: auto;
}

@-webkit-keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

@keyframes border-dance {
    from {
        background-position: 0% bottom;
    }
    to {
        background-position: 100% bottom;
    }
}

.innersection_wrap {
    padding: 60px 0 0 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 20px 20px 20px;
    font-size: 22px;
}

.tnk-chk-order .button:hover {
    background: #5d8d87
}

.thank-order-detaildiv {
    max-width: 780px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;
}

.mainacc_toptext h2 {
    margin: 20px 0 5px 0
}

.mainacc_toptext p {
    font-size: 22px;
    margin: 0;
    font-family: 'subway_novellaregular';
    font-style: italic
}

#CartListMdl .total_amt {
    overflow: hidden;
    padding: 25px;
}

#CartListMdl .total_amt .subtotal {
    font-size: 17px
}

#CartListMdl .order_details .panel-group {
    margin: 0;
    padding: 0
}

#CartListMdl .package .title {
    padding-left: 0px
}

#CartListMdl .package .package_cnt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px 0px 5px;
    border-bottom: 1px solid #cecece;
    margin: 0 0 15px 0;
}

#CartListMdl .package .package_cnt .col-sm-cls {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls {
    font-size: 19px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#CartListMdl .addon {
    padding: 10px 0px 0px 10px;
}

#CartListMdl .package .package-list {
    padding: 0px 0px 5px 10px;
    color: #000;
}

#CartListMdl .buffet_setup {
    padding: 5px 0px 5px;
}

#CartListMdl .buffet_setup .row-replace,
#CartListMdl .addon .row-replace.addon_list,
#CartListMdl .total_amt .subtotal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#CartListMdl .buffet_setup h5 .title {
    padding-left: 10px
}

#CartListMdl .buffet_setup p {
    margin: 0px 0px 7px 25px;
}

#CartListMdl .brline {
    display: none
}

#CartListMdl .addon_list p {
    margin: 0px 0px 5px 20px;
}

#CartListMdl .addon_list h5 .title {
    padding-left: 20px;
}

#CartListMdl .order_details .panel-collapse {
    height: 600px;
}

#CartListMdl .Cart-list-mdlheader h2,
#PackageEditMdl .Cart-list-mdlheader h2 {
    text-align: center;
    font-size: 32px;
    margin: 5px 0 20px 0;
}

#CartListMdl button.close,
#PackageEditMdl button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
}

#CartListMdl button.close:before,
#CartListMdl button.close:after,
#PackageEditMdl button.close:before,
#PackageEditMdl button.close:after {
    position: absolute;
    left: 9px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #000;
    top: 0px;
}

#CartListMdl button.close:before,
#PackageEditMdl button.close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#CartListMdl button.close:after,
#PackageEditMdl button.close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#PackageEditMdl .bx_in {
    padding: 20px 20px;
}

#PackageEditMdl .package_checkitem {
    padding: 20px
}

#PackageEditMdl .chk-payment-btn {
    margin-bottom: 0
}

#PackageEditMdl .pkselected_filtersec .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

#PackageEditMdl .pkselect_tabin {
    padding: 0;
}

#PackageEditMdl .pkselected_package label {
    position: relative;
    padding: 5px 0 0 0px;
}

.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 0 0 120px 0;
    position: relative
}

.myacc_head_sec {
    background: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #fff;
    margin: 0;
    font-size: 21px
}

.head_right h4 {
    color: #fff;
    margin: 0;
    font-family: 'Calibri-Bold';
    text-transform: uppercase;
    font-size: 20px
}

.head_right::before {
    content: "";
    display: block;
    background: #ea2876;
    width: 35%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.order_btns,
.order_amt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.order_btns .button {
    margin: 0;
    width: 50%;
    display: block;
    padding: 18px 10px 16px;
    height: 52px;
    color: #fff;
}

.order_btns .print_invoice {}

.order_btns .view_recipt {
    background: #ea2876;
}
.order_btns .view_recipt.button_full_view{width: 100%}
.order_btns .button:hover {
    background: #d45d38
}

.order_amt {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #000;
    padding: 16px 15px
}

.order_amt h3 {
    margin: 0;
    color: #fff;
    font-size: 24px
}
.order_amt-right sup {
    font-size: 75%;
    position: relative;
    top: -5px;
    margin: 0 2px 0 0;
}
.order_details_body {
    padding: 15px
}
.order_no_deatails {
    margin: 5px 0 20px 0;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}
.order_no_deatails span {
    display: block;
}

.delivery_total_left h3 {
    font-family: 'Calibri-Bold';
}

.delivery_total_left img {
    margin: 0 0 10px 0
}

.delivery_total {
    position: relative;
}
.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 50px 0
}

.web_order_typecls li {
    width: 32.5%
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 17px 10px;
    background: #fff;
    color: #000
}

.web_order_typecls li.active a,
.web_order_typecls li a:hover {
    background: #d45d38
}

.web_order_typecls li a h5 {
    margin: 0;
    color: #000;
    text-transform: uppercase
}

.sprite_lst_icon {
    margin: 0 10px 0 0
}

.sprite_lst_icon.order_t {
    max-width: 33px;
}

.sprite_lst_icon.catering_icon_t {
    width: 22px;
}

.sprite_lst_icon.reserve_icon_t {
    width: 22px;
}

.myacc_order_details .reserv-orderlist {
    padding: 0;
    border-bottom: 2px solid #5d8d87
}

.myacc_order_details .reserv-orderlist .order_no_deatails {
    margin: 15px 0px 0;
    border-top: 1px solid #e4e4e4;
    padding: 20px 0 0 0;
}

.myacc_order_details .reserv-orderlist .order_no_deatails h3 {
    margin: 0;
    color: #000;
    font-size: 19px;
    text-transform: uppercase;
}

.myacc_order_details .reserv-orderlist .order_no_deatails p {
    margin: 0;
    color: #809b66;
    font-size: 21px;
    font-family: 'Calibri-Bold';
}

.reserv-loader-div {
    display: inline-block
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 19px;
}

.reserv-orderlist .delivery_total.delivery_total_number .delivery_total_left h2 {
    margin-bottom: 5px
}

.reserv-orderlist .delivery_total.delivery_total_number {
    margin: 0;
    padding: 0
}

.web_order_typecls li:hover .count_num_t,
.web_order_typecls li.active .count_num_t {
    background: #ffba00;
}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}

.mainacc_menulist #masterCount {
    background: #000;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 12px;
    line-height: 21px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px
}

.reward-list-body .delivery_total.delivery_total_number {
    padding: 0;
    margin: 0
}

.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
    float: none;
    margin: 0 15px 0 0;
}

.reward-list-body .delivery_total.delivery_total_number .delivery_total_left h4 {
    font-size: 17px
}

.reward-list-body .order_amt_div h3 {
    margin: 0;
    font-size: 22px;
    padding: 10px 0 0 0;
}

.reward-list-body .order_amt_div h3 sup {
    font-size: 75%
}

.reward-list-body .order_amt-right h3 {
    color: #ffdd00;
}

.reward-list-body .checkoutDate,
.reward-list-body .checkoutTime {
    position: relative;
    padding: 0 0 0 25px;
    color: #474747 !important
}
.reward-list-body .checkoutDate {
    padding: 0 0 0 0px;
}
.reward-list-body .checkoutDate:before,
.reward-list-body .checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.reward-list-body .myacc_order_details .current_order {
    padding: 0 0 110px 0
}

.promo-form {
    text-align: center;
    padding: 40px 15px;
    margin: 0;
    background: #000;
    border-radius: 0px;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 48px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 18px;
    background: #ea2876;
    padding: 13px 20px;
    height: 48px;
    min-width: 146px;
}

.promo-form h3 {
    color: #fff;
    font-size: 36px;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.3
}

.promo-form h3 span {
    font-size: 19px;
    display: block;
}

.promo-earned-col-image {
    position: relative
}

.promo-earned-col-image img {
    width: 100%
}

.promo-earned-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px
}

.promo-bot-left span {
    display: block;
}

.promo-bot-left p {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
}

.promo-bot-left p span {
    display: block;
    font-size: 14px;
}

.promation_btn {
    line-height: 0
}

.promation_btn .button {
    margin: 0;
}

.promo-earned-col .tag {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background: #ea2876;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
}

.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0;
    margin: 0
}

.mypromo-main-div .myacc_order_details .promo-earned-col {
    width: 31.33%;
    background: #000;
    overflow: hidden;
    margin: 0 1%;
    margin-bottom: 20px;
}

.pickup-cart-div .cart-direction:after {
    display: none
}

.pickup-cart-div .cart-direction img {
    width: 50px;
    margin: 0 auto
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 10px 0
}


span.myaccount_separate {
    width: 2px;
    height: 20px;
    display: inline-block;
    background: #000;
    margin: 0 8px 0 3px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.white_bgbx {
    background: #fff;
    padding: 30px 25px;
}

.brand_note {
    margin: 0 0 70px;
}

.about_lirow {
    margin: 0 auto 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 900px;
    position: relative;
}

.about_lirow:last-of-type {
    margin-bottom: 20px
}

.about_liimg {
    width: 50%;
}

.about_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.about_liinfo {
    width: 50%;
    padding: 50px 50px;
    background: #5d8d87;
}

.diamond_divider {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.diamond_divider span {
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-bottom-color: #000;
    position: relative;
    top: -22px;
}

.diamond_divider span:after {
    content: '';
    position: absolute;
    left: -40px;
    top: 60px;
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-top-color: #000;
}

.award_gallery {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.award_galcol {
    width: 32%;
    position: relative;
    margin: 0 0 30px;
    z-index: 1
}

.award_galimg {
    height: 360px;
    position: relative;
    overflow: hidden;
    background: #fff;
    padding: 20px;
    border-style: solid;
    border-width: 15px;
    border-top-color: #333333;
    border-right-color: black;
    border-bottom-color: #333333;
    border-left-color: black;
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    z-index: 1
}

.award_galimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.award_label {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background: #869f6e;
    text-align: center;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 25px;
    transform: rotate(-42deg);
    -webkit-transform: rotate(-45deg);
    width: 180px;
    line-height: normal;
    z-index: 1;
    display: none
}

.award_label span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.award_galinfo {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    left: 35px;
    right: 35px;
    top: 80px;
    bottom: 35px;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    padding: 15px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    text-align: center;
    z-index: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.award_galcol:hover .award_galinfo {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.hook {
    width: 6px;
    height: 6px;
    background-color: #5c5c5d;
    margin: 0 auto 40px auto;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 0 1px #000000;
    box-shadow: 0 1px 0 1px #000000;
    position: relative;
}

.hook:before {
    content: '';
    width: 100px;
    height: 100px;
    border: 2px solid #a5a1a1;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    margin-left: -50px;
}

.award_galinfo h3 {
    font-size: 18px;
    color: #fff;
}

.award_galinfo h3 a,
.test-popup-link {
    color: #fff
}

.test-popup-link:hover,
.award_galinfo h3 a:hover {
    color: #809b66;
}

.award_plus {
    display: block;
    font-size: 40px;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.award_galcol:hover .award_plus {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
}

.test-popup-link:hover .award_plus {
    border-color: #809b66;
}

.change-pickup-location {
    margin-bottom: 20px !important;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}
.contact_col {
    float: left;
    width: 50%;
    padding: 0 15px;
}

.contact_form .form-control {
    width: 100%; display: block; 
}

.contact_form textarea.form-control {
    margin: 0;
    height: 120px;
}

.contact_form .btn_sec .btn {
    width: 100%;
    margin: 0;
}

.our-policy {
    margin: 30px 0 0 0;
}

.our-policy h6 {
    margin: 0 0 10px 0
}

.service-makkan {
    margin: 0 auto 30px;
    text-align: center;
}

.service-makkan img {
    margin-top: -110px;
    max-height: 182px;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

ul.outletul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.outletul > li {
    width: 49%;
    margin: 0 2% 30px 0;
    background: #5d8d87;
    position: relative
}

.outletul > li:nth-child(even) {
    margin-right: 0
}

.ourrest_img {
    width: 100%;
}

.ourrest_img img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.ourrest_info {
    width: 100%;
    padding: 30px 20px 70px 20px;
    color: #fff
}

.ourrest_info h4 {
    margin: 0 0 15px;
    color: #fff;
    font-size: 22px
}

.ourrest_info p {
    margin: 0 0 8px;
}

.ourrest_infolinks {
    list-style: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.ourrest_infolinks li {
    display: block;
    width: 50%;
}

.ourrest_infolinks a {
    padding: 14px 5px;
    display: block;
    color: #fff;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    font-family: 'Calibri-Bold';
    height: 100%
}

.media-links-a a {
    background: #869f6e
}

.media-links-b a {
    background: #666
}

.ourrest_infolinks a:hover {
    background: #5d8d87;
}

.ourrest_infolinks a i {
    display: inline-block;
    margin: 0 10px 0 0;
}

.news_list {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.news_list > .news_lirow {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    margin: 0 0 40px;
    background: #fff;
}

span.date {
    color: #9c9c9c;
    font-size: 16px;
    display: block;
    margin: 0 0 10px 0;
}

.news_liimg {
    width: 100%;
}

.news_liimg img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.news_liinfo {
    width: 100%;
    padding: 20px 0 0 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.news_liinfo h4 {
    margin: 0 0 5px;
    font-size: 19px;
    line-height: 1.3;
}

.news_liinfo h4 a {
    color: #000;
    display: block
}

.news_liinfo h4 a:hover {
    color: #5d8d87
}

.news_liinfo p {
    margin: 0 0 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.news_readmore {}

.cdd-details-lhs .checkout-heading:after {
    content: "";
    width: 2px;
    top: 5px;
    bottom: 5px;
    right: 0;
    margin: auto;
    position: absolute;
    background: #fff;
    height: 60%;
    display: block;
    z-index: 1;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

#scrollbutton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #353535;
    display: inline-block;
    position: relative;
    color: #fff;
    text-decoration: none;
    line-height: 0;
    padding: 14px 18px;
    overflow: hidden;
    border-radius: 4px;
    font-size: 20px;
}

#scrollbutton a:hover {
    background: #cb4d36
}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1,
#scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1,
#scrollbutton a:hover i.ars2 {
    bottom: 18%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 9%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}

.reservation-full {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.reservation-counter,
.choose-outlet,
.choose-datetime-full,
.special-instructions {
    background-color: rgba(210, 190, 155, 0.38);
    border-radius: 10px;
    clear: both;
    overflow: hidden;
    margin-bottom: 7px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.reservation-full h2 {
    font-size: 40px;
}

.reservation-counter h3,
.choose-outlet h3,
.choose-date h3,
.choose-time h3,
.special-instructions h3 {
    font-family: 'subway_novellaregular';
    font-size: 26px;
    margin-bottom: 10px;
}

.reservation-counter-left h4 {
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 10px;
    text-transform: uppercase;
    margin-right: 10px;
}

.reservation-counter-left,
.reservation-counter-right {
    float: left;
}

.reservation-counter-full {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.choose-date,
.choose-time {
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
}

.special-instructions textarea {
    max-width: 440px;
    height: 130px;
    resize: none;
    margin-bottom: 0px;
    padding: 20px;
}

.choose-outlet .custom_select {
    max-width: 350px;
    margin: 0 auto 0px !important;
}

.choose-outlet .custom_select select {
    margin-bottom: 0px;
}

.choose-date .catering_days_ul li dl dt,
.choose-time .catering_days_ul li dl dt {
    font-size: 12px;
}

.choose-date .catering_days_ul li dl.active,
.choose-time .catering_days_ul li dl.active {
    border: 2px solid #809b66;
}

.choose-date p,
.choose-time p {
    font-family: 'open_sanslight';
    font-size: 12px;
    text-align: left;
    margin-bottom: 0px;
}

.choose-time {
    margin-top: 30px;
}

.reservation-summary {
    max-width: 425px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border: 2px solid #cfbfaa;
    padding: 25px;
    overflow: hidden;
}

.reservation-summary ul {
    padding-left: 0px;
    list-style-type: none;
    padding-bottom: 0px;
}

.reservation-summary ul li span {
    font-size: 15px;
    text-transform: uppercase;
    overflow: hidden;
    width: 50%;
    float: left;
    text-align: left;
}

.reservation-summary ul li span:first-child {
    position: relative;
    padding-right: 10px;
}

.reservation-summary ul li span:last-child {
    padding-left: 10px;
}

.reservation-summary ul li span:first-child::after {
    content: ":";
    position: absolute;
    top: 0px;
    right: 0px;
}

.reservation-summary ul li {
    margin-bottom: 15px;
    overflow: hidden;
}

.reservation-summary ul li:last-child {
    margin-bottom: 0px;
}

.reservation-summary ul li span.summary-email {
    text-transform: lowercase;
}

.reservation-summary ul li span.summary-childseat {
    font-style: italic;
    text-transform: lowercase;
    color: #4e4e4e;
    font-size: 14px;
}

.reservation-summary-full {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(210, 190, 155, 0.38);
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 50px;
    overflow: hidden;
}

.reservation-summary-full h3 {
    font-family: 'subway_novellaregular';
    font-size: 26px;
    margin-bottom: 20px;
}

.reservation-summary-button {
    max-width: 860px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 8px;
}

.reservation-summary-button .go-back,
.reservation-summary-button .continue {
    width: 49.5%;
    float: left;
}

.reservation-summary-button .go-back {
    background-color: #000;
}

.reservation-summary-button .continue {
    background-color: #809b66;
}

.reservation-summary-button .go-back a,
.reservation-summary-button .continue a {
    margin-top: 0px;
    margin-bottom: 0px;
    background: none;
}

.reservation-summary-button .go-back a:hover {
    background: #5d8d87;
}

.reservation-summary-button .continue a:hover {
    background: #333;
}

.go-back a {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 20px;
}

.choose-date .catering_days_ul li dl:hover,
.choose-time .catering_days_ul li dl:hover {
    border: 2px solid #a23c2b;
}

.choose-date .catering_days_ul li dl.cl-next-days,
.choose-time .catering_days_ul li dl.cl-next-days {
    border: none;
}

.choose-outlet select {
    font-size: 15px;
    color: #595656;
    padding-left: 15px;
    padding-right: 15px;
}

.reservation_order_details {
    text-align: center
}

.reservation_outlet,
.reservation_user,
.reservation_Date,
.reservation_Time {
    border-bottom: 1px solid #e4e4e4;
    padding: 0 0 15px 0;
    margin: 0 0 18px 0
}

.reservation_order_details h4 {
    color: #818181;
}

.reservation_order_details p {
    color: #000;
    font-family: 'Calibri-Bold';
    font-size: 19px
}

a.add-address-more {
    display: inline-block;
    position: relative;
    font-size: 24px;
    margin: 0 0 0 5px;
}

.address_linfo {
    margin-bottom: 15px;
}

.address_linfo .custom_radio span {
    color: #555555;
}

.address-list-cls {
    max-height: 240px;
    overflow-y: auto;
}

.disclaimer {
    margin: 30px 0 0 0;
}

.disclaimer h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.disclaimer ul {
    padding-left: 20px
}

.horder_tt {
    padding: 12px 10px 12px 20px;
}

.oreder-row-inv {
    margin: 0;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.oreder_itm_row {
    padding: 15px 5px 10px 5px !important;
}

.order-items-maindiv {
    background: #f5f5f5;
}

.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div {
    margin: 0 auto 20px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    font-family: 'Calibri-Bold';
    font-size: 18px;
}

.catering-product-rowlist .price_nor {
    font-size: 20px;
    display: block;
    padding: 10px 0 0 0;
}

.catering-product-rowlist .price_nor sup {
    font-size: 80%;
    font-family: 'Calibri';
}

.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}

.custom-tarms-div .sign_reg {
    padding-left: 30px
}

.custom-tarms-div span:before {
    width: 20px;
    height: 20px;
    border: 2px solid #d45d38;
    margin-top: 2px;
    border-radius: 0;
}

.custom-tarms-div .custom_checkbox input[type=checkbox]:checked + span:before {
    background: none;
    content: "\F00C";
    font-family: FontAwesome;
    text-rendering: auto;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-align: center;
    background: #d45d38;
    border: 2px solid #d45d38;
}

.error_div_cls {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px !important;
}

h3.choose-eventtype {
    position: relative;
}
a.go-bck-icon {
    position: absolute;
    left: 0;
    top: 0;
}
a.go-bck-icon:before {
    content: "\f0a8";
    font-family: Fontawesome;
    font-size: 30px;
}

.hall-select-popup .mfp-close {
    color: #fff
}

.pkg-detail-top-row .pkselected_catgrytext {
    text-align: left;
}

.pkselected_filtersec .pck_selected_ctry {
    font-size: 21px;
    padding: 0;
}

.pkg-detail-top-row .pkselected_package:first-of-type {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.guest_btn {
    text-align: center;
}

.pkglist_itemtable .pkglist_img img {
    max-height: 200px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 14px;
    display: inline-block;
    color: #272727;
    font-family: 'Calibri-Bold';
}

.package_info_maindiv .package_info_title {
    color: #d45d38;
    text-align: center;
}

.package_info_maindiv .package_info_subtitle {
    color: #bb4d4d;
    text-align: center;
}

.package_info_maindiv .terms_conditions h4 {
    color: #bb4d4d;
    font-size: 16px;
    padding-top: 15px;
}

.back-to-listbtn a {
    width: 100% !important;
}

.package_info_maindiv .text-rgt {
    float: right;
}

.package_info_maindiv .terms_conditions li {
    list-style-type: none;
}

.package_info_maindiv .terms_conditions ul {
    padding-left: 30px;
}

.custom-addon-checkbox .pkgerror_txt {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 25px;
    font-size: 13px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    width: 100%;
}

.apply-red {
    color: red!important;
}

ul.venue_type_item {
    padding: 20px 0 0 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

ul.venue_type_item li {
    margin: 5px 10px;
}

ul.venue_type_item li a {
    padding: 15px 25px;
    background: #809b66;
    color: #fff;
    border-radius: 5px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: block;
}

ul.venue_type_item li.venuetype-active a {
    background: #d45d38;
}

.venue_popup {
    max-width: 780px
}
.pdpa_trmcont_link {
    font-size: 16px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .order-body { border: 2px solid #5d8d87; }
.delivery_outletpoup .self_popup_hea_row { background: #5d8d87; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #d45d38; }
.delete-act-icon {
	position: absolute;
    right: 9px;
    top: 1px;
}
.act-remove-topdiv {
	position: relative;
	padding-right: 20px;
}

.venue_popup .close-icon-but {
    background: #809b66;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;
}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 450px;
    margin: auto !important;
    padding: 30px;
    font-size: 22px;
    border-radius: 10px;

    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-family: 'Calibri-Bold';
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    font-family: 'Calibri';
    vertical-align: text-top;
}

.serving-passionately-left .custom-dots{ padding:0; position: absolute; bottom: 12px; left: 0; right: 0; margin: 0; text-align: center;}
.serving-passionately-left .custom-dots li{ display: inline-block; width: 20px; height: 20px; position: relative; z-index: 1;}
.serving-passionately-left .custom-dots li button{ padding: 0; margin: 0;    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%; }
 .serving-passionately-left .custom-dots li button:before{ content: "";  width: 12px; height: 12px; position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; border-radius: 50%;  }
.serving-passionately-left .custom-dots li button::before{ background: #809b66; border: 2px solid #fff;   }
.serving-passionately-left .custom-dots  li.slick-active button:before{ background: #000; border: 2px solid #fff; }
.package_info_desc a{ color: #000;}
.back-to-pkglist { 
	background: #d45d38;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    position: relative;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 0;
    cursor: pointer;
    font-family: Calibri-Bold;
    letter-spacing: 1px;
    display: inline-block;
	margin-bottom: 5px;
}

.back-list-also {
	-webkit-align-items: flex-start !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.timeslot_info {
    font-size: 13px;
    color: #f20000;
    margin-bottom: 15px;
}
.timeslot_info span {
    display: block;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time.delivery_submit_cls {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
	display: block;
}

.membership_status {margin-top: -33px;position: relative;}
.membership_status span {position: absolute;left: 0;right: 0;top: 13px;text-transform: uppercase;}
.membership-desc { background-color: #d45d38; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase; margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0; }
.user-id span {text-transform: uppercase; font-family: 'Calibri-Bold'; }
.membership-progress {background: #4f7873; margin: 15px -10px 0; padding: 20px 0 15px; }
.membership-progress p {font-family: 'Calibri-Bold'; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fff; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #d45d38;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {color: #809b66; font-family: 'Calibri-Bold'; font-size: 14px; }

div#redeem_point_otp_div .focus-out {width: calc( 100% - 270px );}
.redeem_otp_butn .applyPointsButn{right: 135px; min-width: 130px;}

.redeem_otp_butn .cancelPointsButn {min-width: 130px;}
.cancelPointsButn{background: red;}
.extra-promotion-popup {
    max-width: 780px;margin: 0 auto;text-align: center;padding: 30px;background: #D45D38;color: #FFF;
}
.extra-promotion-popup h3 {color: #FFF; font-size: 34px;}
.extra-promotion-popup p {font-size: 20px;margin: 0;line-height: 1.6;}
span.static_promo_code {background: #141414;display: inline-block;padding: 8px 10px;line-height: 1;}

.inner_menusec > div > div:nth-child(2){height: 0px !important;}
