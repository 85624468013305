/**** Responsive ****/
@media (max-width: 1799px){    
    .logo-header img {height: 180px;}
    .overlay-menu {padding: 20px 0px;}
    .overlay-menu ul li {padding: 5px 0px;}
    .overlay-menu ul li a {font-size: 28px}
    /*--Inner Header--*/
    .header:after {-webkit-transform: skewY(-5deg); transform: skewY(-5deg);}
    .header-in {padding: 20px 35px 0px 75px;}
}
@media (max-width: 1599px){

.footer {height: 60px;}
.footer-inner {padding: 16px 0px;}
.social-media-footer {top: 16px;}
/*----Home Page----*/
.logo-header img {height: 150px;}
.overlay-menu {top: 183px; height: calc(100% - 183px); padding: 0px 0px;}
.overlay-menu ul li {padding: 4px 0px;}
.overlay-menu ul li a {font-size: 22px;}
.overlay-menu-wrap .social-media-overlay {bottom: -100px;}

/*-----Inner Page Header-----*/
.header {height: 220px;}
.header:after {height: 230px; top: -70px;}
.logo-left > a > img {height: 160px;}
.innerproduct-item-image img {height: 285px;}
.redeem-item-hea .points {max-width: 315px;}

.myaccount-wrapper, .wraper {margin-top: 235px;}
.wraper.checkout-wrapper {margin-top: 190px;}
.wraper.cms_wraper {margin-top: 220px;}
.contact_wrap {margin-top: 240px;}

}
@media (max-width: 1199px){
.footer {height: auto;}
.footer p{padding:0px 220px 0px 15px; font-size: 18px;}
.follow-us {font-size: 18px; margin-right: 8px;}
.icon {height: 20px; width: 20px; margin-right: 5px;}
.menu-icon {width: 44px; height: 34px; right: 20px; top: 20px; padding: 6px 6px;}
.menu-icon.active .icon_menu1 {-webkit-transform: translateY(10px) rotate(45deg) scale(1); transform: translateY(10px) rotate(45deg) scale(1);}
.menu-icon span {height: 2px;}
.logo-header img {height: 120px;}
.overlay-menu ul li a {font-size: 20px;}
/*--Inner Header--*/
.header {height: 180px;}
.header:after {height: 190px; top: -60px;}
.logo-left > a > img {height: 110px;}
.header-right {margin-top: 10px; height: 50px;}
.header-in {padding: 20px 30px 0px 20px;}
/*--Product Page--*/
.wraper_product .container {padding: 0 20px;}
.sidebar_menusec {width: 100%; display: block; margin-bottom: 50px; display: none;}
.pright_sec {width: calc(100% - 0px); width: -webkit-calc(100% - 0px); display: block;}
.sidemenu_list {display: none;}
.mobi-product-menu-list{display: block;}
.mobi-product-menu-list .slick-track .slick-slide > div {width: 100%;}
.mobi-product-menu-list .menu-slide {
    background: #fff; text-align: center; border: 1px solid #ea2876;  border-left: 0;
}
.mobi-product-menu-list .menu-slide a {
    padding: 8px 4px; display: block; font-family: DecimaPlusW01; font-size: 20px; color: #000000;
}
.mobi-product-menu-list .slick-prev, .mobi-product-menu-list .slick-next  {
    font-size: 0; border: 0; outline: 0; position: absolute; background: #fff; left: 0;
    top: 1px; width: 48px; height: 44px; margin: 0; border-right: 1px solid #ecdfcc;
}
.mobi-product-menu-list .slick-next {left: unset; right: 0px; border-left: 1px solid #ecdfcc;}
.mobi-product-menu-list .slick-next::after, .mobi-product-menu-list .slick-prev::after {
    content: ""; background: url(../images/spirite_whole.png) no-repeat; width: 26px; height: 26px;
    background-position: -273px -16px; top: 55%; -webkit-transform: translateY(-55%);
    transform: translateY(-55%); position: absolute; left: 0;  right: 0;  margin: auto;
}
.mobi-product-menu-list .slick-prev::after {background-position: -318px -16px;}
.menu-slide.active a {color: #ea2876;}
.plist_bxtt {font-size: 60px;  margin-top: -35px; margin-bottom: 15px;}

/*---My Account----*/
.acc-information {padding: 20px 30px 30px;}
.right-content .top-header {padding: 30px 20px 20px;} 

/*---Checkout---*/
.checkout-total .cdd-details-lhs {width: 100%; margin-right: 0%;}
.checkout-total .cdd-details-rhs {width: 100%; padding: 0px;}
.checkout_hea h3 {font-size: 60px;}
.redeem-col {width: 100%; margin-right: 0%; margin-bottom: 10px; padding: 20px 20px 20px;}  
.innerproduct-item-image img {height: 255px; }
.redeem-col {padding: 35px 30px 30px;}

.wraper.checkout-wrapper {margin-top: 170px;}
.myaccount-wrapper, .wraper { margin-top: 210px;}
.contact_wrap {margin-top: 220px;}
.wraper.cms_wraper {margin-top: 210px;}
/*-----Categoies Menu-----*/
    
.hcategory_sec{
    background: #ecdfcd; transform: skewY(-5deg);  margin-top: 0px; position: relative; 
    display:block; width: 100%;
}
.hcategory_selected_text{ 
	padding: 12px 64px 12px 15px; color: #191919; font-size: 18px; text-transform: uppercase; 
	display: block; position: relative;
}
.hcategory_trigger{
	background: url(../images/menu_ico.png) no-repeat center; background-size: 24px auto; width: 49px;
	height: 49px; position: absolute; right: 6px; top: 0; transform: rotate(0);
	-webkit-transform: rotate(0); transition: all 400ms; z-index: 1; font-size: 0px;
}
.hcategory_trigger.active{ transform: rotate(90deg); -webkit-transform: rotate(90deg);}
.hcategory_menu{
	background: #ecc2d3; position: absolute; left: 0; top: 0; right: 0; max-height: 400px; opacity: 0;
	visibility: hidden; transition: all 400ms; overflow: auto;
}
.hcategory_menu .mCSB_scrollTools{ opacity: 1;}
.hcategory_menu .mCSB_scrollTools .mCSB_draggerRail{ background-color: #ea2876;;}
.hcategory_menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #191919;}
.hcategory_menu.open{ opacity: 1; visibility: visible;}
.hcategory_menulist{ list-style: none; padding: 0; margin: 0;}
.hcategory_menulist > li > a{
	background: #f8f4ed; color: #191919; font-size: 17px; padding: 12px 45px 12px 15px; display: block; text-transform: uppercase;
}
.hcategory_menulist > li > a:hover{ color: #000;}
.hcategory_submenu{ list-style: none; padding: 0; margin: 0; }
.hcategory_submenu >li >a{ color: #191919; font-size: 17px; padding: 12px 15px 12px 45px; display: block; }
.hcategory_submenu >li >a:hover{ color: #000;}
.hsearch_sec.open{ top: 56px;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
.wraper.wraper_product {margin-top: 280px;}  

}   
@media (max-width: 991px){
     h4 {font-size: 18px !important;}
    .btn {font-size: 16px;}
     /*---Product List----*/
     .header {height: 170px; }
    .plist_col {width: 50%;}
    .plist_info h3 {font-size: 28px;}
    .plist_price {font-size: 28px;}
    .plist_img {height: auto; margin-bottom: 15px;}
    .plist_item .innerproduct-button .addcart_row .btn {height: 36px;}
    
    /*---My Account----*/
    .sidebar-left {width: 100%; }
    .right-content {width: calc(100% - 0px); overflow-y: hidden; }
    .sidebar-left .profile {max-width: 220px; margin: 0 auto;}
    .sidebar-left .sidebar-inner {padding: 15px 15px;}
    .acc-information {padding: 10px 22px 20px; }
    .myaccount-sec ul.navlist li {width: 33.33%; height: 44px; line-height: 44px; padding: 0px 1px;}
    .myaccount-sec ul.navlist li a {font-size: 16px;}
    .sidebar-left .totalpoints .points-title {font-size: 14px; }
    .sidebar-left .totalpoints .total-point {font-size: 36px; margin-top: 10px;}
    .sidebar-left .member-benefits p {font-size: 14px;}
    .sidebar-left ul.menu li a:before {width: 15px; height: 15px;}
    .sidebar-left ul.menu li a {font-size: 18px; padding-left: 25px;}
    .field-box {margin-bottom: 10px;}
    .acc-information  .form-group {margin: 0 0 0px;}
    .acc-information .focus-out {margin-bottom: 10px;}
    /*--Order--*/
    .main_tabsec_inner {padding: 20px 20px;}
    .myacc_order_details .current_order {width: 100%; margin-bottom: 30px;}
    .order_details_body {margin-bottom: 15px;}
    .order_amt {padding: 12px 15px;}
    .order_amt h3 {font-size: 20px;}
    .order_btns .button {padding: 15px 10px 15px; height: 44px; font-size: 16px; }
    .promo-form {padding: 30px 15px;}
    .promo-form h3 {font-size: 28px;}
    .promo-form h3 span {font-size: 16px; }
    /*---Cart List---*/
    .cart-sidebar {width: 100%;  max-width: 800px;}
    .cart-header {padding: 30px 20px; height: 135px;}
    .cart_scrollarea {height: calc(100% - 135px); }
    .cart-header:before { height: 160px;}
	.cart-header.min-order-amt {height: 120px;}
    .cart-header .ctitle h6 {font-size: 18px;}
    .cart-header-first .row-replace .col-sm-cls, .cart-header-second .row-replace .col-sm-cls {
        width: 100%; text-align: left; margin-bottom: 15px; padding-left: 0px; padding-right: 0px;
    }
    .cart-header-first p {font-size: 14px;}    
    .cart-header-first .row-replace .col-sm-cls:last-child, .cart-header-second .row-replace .col-sm-cls:last-child {
        margin-bottom: 0px;
    }
	.hcart_tt {margin: 0px 20px 0px 20px;padding: 5px 10px 5px 10px;}
	.hcart_tt h3 {font-size: 18px;}
    .cart_row.cart-header-second h3 {margin: 0px 0px 5px 0px; font-size: 28px;}
    .hclear_cart {font-size: 18px;}
    .cart_info {width: auto;}
	.cart_body.pdt-cart-list .cart_row {padding: 20px 10px 15px 10px;}
	.cart-sidebar .cart_body {padding: 0px 20px 60px;}
	.cart-sidebar .cart_footer {padding: 30px 30px 30px 15px; margin-top: 60px;}
    .cart-sidebar .cart_footer .cart_row {padding: 0px 0px 5px 0px;}
    .cart_remove {right: 10px; top: 22px; width: 18px; height: 18px;}
    .cart-header .ctitle h4 {font-size: 24px !important; margin: 0px 0px 10px 0px;}    
    .cart-header .ctitle h6 span.cart-min-amt {color: #e72875; font-size: 24px;}
    /*---Checkout---*/   
    .check_pg_inner .chk-title h4 {text-transform: uppercase;} 
    .promotion_offer_inner {padding: 25px 20px 20px 20px;}
    .checkout-form-wrap {padding: 20px 20px 20px;}
    .promotion_offer_inner .chk-title h6 {font-size: 24px;}
    .promotion_offer_inner .chk-title h3 {font-size: 36px;}
    .promotion_offer_wrap .form-control, .checkout-form-wrap .form-control{height: 44px; margin-bottom: 10px;}
    .chk_hea {font-size: 18px; }
    .checkout-total .focus-out label {font-size: 14px; }
    .checkout-total .controls-single input[type="text"] {font-size: 14px; }
    .chosen-container-single .chosen-single {height: 44px; line-height: 44px; font-size: 14px;}
    .cdd-details .cart-header:before {height: 120px;}
    .cdd-details .cart-header {padding: 20px 20px; height: 100px; }
    .cdd-details .cart-header h3 {font-size: 28px;}
    .cdd-details .chekout_cart_bar .hcart_tt {margin: 20px 20px 0px 20px;}
	.cdd-details .chekout_cart_bar .cart_body {padding: 0px 20px 70px;}
    .cdd-details .cart_body.pdt-cart-list .cart_row {padding: 20px 10px 15px 10px;}   
    .chekout_cart_bar .cart_footer {padding: 20px 25px 20px 20px;}
    .cart_footer .cart_remove {right: -22px; width: 16px; height: 16px; }
    .chekout_cart_bar .cart_footer .cart_row {padding: 0px 0px 5px 0px;}
    .redeem-item-hea h4 {font-size: 22px !important;}
    .redeem-item-hea span {font-size: 18px;}
    .redeem_apply .btn {font-size: 16px; padding: 14px 12px; min-width: 90px;}
    .chk-payment .chk-title h4 {font-size: 28px !important;}
    .chk-payment-col-radio li .radio_con {font-size: 18px;}
    .chk-payment-col-radio li .radio_con img{height: 25px;}
    .chk-payment .chk-payment-col .btn {font-size: 18px; padding: 14px 22px;}
    .indication .help-block {font-size: 18px;}

    .myaccount-wrapper, .wraper { margin-top: 190px; }
    .wraper.checkout-wrapper {margin-top: 150px;}
    .contact_wrap {margin-top: 200px;}
    .wraper.cms_wraper {margin-top: 190px;}

    .redeem_otp_butn .applyPointsButn {right: 95px;}
    /*---Product Details popup---*/   
    .prd_chosen_row {padding: 15px 15px;}
    .compo_acc_innerdiv {padding: 10px 10px 0px 10px;}
    .qty_bx {width: 112px; height: 36px; padding: 0 38px;}
    .qty_bx span {width: 36px;}
    .qty_bx span:after {width: 18px; height: 18px; margin-top: -9px;}
    .qty_bx span.qty_minus:after {left: 6px; height: 21px; margin-top: -10px; width: 21px;}
    .qty_bx span.qty_plus:after {right: 8px;}
    .radio .qty_bx label {font-size: 18px; padding: 5px 2px;}
    .combo-input-label {padding: 7px 2px;}
    .prd_chosen_sub_row .addcart_row {max-width: 124px;}
    .prd_chosen_sub_row {padding: 0px 20px 15px;}
    .prd_chosen_sub_row .btn {padding: 16px 22px 12px; height: 50px; font-size: 18px !important;}
    .inn_product_hea_left h3 {font-size: 24px; letter-spacing: -0.8px;}
    .inn_product_review .offer50 {width: 66px; height: 32px; line-height: 32px; font-size: 14px; right: -15px;}
    .compopro_acc_head {padding: 10px 15px 10px 15px;}
    .product_chosen_hea h6 {font-size: 16px;}
    .check_pg_inner .chk-title h4 {font-size: 18px !important; }
	
    /*---Thanks---*/    
    .wraper.tnk-you-wrapper {margin-bottom: 50px;}
    .tick img{height: 70px;}
    .tick h2 {font-size: 60px; letter-spacing: 1px; }
    .tick span {font-size: 28px; letter-spacing: 1px;}
    .tnk-inner-wrapper .cart-header {padding: 30px 20px; height: 100px;}
    .tnk-inner-wrapper .cart-header:before {height: 125px; }
    .tnk-inner-wrapper .cart-header h3 {font-size: 28px;}
    .delivery_total h4 {font-size: 18px !important; }
    .delivery_total p {font-size: 14px;}
    .tnk-delivery {padding: 20px 20px;}
    .delivery_total.delivery_total_number h5 {font-size: 18px;}
    .delivery_total.delivery_total_number h3 {font-size: 28px;}
    .cart_footer p, .cart_footer span {font-size: 18px;}
    .cart_footer .cart_row.cart_footer_totrow p, .cart_footer .cart_row.cart_footer_totrow span {font-size: 28px;}
    .tnk-inner-wrapper .cart_footer .cart_row {padding: 0px 0px 5px 0px;}
    .cart_extrainfo p {font-size: 14px;}
    .cart_price p {font-size: 18px;}
    .tnk-chk-order .btn {font-size: 18px; padding: 14px 22px;}
    .thanku-footer-bg.footer-bg:after {height: 740px; top: -440px;}
    .tnk-inner-wrapper .cart_body.pdt-cart-list .cart_row {padding: 20px 0px 15px 0px;}    

    .cms_wraper h3.title {font-size: 44px;}
    .cms_wraper p {font-size: 14px;}
    .cms_wraper h1 { font-size: 42px; }
    .cms_wraper h2 { font-size: 34px; }
    .cms_wraper h3 { font-size: 24px; }
    .cms_wraper ul li {font-size: 14px;}

    .receipt_popup .tnk-detail h2 {font-size: 20px; }
    .receipt_popup .tnk-order h3 { font-size: 26px;}
    .congrats {padding: 20px 20px 15px;}
    .congrats h3 {font-size: 20px;}
    .congrats h2 {font-size: 32px;}
    .addon-list-slider .addcart_row .btn {font-size: 16px; height: 36px; line-height: 36px;}

    /*---POPUP---*/
    .pop-whole-lhs{
        padding: 80px 15px; position: static; -webkit-transform: translateY(-0%); transform: translateY(-0%); 
    }
    .pop-whole-lhs-inner{max-width: 100%; margin: 0 auto;}    
    .login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{
        right: 5px; opacity: 1; width: 30px; height: 30px; line-height: 30px;
    }
    .signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{
        padding: 50px 20px 15px 20px;
    }
    .inside-popup-rhs{width: 100%; max-width: 100%; margin: 0 auto; float: none;}
    .popup_equalrw{display: flex; flex-wrap: wrap; }
    .pop_title{text-align: center; padding-left: 0; margin: 0 auto 10px auto;}
    .pop_title h2{margin-top: 10px;}
    .popup_ttsec, .news_postal_popup .popup_ttsec {width: 100%; padding: 30px 20px; height: auto; min-height: auto;}
    .popup_right {width: 100%;  padding: 20px 20px; }
    
    .addon-list-slider .innerproduct-button .addcart_row {max-width: 180px;}
    .cart-header.min-order-amt:before {height: 142px;}
    .wraper.wraper_product {margin-top: 260px;}     
    .cart-header.min-order-amt + .cart_scrollarea {height: calc(100% - 120px);}
    .cartaction_checkout {padding: 15px 22px; }

}
@media (max-width: 767px){
    /*--Inner Header--*/ 
    .header {height: 160px; }   
    .header:after {height: 170px; top: -50px;}
    .header-right {margin-top: 20px; }

    .logo-left > a > img {height: 100px;} 
    .footer p {padding: 0px 15px 0px 15px; font-size: 16px;}
    .social-media-footer {position: relative; top: 0px; margin-top: 10px; justify-content: center;}
    .follow-us {font-size: 16px;}
    .full-screen-overlay {padding: 30px 0px 140px;}
    .overlay-menu {top: 153px;  height: calc(100% - 153px);}  
    /*---Checkout---*/
    .cart_left {width: 100%;text-align: left;}
    .cart_right {width: 100%;}
    .checkout-total .form-group.controls-three > div {width: 100%;  margin-bottom: 10px;}
    .chekout_cart_bar .cart_info {width: auto;}
    .cart_img {margin-bottom: 10px;}
    .redeem-item-hea-inner {width: 100%;}
    .redeem-item-hea .points {position: static;  margin: 10px 0; max-width: 100%; padding: 8px 10px; font-size: 18px;}
    
    /*---Contact Us---*/
    .locate-map {padding: 20px;}
    .contact_form {padding: 20px 20px 20px;}
    .locate-mapleft {width: 100%; -webkit-order: 2; order: 2; }
    .locate-mapright {width: 100%; -webkit-order: 1; order: 1;}    
    .contact_col {width: 100%; padding: 0;}
    /*--Promotions--*/
    .mypromo-main-div .myacc_order_details .promo-earned-col {width: 48%;}
    .promation_btn .button {padding: 12px 20px 10px; font-size: 16px;}
    .promo-earned-col .tag {font-size: 14px;}    
    .innerproduct-item-image img {height: 205px; }
    .pop-whole-lhs {padding: 40px 15px;}
    .inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs {
        padding: 15px 20px;
    }
    .mobi-product-menu-list.myacc-menu-list {display: block; margin-bottom: 8px;}
    .myaccount-sec ul.navlist{display: none;}
	.right-content .top-header .title-name:before {top: 12px; width: 44px; height: 44px;}
	.right-content .top-header .title-name span {font-size: 22px;}
    .right-content .top-header .title-name h4 {font-size: 32px !important;}
    .right-content .top-header {height: 136px;}
    .wraper.wraper_product {margin-top: 240px;}
}
@media (max-width: 576px){
    .cdd-details .cart-header {height: 90px;}
    .plist_col {width: 100%;}
    .plist_bxtt {font-size: 48px; margin-top: -25px;}
    .sidebar_menusec {margin-bottom: 40px;}
    /*---My Account---*/
    .myaccount-sec ul.navlist {flex-wrap: wrap; }
    .myaccount-sec ul.navlist li {width: 50%; margin:1px 0px;}
    .myaccount-sec .count {width: 22px; height: 22px; line-height: 23px; font-size: 14px;}
    .right-content .top-header {padding: 15px 20px 10px;}
    .right-content .top-header:before {height: 120px; top: -30px;}
    .right-content .top-header .title-name:before {top: 14px;}
    .other-add-row li {width: 100%;}

    /*---Product Details popup---*/
    .check_pg_inner {padding: 20px 30px 10px 30px;}   
    .check_pg_inner .slick-prev {width: 30px; height: 60px; left: -30px; padding: 10px 15px 10px;}
    .check_pg_inner .slick-next {width: 30px; height: 60px; right: -30px; padding: 10px 15px 10px;}
    .check_pg_inner .slick-next::after {width: 22px; height: 22px; top: 32%;}
    .check_pg_inner .slick-prev::after {width: 22px; height: 22px; top: 32%;}
    .check_pg_inner .innerproduct-col {margin-bottom: 25px;} 
    .chk-payment-col {width: 100%;}
    .chk-payment-col-radio li {
        max-width: 100%; width: 100%; margin-right: 0%; margin-bottom: 15px; padding: 12px 14px 10px 14px;
    }
    .chk-payment-col-radio li:last-child{margin-bottom: 0px;}
    .prodet_bansec .slick-slide .check-pg-slide img {height: auto;}
    /*--My Account--*/
    .mypromo-main-div .myacc_order_details .promo-earned-col {width: 100%;}
    .promo-form input[type="text"] {padding: 5px 100px 5px 15px; }
    .promo-form .applyBtn {padding: 16px 20px;  min-width: 80px;}
    .filter_tabsec .nav-tabs>li>a {font-size: 16px;}
    /*---popup---*/
    .pop-whole-lhs{padding: 25px 15px;}
    .pop-whole-lhs h3{font-size: 28px;}
    .pop-whole-lhs p{font-size: 16px;}
    .redeem_apply .focus-out label {left: 5%; letter-spacing: -0.5px;}
    .syd_date, .syd_time.delivery_submit_cls { width: 100%;}
    .sidebar-left .member-benefits {padding: 15px; margin: 5px 0px 30px;}
    .sidebar-left .member-benefits h4 {margin: 0px 0px 10px 0px; }
    .sidebar-left .member-benefits p:last-child {margin: 0px 0px 0px 0px;}
    .right-content .top-header {height: 112px;}
}
@media (max-width: 480px){
    .checkout_hea h3 {font-size: 44px;}
    .delivery_total_left {width: 100%; margin-bottom: 20px;}
    .delivery_total_left:last-child{margin-bottom: 0px;}
    .delivery_total_left.delivery_total_right {text-align: left;}
    .tick h2 {font-size: 44px; letter-spacing: 0px; margin: 20px 0px 5px 0px; }
    .tick span {font-size: 24px; letter-spacing: 0px; line-height: 1.1; margin-bottom: 20px;}
    
    .myacc_head_sec {flex-direction: column;}
    .head_left h4 {margin-bottom: 10px;}
    .head_right h4 {color: #ea2876;}
    .head_right::before {background: #000000; width: 0%;}

    .plist_bxtt {font-size: 24px;  margin-top: -15px; }
    .plist_info h3 {font-size: 22px;}
    .plist_info{ padding: 5px 5px 0;}
    .chk_hea > span {font-size: 15px; margin:5px 0px 10px;}
    .chekout_cart_bar .cart_info {padding-right: 20px;}
    
    .redeem_apply .btn {min-width: 100%;  position: relative; }    
    div#redeem_point_otp_div .focus-out {width: calc( 100% - 126px );}
    .redeem_otp_butn .applyPointsButn {right: 0px;margin-bottom: 5px;}
	.cart-header.min-order-amt {height: 105px;}
    .wraper.wraper_product {margin-top: 230px;}    
    .cart-header.min-order-amt + .cart_scrollarea {height: calc(100% - 105px);}
}
@media (max-width: 420px){
    .tnk-inner-wrapper .cart-header h3 {font-size: 20px;}	
    
}
@media (max-width: 380px){
    .cdd-details .cart-header h3 {font-size: 24px; }
    .cart_right {text-align: left;}
    .tnk-inner-wrapper .cart-header {height:80px;}
    .tnk-inner-wrapper .cart-header:before {height: 115px;}   
	.cart_footer .cart_row.cart_footer_totrow p, .cart_footer .cart_row.cart_footer_totrow span {font-size: 24px;}
    
}
@media (max-width: 340px){
    /*--Order Page--*/ 
    .order_btns .button {width: 100%;}

}