@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

@font-face {
    font-family: 'DecimaPlusW01';
    src: url('../font/DecimaPlusW01-Bold.eot');
    src: url('../font/DecimaPlusW01-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/DecimaPlusW01-Bold.woff2') format('woff2'),
        url('../font/DecimaPlusW01-Bold.woff') format('woff'),
        url('../font/DecimaPlusW01-Bold.ttf') format('truetype'),
        url('../font/DecimaPlusW01-Bold.svg#DecimaPlusW01-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Raiders';
    src: url('../font/Raiders.eot');
    src: url('../font/Raiders.eot?#iefix') format('embedded-opentype'),
        url('../font/Raiders.woff2') format('woff2'),
        url('../font/Raiders.woff') format('woff'),
        url('../font/Raiders.ttf') format('truetype'),
        url('../font/Raiders.svg#Raiders') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../font/Futura-Thin.eot');
    src: url('../font/Futura-Thin.eot?#iefix') format('embedded-opentype'),
        url('../font/Futura-Thin.woff2') format('woff2'),
        url('../font/Futura-Thin.woff') format('woff'),
        url('../font/Futura-Thin.ttf') format('truetype'),
        url('../font/Futura-Thin.svg#Futura-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Futura Bk';
    src: url('../font/Futura-Book.eot');
    src: url('../font/Futura-Book.eot?#iefix') format('embedded-opentype'),
        url('../font/Futura-Book.woff2') format('woff2'),
        url('../font/Futura-Book.woff') format('woff'),
        url('../font/Futura-Book.ttf') format('truetype'),
        url('../font/Futura-Book.svg#Futura-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'a_FuturaRound';
    src: url('../font/AFuturaRound.eot');
    src: url('../font/AFuturaRound.eot?#iefix') format('embedded-opentype'),
        url('../font/AFuturaRound.woff2') format('woff2'),
        url('../font/AFuturaRound.woff') format('woff'),
        url('../font/AFuturaRound.ttf') format('truetype'),
        url('../font/AFuturaRound.svg#AFuturaRound') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Futura';
    src: url('../font/Futura-Bold.eot');
    src: url('../font/Futura-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/Futura-Bold.woff2') format('woff2'),
        url('../font/Futura-Bold.woff') format('woff'),
        url('../font/Futura-Bold.ttf') format('truetype'),
        url('../font/Futura-Bold.svg#Futura-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}


body {
    color: #000; word-wrap: break-word; font-size: 16px; line-height: 1.4; -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; overflow-x: hidden; font-family: 'DecimaPlusW01'; 
    font-weight: bold; font-style: normal; margin:0; padding:0;
	background: #ea2876;
}

ol, ul {margin: 0; padding: 0px 0px; list-style: none;}
a {color: #cb4d36; text-decoration: none; cursor: pointer; transition: all 400ms;}
a:hover {color: #ea2876}
h4{font-size:21px !important; font-family: 'DecimaPlusW01' !important;}
.btn_yelow{background:#fdd24a; color: #000; border:1px solid #fdd24a;}
.btn_yelow:hover{background:#000; color: #fdd24a; border:1px solid #fdd24a;}
input[type="reset"], input[type="button"], input[type="submit"], .button, button{
     background: #FDD24A; color: #000; padding: 10px 20px 10px; height: 40px; font-size: 20px;
 }
input[type="reset"]:hover, input[type="button"]:hover, input[type="submit"]:hover, .button:hover, button:hover{ 
    background: #000; color: #FFF; 
}
.text-uppercase{text-transform: uppercase;}
.home-wrapper {height: 100vh; width: 100%; max-width: 100%; position: relative;}
.home-bg-video{position: fixed; top: 0; right: 0; bottom: 0; left: 0; overflow: hidden; z-index:0;}
.home-bg-video video{position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; overflow: hidden;}
.footer {background: #1a1a1a; height: 70px; text-align: center; position:relative; width: 100%;}
.footer-fixed{position: absolute; bottom: -1px; left: 0; right: 0;}
.footer p{font-size: 20px; color: #fff; line-height: 1.4; margin: 0px 0px 0px;}
.social-media-footer {position: absolute; top: 22px; right: 25px; display: flex; align-items: center;}
.social-media-footer .follow-us{margin-right: 10px;}
.follow-us{font-size: 20px; color: #fff;}
.icon{display:inline-block; height:24px; width:24px; margin-right: 7px;}
.facebook{background:url(../images/fb.png); background-size:contain; background-repeat: no-repeat;}
.instagram{background:url(../images/instagram.png); background-size:contain; background-repeat: no-repeat;}
.youtube{background:url(../images/youtube.png); background-size:contain; background-repeat: no-repeat;}
.mail{background:url(../images/mail1.png); background-size:contain; background-repeat: no-repeat;}

.full-screen-overlay{
    height: 100%; width: 100%; position: fixed; z-index: 10; bottom: 100%; left: 0;
	padding:50px 0px 160px; overflow-x: auto; overflow: hidden; text-align: center;
	opacity: 1; visibility: visible; transition: 0.5s all; transition: .5s;
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.62) 0%, rgba(0,0,0,1) 64%, rgba(0,0,0,1) 99%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.62) 0%,rgba(0,0,0,1) 64%,rgba(0,0,0,1) 99%);
    background: radial-gradient(ellipse at center, rgba(0,0,0,0.62) 0%,rgba(0,0,0,1) 64%,rgba(0,0,0,1) 99%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9e000000', endColorstr='#000000',GradientType=1 );	
}
.full-screen-overlay.active{bottom: 0; opacity: 1; visibility: visible;}
.overlay-menu-wrap {height: 100%; position:relative;}
.logo-header {margin-bottom: 33px;}
.logo-header img {height: 220px;}
.overlay-menu {position: absolute; top: 213px; left: 0; right: 0; height: calc(100% - 213px); padding: 70px 0px 20px;}
.overlay-menu ul li {padding: 7px 0px;}
.overlay-menu ul li a {font-size: 34px; color: #fff;}
.overlay-menu ul li.active a {color: #ea2876;}
.overlay-menu-wrap .social-media-overlay .follow-us{display:block;  margin-bottom: 10px;}
.overlay-menu-wrap .social-media-overlay {
    position: absolute; margin: auto; bottom: -80px; width: 120px; left: 0; right: 0; text-align:center;
}
.menu-icon {
    display: inline-block; width: 55px; height: 45px; position: absolute; right: 30px; top: 30px; background: #ea1570;
    padding: 10px 8px; z-index:11;
}
.menu-icon span {background-color: #fff; display: block; height: 3px; margin-bottom: 8px; transition: all .5s;}
.menu-icon.active .icon_menu1 {
    top: 8px; -webkit-transform: translateY(12px) rotate(45deg) scale(1);  transform: translateY(12px) rotate(45deg) scale(1);
}
.menu-icon.active .icon_menu2 {
    top: 17px; -webkit-transform: translate(0) rotate(0deg) scale(0); transform: translate(0) rotate(0deg) scale(0);
}
.menu-icon.active .icon_menu3 {
    top: 26px; -webkit-transform: translateY(-10px) rotate(-45deg) scale(1); transform: translateY(-10px) rotate(-45deg) scale(1);
}
.full-screen-overlay.active .overlay-menu ul li, .full-screen-overlay.active .logo-header .logo{animation: slidein 500ms forwards; opacity:0;}
	@-webkit-keyframes slidein {
		from {
			margin-left: -10%;
			opacity:1;
		}

		to {
			margin-left: 0%;
			opacity:1;
		}
	}

	@-moz-keyframes slidein {
		from {
			margin-left: -10%;
			opacity:1;
		}

		to {
			margin-left: 0%;
			opacity:1;
		}
	}
	 @-o-keyframes slidein {
		from {
			margin-left: -10%;
			opacity:1;
		}

		to {
			margin-left: 0%;
			opacity:1;
		}
	}
	 @keyframes slidein {
		from {
			margin-left: -10%;
			opacity:1;
		}

		to {
			margin-left: 0%;
			opacity:1;
		}
	}
	
.full-screen-overlay.active .logo-header .logo{animation-delay: 1s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(1){animation-delay: 1.20s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(2){animation-delay: 1.30s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(3){animation-delay: 1.40s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(4){animation-delay: 1.50s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(5){animation-delay: 1.60s;}
.full-screen-overlay.active .overlay-menu ul li:nth-child(6){animation-delay: 1.70s;}


.home-wrapper.inner-page-wrapper {background: #ea2876; height:auto; overflow-y: hidden;}
.header {
    height: 240px; position: fixed; width: 100%; top: 0; left: 0;
    right: 0; z-index: 10;
}
.header:after {
    content: ''; background: #000; height: 250px; width: 100%; position: absolute;
    top: -80px; left: 0; right: 0; -ms-transform: skewY(-4deg); transform: skewY(-4deg); z-index: 1;
}
.header-in {display: flex; justify-content: space-between; padding: 25px 35px 0px 75px;}
.logo-left{position:relative; z-index:3;}
.logo-left > a > img {height: 180px;}
.header-right > .cart{position:relative; z-index:3;}
.header-right {margin-top: 20px; display: flex; display: -webkit-flex;}
.header-right > .menu-icon {right: 0px; top: -6px; background: #000000; margin-right:18px;}
.header-right > a{position:relative;}
.header-right > .cart > .count {
    position: absolute; background: #fdd24a; width: 24px; height: 24px; color: #000; font-weight: 200; font-size: 13px;
    border-radius: 100%; text-align: center; line-height: 22px; top: -10px; right: -10px;
}
.footer.footer-bg {background: #000;}
.footer-bg:after {
    content: ''; background: #000; height: 280px; width: 100%; position: absolute; top: -130px; left: 0; right: 0;
    -webkit-transform: skewY(7deg); transform: skewY(7deg); z-index: 1;
}
.footer-inner{position:relative; z-index:3; padding: 22px 0px;}
.checkout-footer-bg .footer-inner, .thanku-footer-bg .footer-inner{border-top: 1px solid #555555;}
/*My Account*/
.myaccount-wrapper, .wraper {
    position: relative; z-index: 9; min-height: 800px; margin-top: 255px; margin-bottom: 100px;
}
.myaccount-container {margin: 0 auto; padding: 0 20px; width: auto; max-width: 1430px;}
.myaccount-sec ul.navlist {list-style: none; display: flex; align-items: center; margin-bottom:8px;}
.myaccount-sec ul.navlist li {width: 33.33%; height: 48px; line-height: 48px; padding: 0px 1px;}
.myaccount-sec ul.navlist li a {
    color: #101010; background: #fff; display: block; font-size: 21px; text-align: center; text-transform: uppercase;
    height: 100%;
}
.myaccount-sec ul.navlist li.active a {color: #fff; background: #101010;}
.myaccount-sec .count {
    background: #ea2876; display: inline-block; width: 25px; height: 25px; border-radius: 100%; line-height: 25px;
    font-size: 16px; color: #fff; font-family: 'Calibri';
}
.myaccount-content {background: #fff; border: 2px solid #f2c947;}
.myaccount-content {background: #fff; display: flex; border: 2px solid #f2c947; flex-wrap: wrap;}
.sidebar-left {width: 280px; box-shadow: 6px 0 9px #ececec6b;}
.sidebar-left .sidebar-inner{padding:18px 18px;}
.right-content {width: calc(100% - 280px);}
.sidebar-left .profile {text-align: center; margin-bottom: 5px;}
.sidebar-left .profile .profile-img{
    width: 125px; height: 125px; border: 5px solid #fdd24a; border-radius: 100%;
    display: block; margin: 0 auto; 
}
.sidebar-left .profile .goldmember{
	background:url(../images/gold-member.png) no-repeat; width: 220px; height: 53px; display: inline-block;
    background-size: contain; text-align: center; font-size: 21px; color: #fff; text-transform: uppercase;
    padding-top: 8px; margin-top: -10px;
}
.sidebar-left .profile .join-date {
    display: inline-block; text-transform: uppercase; font-size: 15px; padding: 0px 5px 10px;
	font-family: 'Open Sans', sans-serif; font-weight:700; color:#7a7a7a;
}
.sidebar-left .member-benefits {padding: 20px; border-radius: 10px; border: 1px solid #fdd24a; margin: 5px 0px 40px;}
.sidebar-left .member-benefits h4 {font-size: 21px; text-align: center; text-transform: uppercase; margin-top: 5px;}
.sidebar-left .member-benefits p {
    margin: 0 0 15px; font-family: 'Open Sans', sans-serif;
    font-weight: 400; font-size: 14px; color: #000;
}

.sidebar-left .totalpoints {
    background: #101010;
    padding: 15px 20px;
	margin-bottom: 12px;
	border-radius: 10px;
}
.sidebar-left .totalpoints .points-title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    position: absolute;
    margin-left: 60px;
    margin-bottom: 6px;
}
.sidebar-left .totalpoints .total-point {
    display: block;
    font-size: 41px;
    color: #fdd24a;
    padding-left: 48px;	
    position: relative;
    margin-top: 12px;
	font-family: 'Raiders';
    font-weight: normal;
    font-style: normal;
	margin-bottom: -10px;
}
.sidebar-left .totalpoints .total-point:before{
	content:'';
	background:url(../images/points.png) no-repeat;    
	width: 42px;
    height: 42px;
    display: inline-block;
    background-size: contain;
	position: absolute;
    left: 0;
    top: 0px;
}
.sidebar-left .totalpoints .expiry-points {
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
}

.sidebar-left ul.menu{list-style:none;}
.sidebar-left ul.menu li {
    padding: 8px 20px 6px;
    background: #e8eaec;
    margin-bottom: 2px;
}
.sidebar-left ul.menu li a {
    font-size: 21px;
    color: #151515;
    text-transform: uppercase;
    position: relative;
    padding-left: 32px;
    display: block;
}
.sidebar-left ul.menu li a:before{
	content:'';   
	width: 18px;
    height: 18px;
    display: inline-block;
    background-size: contain;
	position: absolute;
    left: 0;
    top: 5px;
}
.sidebar-left ul.menu li.change-pwd a:before{
	content:'';
	background:url(../images/log.png) no-repeat; 
    background-size: contain;  
}
.sidebar-left ul.menu li.setting a:before{
	content:'';
	background:url(../images/setting.png) no-repeat;
    background-size: contain;
}
.sidebar-left ul.menu li.logout a:before{
	content:'';
	background:url(../images/logout.png) no-repeat;  
    background-size: contain;
}

.right-content .top-header {
    position: relative; padding: 30px 110px 20px; height: 154px; overflow-y:hidden;
}

.right-content .top-header:before {
    content: '';
    background: #000;
    height: 160px;
    width: 100%;
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
    -webkit-transform: skewY(-4deg);
    transform: skewY(-4deg);
    z-index: 1;
}
.right-content .top-header .title-name {
    position: relative;
    z-index: 9;
	padding-left: 60px;
}

.right-content .top-header .title-name h4 {
    font-size: 42px !important;
    font-family: 'Futura';
    font-weight: bold;
    font-style: normal;
    color: #fff;
    text-transform: uppercase;
}
.right-content .top-header .title-name span {
    font-size: 28px;
    color: #fff;
    margin-bottom: -5px;
    display: inline-block;
}
.right-content .top-header .title-name:before{	
	content:'';
	background:url(../images/hand.png) no-repeat; 
    background-size: contain;  
	content:'';   
	width: 48px;
    height: 48px;
    display: inline-block;
    background-size: contain;
	position: absolute;
    left: 0;
    top: 18px;
}

.acc-information {
    padding: 20px 110px 30px;
}
.acc-information .profile-info-div {
    margin: 20px 0;
}
.acc-information .form_title{font-size:21px; margin: 0px 0px 8px -4px;}
.acc-information [class*="col-"] {
    padding: 0 8px;
}
.acc-information  .two-clmn [class*="col-"] {
    padding: 0 16px;
}
.acc-information .other-add-row li a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}
.acc-information  .form-control {height: 44px; margin: 0 0 0px; font-size: 14px;}
/*---------Cart List---------*/

.cart-sidebar {
    height: 100%;
    width: 800px;
    max-width: 800px;
    position: fixed;
    z-index: 11;
    top: 0;
    right: 0px;
	bottom:0px;
    padding:0px;
    overflow-x: auto;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: 0.6s all ease;
    background: #fff;
}
.cart-sidebar.active {
    opacity: 1;
    visibility: visible;
}
.cartlist-wrapper{height:100%;}
.cart-header{position:relative; padding: 42px 30px; height:172px;}
.cart_scrollarea {height:calc(100% - 172px); overflow-y: auto;}
.cart-header:before {
    content: '';
    background: #000;
    height: 192px;
    width: 100%;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
    -webkit-transform: skewY(-4deg);
    transform: skewY(-4deg);
    z-index: 1;
}
.cart-header .ctitle {
    z-index: 11;
    position: relative;
}
.cart-header .ctitle h4 {
    color: #fff;
    font-size: 35px !important;
    margin: 0px 0px 12px 0px;
    font-family: 'Futura';
    font-weight: bold;
}
.cart-header .ctitle h6 {
    color: #fff;
    font-size: 21px;
    font-family: 'Futura';
    font-weight: bold;
    text-transform: uppercase;
}
.cart-header .ctitle h6 span.cart-min-amt {
    color: #e72875; font-size: 30px;
}

.cart_row.cart-header-first {margin-top: 10px;  border-bottom: 1px solid transparent;}
.cart-header-first h4 {
    font-family: 'DecimaPlusW01'; color: #000000; font-size: 21px; text-transform: uppercase;
     margin: 0 0 10px 0;
}
.cart-header-first p {font-family: 'Open Sans', sans-serif; font-weight: 700;}
.cart_row.cart-header-second {margin: 10px 0 0 0;}
.cart-header-second h5{
    font-family: 'DecimaPlusW01'; color: #3d3d3d; font-size: 21px; text-transform: uppercase;
}
.cart_row.cart-header-second h3 {
    margin: 0px 0px 15px 0px; font-size: 32px; font-family: 'Futura'; font-weight: bold;
}
.hcart_tt {
    background: #060203; padding: 5px 10px 5px 15px; color: #fff; margin: 0px 20px 0px 25px;
}
.hcart_tt .row-replace {-webkit-justify-content: space-between; justify-content: space-between;}
.hcart_tt h3 {
    margin: 0; font-size: 21px; margin-top: 4px; text-transform: uppercase; font-family: 'DecimaPlusW01';
}
.hclear_cart {
    font-family: 'DecimaPlusW01'; text-transform: uppercase; font-size: 21px; text-decoration: underline;
}

.cart_body {padding: 0px 25px 100px;}
.cart_row{ padding: 15px 20px 5px 20px; border-bottom: 1px solid #e4e3e3; position: relative;}
.cart_row .row{ margin-left: -5px; margin-right: -5px;}
.cart_row [class*="col-"]{ padding-left: 5px; padding-right: 5px;}
.cart_body.pdt-cart-list .cart_row{padding: 20px 15px 15px 20px;}
.cart_img{ 
    width: 110px; display: inline-block; margin-right: 10px; vertical-align: top; height: 66px; 
    line-height: 66px; overflow: hidden; background: transparent;
}
.cart_info{
    width: calc(100% - 125px); width: -webkit-calc(100% - 125px); display: inline-block; vertical-align: top; 
    color: #3d3d3d;
}
.cart_info h4{ margin: 0 0 5px; color: #3d3d3d;}
.cart_extrainfo{color: #3d3d3d; padding: 5px 0px 0px 20px;}
.cart_extrainfo p b{ font-weight: 700; color: #3d3d3d; font-style: normal;}
.cart_extrainfo p{ 
    margin: 0; color: #3d3d3d; font-family: 'Open Sans', sans-serif;
    font-size: 16px; font-weight: 400; font-style: normal;
}
.cart_left .help-block{color: #a7a4a4; margin: 5px 0 0;  font-size: 14px; 
    font-style: normal; font-family: 'Open Sans', sans-serif;font-weight: 400;
}
.cart_body.pdt-cart-list .cart_row:last-child{border-bottom: 1px solid transparent;}

.cart_price{ position: relative; margin:0px 25px 10px 0px; }
.cart_price p{ margin: 0; color: #3d3d3d; font-size: 21px; font-family: 'DecimaPlusW01';}
.cart_remove{ background: #b0b0b0 url(../images/cross1.png) no-repeat center; position: absolute; top: 26px; right: 10px;width: 21px; height: 21px; }
.cart_remove:hover{ background-color: #b69a6b; background-image: url(../images/cross1.png);}

.qty_bx{ width: 138px; height: 42px; padding: 0 46px; overflow: hidden;}	
.qty_bx input{ 
	background: #060103; width: 100%; height: 100%;border: none; color: #fff; 
	text-align: center; margin: 0; padding: 2px; font-size: 19px; font-weight: 700; outline: 0;
}
.qty_bx span{ 
	position: absolute; width: 42px;height: 100%; line-height: 36px; display: block; 
	text-align: center;top: 0; cursor: pointer; font-weight: 700; color: #fff; font-size: 30px; transition: all 400ms;
}
.qty_bx .qty_minus{ background: #e72674; color:#fff; left:0px; border-radius: 5px 5px; border: 1px solid #e72674; }
.qty_bx .qty_plus{ background: #e72674; color:#fff; right: 0; border-radius: 5px 5px; border: 1px solid #e72674;}
.qty_bx span.qty_minus:after {height: 24px; margin-top: -12px; width: 24px;}
.qty_bx span.qty_plus:after {right: 10px;}
.radio .qty_bx label {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    padding: 8px 2px;
    line-height: 26px;
}
.cart_footer {
    padding: 40px 25px 25px 35px;
    background: #000;
    margin-left: 0px;
    margin-right: 0px;
	position: relative;
	margin-top: 100px;
}
.cart_footer:before{
	content: ''; background: #000; height: 190px; width: 100%; position: absolute; top: -30px;
    left: 0; right: 0; -webkit-transform: skewY(-4deg);  transform: skewY(-4deg); z-index: 0;
}
.cart_footer .cart_row{position:relative; z-index:2;}
.cart_footer p, .cart_footer span {font-size: 21px; font-family: 'DecimaPlusW01';}
.cart_footer .cart_row.cart_footer_totrow p, .cart_footer .cart_row.cart_footer_totrow span {
    font-size: 35px;
}
.cart_row.cart_footer_totrow {margin-bottom: 20px;}
.cartaction_checkout {
    width: 100%; display: block; text-transform: uppercase; font-size: 21px; text-align: center; 
    font-family: 'DecimaPlusW01';  margin-bottom: 0px; padding: 11px 22px;  height: 48px;
}
.indication_progress {background: #fff;}
.progress_bar {background: #ea2876;}
.mCSB_inside > .mCSB_container {margin-right: 12px;}
.indication .help-block {
    color: #fff;
    font-size: 21px;
    margin: 15px 0 0;
    font-family: 'DecimaPlusW01';
    text-align: right;
    text-transform: uppercase;
}
a.remove {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    color:#e72674;
    border: 1px solid #fff;
    background-image: url(../images/crosslg1.png); background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    border-radius: 100%;
    transition: 0.5s all;
}
/*----------Checkout page---------*/
.checkout-footer-bg.footer-bg:after {
    height: 1202px;
    top: -920px;
    -webkit-transform: skewY(18deg);
    transform: skewY(18deg);
}
.wraper.checkout-wrapper {
    margin-top: 210px;
    margin-bottom: 70px;
}
.checkout-wrapper .container {
    margin: 0 auto;
    padding: 0 20px;
    width: auto;
    max-width: 1430px;
}

.checkout_hea h3 {
    font-size: 90px;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    text-align: center;
}
.check_pg_inner {
    background: #fff;
    padding: 35px 60px 10px 60px;
    margin-bottom: 10px;
    border: 2px solid #fdd24a;
}
.check_pg_inner .innerproduct-button {
    position: relative;
    transform: none;
    -webkit-transform: none;
    opacity: 1;
    visibility: visible;
    margin-top: 10px;
}
.check_pg_inner .slick-prev {
    width: 38px;
    height: 78px;
    left: -60px;
    background: #060203;
}
.check_pg_inner .slick-next {
    width: 38px;
    height: 78px;
    right: -60px;
    background: #060203;
}
.promotion_offer_inner {
    background: #fff;
    padding: 35px 60px 30px 60px;
    margin-bottom: 10px;
    border: 2px solid #fdd24a;
}

.promotion_offer_inner .chk-title h6 {
    font-size: 28px;
    font-family: 'DecimaPlusW01';
    margin: 0px 0px 10px 0px;
}
.promotion_offer_inner .chk-title h3 {
    font-size: 42px;
    font-family: 'Futura';
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    text-transform: uppercase;
}
.check_pg_inner .chk-title  h4 {
    font-size: 20px !important;
    text-transform: uppercase;
}
.promotion_offer_inner .chk-title h4 {margin: 0px 0px 15px 0px;}
.promotion_offer_inner .form-group {
    margin: 0 0 8px;
    max-width: 560px;
     margin: 0 auto;
}
.promotion_offer_inner .row {
    margin-right: -3px;
    margin-left: -3px;
}
.promotion_offer_inner .row .col-md-6 {
    padding-right: 3px;
    padding-left: 3px;
}

.check_out_fourcommon {
    width: 100%; margin: 0 auto;
}
.checkoutpage_form_outer {
    width: 100%; margin: 0 auto;
}
.cdd-details{
    display: flex; flex-wrap: wrap; margin-bottom: 10px;
}
.checkout-total {width: 100%; margin: 0px auto 0px auto}
.checkout-total::after {display: block; content: "";  clear: both}


.checkout-total .cart-header:after {
    display: none
}

.checkout-total .cdd-details-lhs {
    width: 49.3%;
    margin-right: 1.4%;
    position: relative;
    padding: 0px;
    background: #fff;
}

.checkout-total .cdd-details-rhs {
     width: 49.3%;
    padding: 0px;
    background: #fff;
    position: relative;
}
.checkout-details-bar, .chekout_cart_bar {
    overflow: hidden;
}
.cdd-details .cart-header {
    position: relative;
    padding: 30px 30px;
    height: 120px;
}
.cdd-details .cart-header h3 {
    font-size: 35px;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    text-align: center;
}
.cdd-details .cart-header:before {height: 145px;}
.checkout-form-wrap {padding: 45px 50px 60px;}
.cdd-details .chekout_cart_bar .hcart_tt {margin: 25px 20px 0px 25px;}
.cdd-details .chekout_cart_bar .cart_body {padding: 0px 15px 70px;}
.cart_remove {right: 25px;}
.cart_price {margin: 0px 40px 10px 0px;}
.chk_hea{
    padding: 0px 0px; background: transparent; color: #060203; font-family: 'DecimaPlusW01';
    margin: 0 0 10px 0; font-size: 21px; text-transform: uppercase;
}
.checkout-total .checkout-control-group-top a{    
    color: #dd2670; border-bottom: 1px solid #dd2670; margin: 5px 0 0 0px;
    font-family: 'Open Sans', sans-serif; font-weight: bold;
}
.checkout-total .checkout-control-group-top { padding: 20px 0 25px 0;  border-bottom: 1px solid transparent;}
.checkout-total .checkout-control-group-middle { padding: 30px 0 25px 0; border-top: 1px solid #d0cfcb;}
.checkout-total .focus-out label {left: 20px; top: 14px; font-size: 17px;}
.checkout-total .focused label{ top: -7px; font-size: 11px; }
.checkout-total .checkout-control-group-bottom textarea{
    padding: 20px 30px; color: #615f5f; height: 160px; font-size: 15px; width: 100%;
    outline: 0; border: 1px solid #e2e2e2;
}
.checkout-total textarea::-webkit-input-placeholder {color: #595656;}
.checkout-total textarea::-moz-placeholder {color: #595656;}
.chekout_cart_bar .cart_footer{padding: 40px 20px 40px; margin-top: 60px;}
.chekout_cart_bar .cart_left{padding-left: 0;}
.redeem{position: relative; margin-bottom: 35px;}
.redeem::after{display: block; clear: both; content: "";}
.checkout-control-group-top .custom_checkbox span:before {top: -4px;}
.redeem-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.redeem-col{
    width: 49.3%;
    margin-right: 1.4%;
    background: #fff;
    padding: 35px 50px 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
}
.redeem-col.redeem-col-right{
    margin-right: 0;  background: #fff;
}
.redeem-item-hea h4{
    font-family: 'Futura' !important;
    color: #000000;
    font-weight: bold;
}
.redeem-item-hea span{
    color: #000000;
    font-weight: 700;
    font-size: 21px;
    font-family: 'DecimaPlusW01';
    text-transform: uppercase;
    letter-spacing: -1.2px;
}
.redeem-item-hea .points {
    max-width: 335px;
    padding: 5px 10px;
    color: #ecdfcc;
    font-size: 19px;
    font-weight: bold;
    font-family: 'DecimaPlusW01';
}
.redeem-item-hea .points b{
    color: #fdd24a;
}
.redeem-item-hea .points:hover{
    background: #54362c;
    color: #fff;
}
.redeem_apply {margin-top: 2px;}
.redeem_apply .btn{
    background-color: #1c1c1c;
    font-family: 'DecimaPlusW01';
    font-size: 21px;
    padding: 14px 22px;
    height: 46px;
}
.redeem_apply .btn:hover{
    background: #ea2876;
}
.redeem_apply .focus-out label {
    left: 20%; top: 14px; font-size: 15px; color: #615f5f;
}
.redeem_apply .focused label{ top: -7px; font-size: 11px; }

.chk-payment{
    background: transparent;
    padding: 0px 10px;
    text-align: center;
}
.chk-payment .chk-title h4 {
    font-family: 'Futura' !important;
    font-weight: bold;
    letter-spacing: -1.5px;
    font-size: 35px !important;
    color: #fff;
    margin: 0px 0px 15px 0px;
}
.chk-payment .ttsplitter span{
    background: #fefefe;
    z-index: 1;
}
.chk-payment-col-radio{
    padding-left:  0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.chk-payment-col-radio li{
    max-width: auto;
    width: 49%;
    margin-right: 2%;
    background: #fff;
    padding: 12px 14px 10px 14px;
    border: 1px solid #fff;
    position: relative;
}
.chk-payment-col-radio li:last-child{margin-right: 0px;}
.chk-payment .chk-payment-col-radio li label{
    padding-left: 32px !important;
}
.chk-payment-col-radio li .radio_con {
    font-size: 21px;
    font-weight: bold;
    font-family: 'DecimaPlusW01';
    color: #000;
}
[type="radio"]:checked,
 [type="radio"]:not(:checked) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
}
 [type="radio"]:checked + label,
 [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
}
[type="radio"]:checked + label:before,
 [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 24px;
    height: 24px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 24px;
    height: 24px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -398px -124px;
    position: absolute;
    top: -2px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.chk-payment-col {
    width: 480px;
    margin: 0 auto;
}
.chk-payment .chk-payment-col .btn{
    width: 100%;
    display: block;
    font-size: 21px;
    font-weight: bold;
    font-family: 'DecimaPlusW01';
    margin-top: 25px;
}

/* Chosen select */
.chosen-container{ width: auto  !important; display: block; font-size: 17px; margin-bottom: 10px;}
.chosen-container-single .chosen-single{ 
    background: #fff; padding: 0 0 0 18px; border: 1px solid #e2e2e2; height: 54px; line-height: 52px;
    border-radius: 0; box-shadow: none; -webkit-box-shadow: none; font-size: 15px; color: #615f5f;
    font-family: 'Open Sans', sans-serif; font-weight: normal;   
}
.chosen-container-single .chosen-default{ color: #615f5f; font-weight: 400; }
.chosen-container-active.chosen-with-drop .chosen-single{ background: none; border: 1px solid #e2e2e2;}
.chosen-container-single .chosen-single span{ margin-right: 45px;}
.chosen-container-single .chosen-single div{ width: 45px;}
.chosen-container-single .chosen-single div b{ background: url(../images/arrow-down.png) no-repeat center;}
.chosen-container-active.chosen-with-drop .chosen-single div b{background: url(../images/arrow-up.png) no-repeat center;}
.chosen-container-single .chosen-drop{ margin-top: -2px;}
.chosen-container .chosen-drop{border: 1px solid #e2e2e2; border-top: 0; box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15); -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);}
.chosen-container-single .chosen-search input[type="text"]{ padding-left: 18px; border-color: #d5d7e0;}
.chosen-container .chosen-results{ margin: 0; padding: 0;}
.chosen-container .chosen-results li{ padding: 7px 18px;}
.chosen-container-single .chosen-single input[type="text"] {left: 0;}

/*----404 page----*/
.page404-main-div .gohome {
    display: inline-block; line-height: 1; font-weight: bold; padding: 15px 50px; border-radius: 4px;
    text-align: center;  background: #fdb23e; margin: 10px; -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;  color: #000000;
}
.page404-main-div {text-align: center; margin-top: 60px; font-family: 'DecimaPlusW01'; font-weight: bold;}
.page404-main-div img {border-radius: 100%;}
.page404-main-div h1 {font-size: 60px; font-family: 'DecimaPlusW01'; font-weight: bold; margin: 20px 0px;}
.page404-main-div p {font-size: 24px;}
/*---404 page end---*/


/* =========------------ All POPUP & Product List & Product Details popup -----------====== */

.style_pg .open-popup-link{ color: #fff; }
/* Product start */
.wraper_product .container{ max-width: none; width: auto; padding: 0 40px; }
.sidebar_menusec{ width: 285px; display: inline-block; vertical-align: top; height: calc(100% - 280px);}
.sidemenu_list{ list-style: none; margin: 0; padding: 0; }
.sidemenu_list li{ margin: 0 0 3px; }
.sidemenu_list li a{ font-family: DecimaPlusW01; font-size: 33px; color: #000000; }
.sidemenu_list li a:hover{ color: #fff; }
.sidemenu_list li.active a{ color: #fff; }
.pright_sec{ width: calc(100% - 290px); width: -webkit-calc(100% - 290px); display: inline-block; vertical-align: top;}
.plist_bx{ border: 1px solid transparent; border-right: 0; margin: 0 0 60px; padding: 0px 0px 0px 15px;}
.plist_bx.active{ color: #fff; border-color: #fff;}
.plist_bxtt{ text-align: right; font-family: Futura; font-weight: bold; font-size: 90px; color: inherit; margin-top: -45px;}
.plist_bxtt span{ background: #ea2876; padding: 0 18px; }
.plist_row{ display: flex; flex-wrap: wrap; width: 100%;}
.plist_col{ width: 33.33%; padding: 0 5px 35px;}
.plist_img{ 
    height: 255px; width: 100%; display: flex; align-items: center; justify-content: center; 
    margin-bottom:15px; overflow: hidden;
}
.plist_img img {height: 100%; object-fit: contain; object-position: center;}

.plist_info{ padding: 5px 25px 0; font-family: DecimaPlusW01}
.plist_info h3{ color: inherit; ; font-family: DecimaPlusW01; font-size: 33px; margin: 0 0 12px;}
.plist_info p{ font-size: 15px; margin: 0 0 13px;}
.plist_price{ font-size: 33px; }
.plist_price sup{ font-size: 21px; }
.plist_item{position: relative;}
.plist_item:hover .innerproduct-button {opacity: 1; visibility: visible; top: 40%;}

.plist_item .innerproduct-button .addcart_row {max-width: 260px;}
.plist_item .innerproduct-button .qty_bx .qty_minus {border: 1px solid #fdd24a;}
.plist_item .innerproduct-button .qty_bx .qty_plus {border: 1px solid #fdd24a;}
.plist_item .innerproduct-button .addcart_row .btn {height: 42px;}
.plist_item .innerproduct-button .addcart_row {display:none;}

.no-stock-product .innerproduct-button {opacity: 1; visibility: visible; top: 40%;}
.no-stock-product .innerproduct-button .btn {cursor: not-allowed; pointer-events: all !important;}

.mobi-product-menu-list{display: none;}

/* Prduct detail popup */
.pdetail_popup.white-popup {margin: 125px auto; background: transparent;}
.dtls-slider-inner {margin-top: -70px;}
.inn-product-popup {border: 1px solid #fdd24a;}
.prodet_bansec{ padding: 0px 30px 0; display: block; }	
.dtls-slider-inner .slick-track .slick-slide {justify-content: center;}
ul.slick-dots {list-style: none; text-align: center; display: flex !important; justify-content: center;}
ul.slick-dots > li > button {
    background: #dadada; color: #000; padding: 0px; height: 11px; width: 11px; font-size: 0px;
    margin: 0px 3px; border-radius: 100%;
}
ul.slick-dots > li.slick-active > button {background: #e72875;}
.dtls-slider-inner .slick-track .slick-slide {justify-content: center;}
ul.slick-dots {list-style: none; text-align: center; display: flex !important; justify-content: center;}
ul.slick-dots > li > button {
    background: #dadada; color: #000; padding: 0px; height: 11px; width: 11px;
    font-size: 0px; margin: 0px 3px; border-radius: 100%;
}
ul.slick-dots > li.slick-active > button {background: #e72875;}
.prodet_baninfo {width: 100%; padding: 30px 0 0; position: relative;}
.product_chosen_col_inner{ margin: 0; }
.product_chosen_col{ width: 100%; margin-right: 0;}
.product_chosen_item_left{ padding: 0; }
.combo-input-label{ line-height: 20px; }
.inn_product_review {position: relative;}
.inn_product_review .offer50 {
    background: #060203; color: #fbea32; width: 88px; height: 34px; display: inline-block; line-height: 34px;  font-size: 19px;
    font-family: 'Open Sans', sans-serif; text-transform: uppercase; font-weight: 600; text-align: center; position: absolute;
    top: 0; right: -30px;
}
.inn_product_hea_left h3 {font-size: 31px; font-family: 'DecimaPlusW01';}
.related-tags ul li, .product-tags-list ul li {
    font-family: 'Open Sans', sans-serif; font-size: 14px; background-color: #e72875; border: 1px solid #e72875;
    margin-top: 0px;
}
.prd_chosen_row {padding: 20px 30px 20px 30px;}
.product_chosen_hea h6 {font-size: 19px; font-family: 'DecimaPlusW01'; color:#383838;}
.inn_product_hea p {color: #000000; font-size: 16px; font-family: 'DecimaPlusW01'; margin-bottom: 0px;}
.product-size-radio li {position: relative; padding: 10px 0px;}
.product-size-radio li label{position: absolute !important; right: -7px; top: 26px;}
.product-size-radio li .radio_con, .chosen_adn_left span {
    font-family: 'Open Sans', sans-serif; font-size: 16px; color:#383838;
}
.prd_chosen_sub_row {
    text-align: center;
    margin: -10px 0 0 0;
    background: #fff;
    padding: 0px 30px 15px;
}
.prd_chosen_sub_row .addcart_row {max-width: 150px;}
.prd_chosen_sub_row .addcart_row .qty_bx {width: 100%; float: none;}

.pdetail_popup.white-popup .check_pg {margin-top: 7px;}
.prd_chosen_sub_row > p {
    color: #0a0b10;
    font-size: 38px;
    font-family: 'DecimaPlusW01';
}
.prd_chosen_sub_row .btn {
    border-radius: 0;
    display: block;
    width: 100%;
    padding: 20px 22px;
    height: 60px;
    font-size: 21px !important;
    font-family: 'DecimaPlusW01' !important;
}

/* Product end */
/* Popup */
/* Login */
.popup_sec .form-control{ height: 41px; padding: 5px 14px;}
.focus-out label{ left: 14px; top: 11px; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: normal; }
.focused label{ top: -7px; font-size: 11px; }
.form-control:focus{ border-color: #fdd24a; }
.white-popup.login-popup, .white-popup.signup-popup, .white-popup.signup-corparateone-popup, 
.white-popup.signup-corparatetwo-popup, .white-popup.guest-popup{ max-width: 820px;}


.login-popup .mfp-close, 
.signup-popup .mfp-close, 
.signup-corparateone-popup .mfp-close, 
.signup-corparatetwo-popup .mfp-close, 
.guest-popup .mfp-close,
 .pdetail_popup .mfp-close { 
    width: 24px; height: 24px; background: #060203; border-radius: 6px; right: 44px; top: 4px; opacity: 1;
}
.login-popup .mfp-close:after, 
.pdetail_popup .mfp-close:after, 
.signup-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after, 
.signup-corparatetwo-popup .mfp-close:after,
.guest-popup .mfp-close:after{
    background-image: url(../images/crosslg1.png); background-position: center;
}
.pdetail_popup .mfp-close {right: 6px; top: 6px;}
.pop-whole-lhs{ background: #ea2876; padding: 45px 30px; border: 1px solid #fbd049;}
.ico_pop{ margin: 0 0 18px; }
.pop-whole-lhs h3{ font-size: 33px; font-family: DecimaPlusW01; margin: 0; text-transform: uppercase; }
.pop-whole-lhs p{ font-size: 20px; }
.pop-whole-lhs-inner{ max-width: 330px;}
.inside-popup-rhs{ max-width: 390px; padding: 70px 40px; margin: 0 40px 0 0;}
.login-popup .popup-header{ padding: 0 0 15px; }
.popup-header h3{  font-family: DecimaPlusW01; font-size: 20px; margin: 0;}
.popup_btn{  background: #fdd24a; height: 40px; color: #000; font-size: 20px;}
.popup_btn:hover{ background: #ea2876; color: #fff; }
.login-popup .popup_btn{ margin-top: 0;} 
	/* Delivery Popup */
.delevery_popup .popup_ttsec{ background: #ea2876; padding-left: 20px; padding-right: 20px;}
.pop_title img{margin: 0 0 7px;}
.pop_title h2{ font-size: 33px; font-family: DecimaPlusW01; margin: 0; text-transform: uppercase;  }
.pop_title small{font-size: 21px; }
.delevery_popup .popup_right .form-group .form-control:focus{ border-color: #fdd24a; }
.go_second a{ background: #000000; color: #fff; font-family: DecimaPlusW01; font-size: 20px;  }
.two-button-row .delivery_submit{ background: #fdd24a; color: #000; font-family: DecimaPlusW01; font-size: 20px;  height: 40px;}
.two-button-row .delivery_submit:hover, .go_second a:hover{ background: #ea2876; }
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close{ width: 24px; height: 24px; background: #060203; border-radius: 6px; right: 4px; top: 4px; opacity: 1;}
.mfp-close-btn-in .order_popup .mfp-close::after, .mfp-close-btn-in .awesome_popup .mfp-close::after, .mfp-close-btn-in .self_popup .mfp-close::after, .mfp-close-btn-in .delevery_popup .mfp-close::after, .mfp-close-btn-in .error_postal_popup .mfp-close::after, .mfp-close-btn-in .receipt_popup .mfp-close:after, .mfp-close-btn-in .receipt_cateringpopup .mfp-close:after, .changepw_popup .mfp-close:after, .redeem_popsec .mfp-close:after, .pay-conf-popup .mfp-close:after, .warning_popup .mfp-close:after, .venue_popup .close-icon-but:after{
    background-image: url(../images/crosslg1.png); background-position: center;
}
.mfp-close-btn-in .order_popup .mfp-close:hover, .mfp-close-btn-in .awesome_popup .mfp-close:hover, .mfp-close-btn-in .delevery_popup .mfp-close:hover, .mfp-close-btn-in .error_postal_popup .mfp-close:hover, .changepw_popup .mfp-close:hover, .redeem_popsec .mfp-close:hover, .pay-conf-popup .mfp-close:hover, .warning_popup .mfp-close:hover, .venue_popup .close-icon-but:hover{background: #000;}
	/* Delivery Success Popup */
.delevery_popup{ max-width: 780px; }
.popup_right{ padding-left: 35px; padding-right: 35px; }
.awesome_del{ margin-top: 8px; }	
.awesome_del h2{ font-size: 16px; font-family: DecimaPlusW01; line-height: 1.3; }
.datetime_selt_lbl, .error-title{ font-size: 21px; font-weight: normal; text-align: center; color: #000000;}
.error-title{margin-bottom: 10px;}
	/* Error postal code popup */
.error_postal_popup{ max-width: 780px; }
.error_postal_popup .popup_ttsec{ background: #000; }
.login_pop_sub .btn{color:#000;}
.login_pop_sub .btn:hover{color:#fff;}
/* Popup end */
/* =========------------ All POPUP & Product List & Product Details popup  END-----------====== */

/*====-----Thank you page-------====*/
.wraper.tnk-you-wrapper {margin-bottom: 200px;}
.wraper.tnk-you-wrapper .container {padding: 0 20px;}
.tnk-you {position: relative; padding: 0px 0px 80px;}
.thanku-footer-bg.footer-bg:after {
     height: 940px; top: -590px; -webkit-transform: skewY(18deg); transform: skewY(18deg);
}
.tick {text-align: center;}
.tick h2 {
    font-size: 90px;
    margin: 30px 0px 0px 0px;
    font-family: 'Futura';
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
}
.tick span {
    font-size: 35px;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    margin-bottom: 30px;
    display: block;
    letter-spacing: 3px;
}
.tnk-inner-wrapper {
    max-width: 696px;
    margin: 0 auto;
    overflow-y: hidden;
    background: #fff;
}
.tnk-inner-wrapper .cart-header {
    padding: 42px 30px;
    height: 120px;
}
.tnk-inner-wrapper .cart-header:before {
    height: 145px;
}
.tnk-inner-wrapper .cart-header h3 {
    font-size: 35px;
    color: #fff;
    font-family: 'Futura';
    font-weight: bold;
    text-align: center;
    margin: -10px 0px 0px;
}
.tnk-inner-wrapper .tnk-order {
    background: #fff;
    padding: 5px 15px 10px;
    text-align: center;
}
.tnk-delivery .delivery_total {
    margin: 5px 0 10px 0;
}
.delivery_total h4 {
    font-size: 21px !important;
    font-family: 'DecimaPlusW01' !important;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}
.delivery_total p {
    margin: 0 0 15px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_total.delivery_total_number h5{
    font-family: 'DecimaPlusW01';
    color: #3d3d3d;
    font-size: 21px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.delivery_total.delivery_total_number h3 {
    margin: 0px 0px 5px 0px;
    font-size: 32px;
    font-family: 'Futura';
    font-weight: bold;
}
.tnk-inner-wrapper .cart_body {
    padding: 0px 0px 0px;
}
.tnk-inner-wrapper .hcart_tt {
    margin: 0px 0px 0px 0px;
    padding: 5px 10px 7px 15px;
}
.tnk-inner-wrapper .cart_body.pdt-cart-list .cart_row {
    padding: 20px 15px 15px 15px;
}
.tnk-inner-wrapper .cart_body.pdt-cart-list .cart_row .cart_price {
    margin: 0px 0px 10px 0px;
}
.tnk-inner-wrapper .cart_footer {
    padding: 30px 20px 15px 20px;
    margin-top: 55px;
}
.tnk-inner-wrapper .cart_footer .cart_row {padding: 0px 20px 5px 20px;}

.tnk-chk-order .btn {
    width: 100%;
    border: 0;
    background: #ea2876;
    display: block;
    margin-bottom: 0px;
    font-size: 21px;
    font-family: 'DecimaPlusW01';
    text-align: center;
}
.tnk-chk-order .btn:hover {
    background: #fdd24a;
    color: #000000;
}
.tnk-order p {font-family: 'Open Sans', sans-serif; font-weight: 700;}
/*----15-04-2020---*/
.sidebar-left {
    position:relative;
}
.sidebar-left .sidebar-inner {
    margin-bottom: 135px;
}
.sidebar-left ul.menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.congrats {background: #fff; margin-bottom: 10px; padding:20px 30px 15px; text-align: center;}
.congrats h3{margin: 0px 0px 5px 0px; text-transform: uppercase; font-size: 24px;}
.congrats h2{margin: 0px 0px 0px 0px; font-family: 'Futura'; font-weight: bold;}
.no-recrds-found {
    font-size: 32px;
}
.promation_btn .button {
    border: 1px solid #FDD24A;
}

.promation_btn .button:hover {
    color: #FDD24A;
}
.applyBtn:hover {
    color: #000;
    background: #FDD24A;
}
.myaccount_update {
    margin: 0px 0px 0px 0px;
    height: 44px;
    padding: 14px 22px;
}
/*---Contact Us---*/
.contact_wrap {
    margin-top: 260px;
    position: relative;
    z-index: 9;
    min-height: 800px;
    margin-bottom: 100px;
}
.contact_wrap .inner_banner {
    display: none;
}
.contact_wrap .container, .cms_wraper .container {    
    margin: 0 auto;
    padding: 0 20px;
    width: auto;
    max-width: 1430px;
}
.contactus_wrapper{
    background: #fff;
    min-height: 600px;
}
.contact_form {
    padding: 20px 15px 60px;
}
.contact_form h3 {
    font-size: 28px;
    font-family: 'DecimaPlusW01';
    padding-left: 15px;
}
.contact_form .btn_sec .btn {
    color: #000;
    padding: 15px 22px 12px;
    border:1px solid #FDD24A;
}
.contact_form .btn_sec .btn:hover {
    color: #fff;
}
.contact_form .focus-out label {
    top: 16px;
    font-size: 16px;
}
.contact_form .focused label {
    top: -7px;
    font-size: 11px;
}

.locate-map {
    margin: 0px;
    display: -webkit-flex;
    display: flex;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.1);
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 30px;
}
.locate-mapleft {
    width: 360px;
}
.locate-mapright {
    width: calc(100% - 360px);
}
.locate-lirow, .locate-list {
    display: -webkit-flex;
    display: flex;
}
.locate-list {
    height: 100%;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
}
.locate-lirow:last-child {
    border-bottom: 0;
}
.locate-lirow {
    padding: 20px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    cursor: pointer;
    -webkit-flex: 1 1;
    flex: 1 1;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    background: #fdd24a;
    color: #000;
}
.locate-mapright iframe {vertical-align: top; width: 100%; height:100%;}
#locateGmap {width: 100%; height: 360px;}
#locateGmap > div{position: relative; width: 100%; height: 100%;}
#locateGmap div{width: 100% !important; height: 100% !important;} 
.locate-lirow h4 {font-size: 30px !important; font-family: 'DecimaPlusW01' !important; margin-bottom: 10px;}
.locate-lirow p {margin: 0 0 0px; font-size: 20px;}

/*--CMS page--*/
.cms-page {margin: 0px;}
.wraper.cms_wraper{margin-top: 240px;}
.cms_wraper h3.title {
    font-size: 70px; color: #fff; font-family: 'Futura'; font-weight: bold; text-align: center; 
    letter-spacing: -1px; margin: 0px 0px 10px 0px;
}
.cms-content-wrapper {background: #fff; min-height: 600px; padding: 40px 20px 30px;}
.cms_wraper p {font-family: 'Open Sans', sans-serif; font-weight: 400; font-size: 16px; line-height: 1.8;}
.cms_wraper ul li {line-height: 1.8; font-size: 16px;}

/*--View Recipt--*/
.receipt_popup .Viewreceipt, .receipt_cateringpopup .Viewreceipt {overflow-y: hidden;}
.receipt_popup .tnk-delivery {padding: 20px 0px 0px; }
.receipt_popup .delivery_total {padding: 10px 25px 0px;}
.receipt_popup .order-items-maindiv {padding: 25px 25px 50px;}
.receipt_popup .tnk-delivery .cart_footer.tnk_cart_footer {padding: 25px 25px 25px 25px; margin-top: 50px;}
.receipt_popup .cart_row.cart_footer_totrow {margin-bottom: 0px;}
.receipt_popup .tnk-order h3 {font-family: 'Futura'; font-weight: bold;}
.receipt_popup .tnk-order p {color: #fff; font-size: 18px;}

/*---17-04-2020--*/
.prodet_bansec .slick-slide .check-pg-slide img {width: auto; height: 405px;}
.addon-list-slider .innerproduct-item .product-tags-list {display: none;}
.addon-list-slider .innerproduct-item-text p {display: none;}
.addon-list-slider .innerproduct-button .addcart_row {max-width: 260px;}
.addon-list-slider .innerproduct-button .addcart_row {display: none;}
.addon-list-slider .innerproduct-button {margin-top: 30px;}
.addon-list-slider .innerproduct-col:hover .innerproduct-button{top: 0%;}
.addon-list-slider .innerproduct-item-text {padding-top: 20px;}
.addon-list-slider .innerproduct-item-text h4 {margin-bottom: 10px;}
.addon-list-slider .addcart_row .qty_bx {width: 66%;}
.addon-list-slider .addcart_row .btn {width: 32%; font-size: 18px; height: 42px; line-height: 42px;}
.product_chosen_hea {margin-bottom: 5px;}
.pdetail_popup .addon-group {display: flex; align-items: center; margin-bottom: 10px;}
.pdetail_popup .addon-group button {padding: 12px 20px 10px; height: 42px; font-size: 18px; margin: 0px;}
.innerproduct-item-image img {height: 305px; object-fit: contain; object-position: center;}




/* Omise Setion */
.omise_popsec{ background: rgba(0,0,0,.6); background: rgba(0,0,0,.6); max-width: 100%; margin: 0px auto;}
.omise_popsec .white-outer{ width: auto; max-width: 300px; margin: 65px auto; border: 0; border-radius: 6px; box-shadow: 0 12px 30px 0 rgba(0,0,0,.5),inset 0 1px 0 0 hsla(0,0%,100%,.65);}
.omise_popsec .close{ background: #c1c1c1; box-shadow: none; width: 20px; height: 20px; font-size: 21px; position: absolute;right: 10px;top: 10px;color: #333; transition: all 0.3s ease;-webkit-transition: all 0.3s ease;cursor: pointer; border-radius: 50%; line-height: 20px; text-align: center; text-shadow: none; opacity: 1; color: #fff;	}
.omise_popsec .close:hover{ background: #000; color: #fff;}
.omise_pophead{padding: 44px 32px 22px; position: relative; border-radius: 6px 6px 0 0; text-align: center; background-color: #e8e9eb;}
.omisepop_logo{
    position: absolute; top: -35px; right: 0; left: 0; width: 70px; height: 70px; margin: 0 auto; 
    background: #fff; padding: 5px; line-height: 58px;     
    background: #fdd34d;
    padding: 5px;
    line-height: 58px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: none; border-radius: 50%;
}
.omisepop_logo img{vertical-align: middle; display: inline; height: 50px; margin-top: -4px;}
.omise_pophead h3{ font-size: 20px; margin: 0;    text-transform: uppercase;}
.omisepop_in{ background: #fff; padding: 20px 25px;}
.omise_popsec .form-group{ margin: 15px 0 0;     padding: 0;}
.form_expire_row .row{ margin-left: 0; margin-right: 0;}
.form_expire_row [class*='col-']{ padding: 0; }
.form_expire_row .form-group{ margin-top: 0;}
.form_expire_row .card-month, .form_expire_row .card-year{ width: auto;}
.form_expire_row .card-month{ border-right: 0;}
.card-image {position: absolute; right: 4px; top: 12px; height: 16px;}
.omise_popsec .btn_sec .btn{ width: 100%; color: #000;}
.omisepop-error-msg{color: #D8000C; display: inline-block; font-weight: normal; font-size: 14px; }
.omisepop_in .input-group span{color: #D8000C; margin-left: 2px; font-size: 13px;}
.input-group .spn-error {
	position: absolute; left: 0; right: 0; bottom: -26px; text-align: left; background: #FFBABA;
	color: #D8000C !important; padding: 4px 22px; font-size: 13px;	text-transform: inherit !important;
	margin:0 1px; z-index: 1;
}
.omise_popsec .btn_sec .btn:hover{ color: #FDD24A; border:1px solid#FDD24A;}
.order_amt_div {
    margin: 5px 0px;
}
.reward-list-body .dir_reedem_points {
    margin: 0;
    font-size: 18px;
    padding: 20px 20px;
    background: #ea287630;
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    font-family: 'Open Sans', sans-serif;
}

.inner_menusec {
    position: absolute;
    overflow-y: auto;
    width: inherit;
    height: inherit;
	padding:10px 10px 10px 0px;
}
.sidebar_menusec.fixed .inner_menusec {
    position: fixed;
}
.omise_popsec .mfp-close {display: none;}
.mobi-product-menu-list.myacc-menu-list {
    display: none;
}
.cart-header.min-order-amt {height: 152px;}
.cart-header.min-order-amt:before{height: 172px;}
.cart-header.min-order-amt a.remove {top: -6px;}

.alert_popup .cart-min-amt {
    color: #ea2876; font-size: 20px;
}
.promo-earned-col-image img {
   object-fit: cover !important;
}

.hcategory_sec{display: none;}
.cart-header.min-order-amt + .cart_scrollarea {height: calc(100% - 152px);}

.otp-btn-group .btn{color: #000; margin-bottom: 15px;}
.otp-btn-group .btn:hover{color: #fff;}
.otp-btn-group a {color: #ea2876;}
.back_to_register{
	font-size:0; background: url(../images/black-previous.png)no-repeat center; height: 20px; width: 30px;
    position: absolute; background-size: contain; top: 15px; left: 35px;
}

.sidebar_menusec .inner_menusec > div > div:nth-child(2){height: 0px !important;}